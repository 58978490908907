import React, { FunctionComponent, useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import { CustomLoadingIndicator } from "../../components/CustomLoadingIndicator";
import styles from "./styles.module.scss";
import queryString from "query-string";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { strings } from "../../localization/LocalizedStrings";
import { AlertDialog } from "../../components/AlertDialog";
import { LoginAgainDialog } from "../../components/LoginAgainDialog";
import { StatusCodes } from "http-status-codes";
import { useGrantPatreonAccess } from "../../hooks/patreon";

export const PaymentPatreonPage: FunctionComponent = () => {
  useEffect(() => {
    document.title = strings.app_name;
  }, []);
  const navigate = useNavigate();
  const grantPatreonAccess = useGrantPatreonAccess();
  const [isSignInAgainDialogOpen, setIsSignInAgainDialogOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const processAuth = () => {
    const queryParams = queryString.parse(location.search);
    const code = queryParams.code;
    if (typeof code != "string") {
      navigate(RoutesBuilder.home());
    } else {
      processAuthFromPatreon(code).then();
    }
  };
  useEffect(() => {
    processAuth();
  }, []);
  const processAuthFromPatreon = async (code: string) => {
    const { status, body } = await grantPatreonAccess(code);
    if (StatusCodesHelper.isSuccessful(status)) {
      window.location.href =
        "https://patreon.com/Piper107?utm_medium=piper&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink";
    } else if (status == StatusCodes.FORBIDDEN) {
      setIsSignInAgainDialogOpen(true);
    } else {
      setAlertMessage(body.message);
    }
    setIsAlertOpen(true);
  };
  return (
    <Backdrop className={styles.backdrop} open={true}>
      <CustomLoadingIndicator />
      <LoginAgainDialog
        isOpen={isSignInAgainDialogOpen}
        setIsOpen={setIsSignInAgainDialogOpen}
        closeOnTouch={false}
        loginCallback={async () => {
          setIsSignInAgainDialogOpen(false);
          processAuth();
        }}
      />
      <AlertDialog
        title={strings.app_name}
        message={alertMessage}
        open={isAlertOpen}
        setOpen={setIsAlertOpen}
        onConfirm={() => navigate(RoutesBuilder.home())}
      />
    </Backdrop>
  );
};
