import { createState } from "state-pool";
import { useEffect } from "react";
import {
  FilterElement,
  FilterSortOption,
  FilterSortOrder,
  FilterType
} from "../../models/FilterElement";
import { filterIsNegatedInit, IIsNegatedAlbumsParameters } from "./useAlbumStorage";
import { strings } from "../../localization/LocalizedStrings";

const useLocalStoredState = <T>(name: string, defaultValue: T) =>
  createState<T>(() => {
    const stringValue = localStorage.getItem(name);
    if (stringValue != null) {
      return JSON.parse(stringValue) ?? defaultValue;
    }
    return defaultValue;
  });

export const useCurrentPrivateFilterElements = useLocalStoredState<FilterElement[]>(
  "currentFilterElements",
  []
);

export const useCurrentPrivateIsNegated = useLocalStoredState<IIsNegatedAlbumsParameters>(
  "currentIsNegated",
  filterIsNegatedInit()
);

export const useCurrentPrivateFilterSortOption = useLocalStoredState<FilterSortOption>(
  "currentFilterSortOption",
  FilterSortOption.ARTIST
);

export const useCurrentPrivateFilterSortOrder = useLocalStoredState<FilterSortOrder>(
  "currentFilterSortOrder",
  FilterSortOrder.ASCENDANT
);

export const useCurrentLayoutFilterElements = useLocalStoredState<FilterElement[]>(
  "currentLayoutFilterElements",
  []
);

export const useCurrentLayoutIsNegated = useLocalStoredState<IIsNegatedAlbumsParameters>(
  "currentLayoutIsNegated",
  filterIsNegatedInit()
);

export const useCurrentLayoutFilterSortOption = useLocalStoredState<FilterSortOption>(
  "currentLayoutFilterSortOption",
  FilterSortOption.ARTIST
);

export const useCurrentLayoutFilterSortOrder = useLocalStoredState<FilterSortOrder>(
  "currentLayoutFilterSortOrder",
  FilterSortOrder.ASCENDANT
);

export const useAlbumFilterManager = (isLayout: boolean) => {
  const [currentFilterElements] = isLayout
    ? useCurrentLayoutFilterElements.useState()
    : useCurrentPrivateFilterElements.useState();
  const [currentIsNegated] = isLayout
    ? useCurrentLayoutIsNegated.useState()
    : useCurrentPrivateIsNegated.useState();
  const [currentFilterSortOption] = isLayout
    ? useCurrentLayoutFilterSortOption.useState()
    : useCurrentPrivateFilterSortOption.useState();
  const [currentFilterSortOrder] = isLayout
    ? useCurrentLayoutFilterSortOrder.useState()
    : useCurrentPrivateFilterSortOrder.useState();
  useEffect(() => {
    const key = isLayout ? "currentLayoutFilterElements" : "currentFilterElements";
    if (currentFilterElements.length == 0) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(currentFilterElements));
    }
  }, [currentFilterElements]);
  useEffect(() => {
    const key = isLayout ? "currentLayoutIsNegated" : "currentIsNegated";
    localStorage.setItem(key, JSON.stringify(currentIsNegated));
  }, [currentIsNegated]);
  useEffect(() => {
    const key = isLayout ? "currentLayoutFilterSortOption" : "currentFilterSortOption";
    if (currentFilterSortOption == FilterSortOption.ARTIST) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(currentFilterSortOption));
    }
  }, [currentFilterSortOption]);
  useEffect(() => {
    const key = isLayout ? "currentLayoutFilterSortOrder" : "currentFilterSortOrder";
    if (currentFilterSortOrder == FilterSortOrder.ASCENDANT) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(currentFilterSortOrder));
    }
  }, [currentFilterSortOrder]);
  const getCurrentFilterElements = () => structuredClone(currentFilterElements);
  const getCurrentIsNegated = () => currentIsNegated;
  const getCurrentFilterSortOption = () => currentFilterSortOption;
  const getCurrentFilterSortOrder = () => currentFilterSortOrder;
  const getFilterElementsWithSearchText = (
    searchText: string | null,
    filterElements: FilterElement[]
  ) => {
    const newFilterElements = filterElements.filter(
      filterElement => filterElement.filterType != FilterType.SEARCH_TEXT
    );
    if (searchText) {
      newFilterElements.unshift(
        new FilterElement(
          strings.filter_album_search_text_title,
          FilterType.SEARCH_TEXT,
          false,
          false,
          searchText,
          searchText
        )
      );
    }
    return newFilterElements;
  };
  const getSearchText = (filterElements: FilterElement[]) => {
    const searchTextFilterElement = filterElements.filter(
      filterElement => filterElement.filterType == FilterType.SEARCH_TEXT
    );
    if (searchTextFilterElement[0]) {
      return searchTextFilterElement[0].elementId;
    }
    return "";
  };
  return {
    getCurrentFilterElements,
    getCurrentIsNegated,
    getCurrentFilterSortOption,
    getCurrentFilterSortOrder,
    getFilterElementsWithSearchText,
    getSearchText
  };
};
