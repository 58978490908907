import React, { FunctionComponent, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { IAlbum, SellStatus } from "../../../hooks/albums";
import { Divider, List, ListSubheader, useTheme } from "@mui/material";
import { AlbumListItem } from "../AlbumListItem";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { FilterSortOption } from "../../../models/FilterElement";
import { strings } from "../../../localization/LocalizedStrings";
import { TimeInterval } from "../../../models/TimeInterval";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { format } from "date-fns";
import { SkeletonAlbumList } from "../SkeletonAlbumList";
import { ShortcutsGrid } from "../../ShortcutsGrid";
import { useCurrencyFormatter } from "../../../hooks/currencyFormatter";
import { usePublicUserProfile, useUserProfile } from "../../../hooks/session";
import { IShelving } from "../../../hooks/shelvings";
import { AlbumSpineDraggable } from "../AlbumSpineDraggable";

export const AlbumList: FunctionComponent<IAlbumListProps> = ({
  dragDropId,
  isPublic,
  albums,
  selectedAlbumId,
  sortOption,
  canMoveAlbums,
  canDropAlbums,
  canLoadMoreAlbums,
  setNeedMoreAlbums,
  onAlbumSelected,
  onAlbumDeleted,
  onAlbumDeletedFromBoxSet,
  onAlbumCopy,
  onAlbumMarkAs,
  setIsLoading,
  noMoreAlbums,
  albumCount,
  onNewAlbum,
  onScanBarcode,
  onFilterAlbums,
  onImportFromDiscogs,
  onImportFromExcel,
  onChangeCollection,
  onEditProfile,
  onPlayFromYouTube,
  scrollToTopTrigger,
  isForLayout,
  shelving,
  isBoxSetList,
  boxSetAlbumId
}) => {
  const theme = useTheme();
  const currencyFormatter = useCurrencyFormatter();
  const [currentUserProfile] = useUserProfile.useState();
  const [currentPublicUserProfile] = usePublicUserProfile.useState();
  const userProfile = isPublic ? currentPublicUserProfile : currentUserProfile;
  const topReference = useRef<HTMLHeadingElement>(null);
  const handleScroll = (element: React.UIEvent) => {
    if (noMoreAlbums || !canLoadMoreAlbums) return;
    const shouldLoadMore =
      element.currentTarget.scrollHeight - element.currentTarget.scrollTop <=
      element.currentTarget.clientHeight * 4;
    if (shouldLoadMore) {
      setNeedMoreAlbums();
    }
  };
  useEffect(() => {
    topReference.current?.scrollIntoView();
  }, [scrollToTopTrigger]);
  const getHeader = (album: IAlbum) => {
    switch (sortOption) {
      case FilterSortOption.ARTIST:
        return album.artist?.name ?? strings.unknown;
      case FilterSortOption.LABEL:
        return album.label?.name ?? strings.unknown;
      case FilterSortOption.ORIGINAL_YEAR:
        return album.originalYear ?? strings.unknown;
      case FilterSortOption.RELEASE_YEAR:
        return album.releaseYear ?? strings.unknown;
      case FilterSortOption.STORE:
        return album.store?.name ?? strings.unknown;
      case FilterSortOption.COUNTRY:
        return album.country ?? strings.unknown;
      case FilterSortOption.PURCHASE_PRICE:
        return `${currencyFormatter.format(
          album.purchasePriceToday,
          userProfile,
          isPublic
        )} (${currencyFormatter.format(
          album.purchasePriceDollars,
          userProfile,
          isPublic,
          2,
          "USD"
        )})`;
      case FilterSortOption.SELL_VALUE:
        return album.sellValue?.pesos
          ? `${album.sellValue.group} (${currencyFormatter.format(
              album.sellValue.pesos,
              userProfile,
              isPublic
            )})`
          : strings.unknown;
      case FilterSortOption.SELL_VALUE_DOLLARS:
        return album.sellValue?.pesos
          ? `${currencyFormatter.format(
              album.sellValue?.pesos,
              userProfile,
              isPublic
            )} (${currencyFormatter.format(
              album.sellValue?.dollars,
              userProfile,
              isPublic,
              2,
              "USD"
            )})`
          : strings.unknown;
      case FilterSortOption.REVENUE:
        const revenue = AlbumUtilities.getRevenue(album);
        return currencyFormatter.format(revenue, userProfile, isPublic);
      case FilterSortOption.PURCHASE_DATE:
        if (album.purchaseDate) {
          return format(album.purchaseDate * 1000, "d MMMM yyyy", { locale: strings.fnsLocale });
        }
        return strings.unknown;
      case FilterSortOption.TRACK_COUNT:
        return album.tracks ?? strings.unknown;
      case FilterSortOption.LENGTH:
        return album.length ? TimeInterval.toHHMMSS(album.length) : strings.unknown;
      case FilterSortOption.RANDOM:
        return strings.option_random_albums;
    }
  };
  const albumListItem = (
    album: IAlbum,
    section: number,
    index: number,
    sectionAlbums: IAlbum[]
  ) => (
    <AlbumListItem
      isPublic={isPublic}
      sortOption={sortOption}
      album={album}
      isBoxSetList={isBoxSetList}
      setIsLoading={setIsLoading}
      highlighted={album.uuid == selectedAlbumId}
      onClick={() => onAlbumSelected(album)}
      onDelete={() => onAlbumDeleted(album, section, index)}
      onDeleteFromBoxSet={onAlbumDeletedFromBoxSet}
      onCopy={() => onAlbumCopy(album)}
      onMarkAs={sellStatus => onAlbumMarkAs(album, sellStatus)}
      onPlayFromYouTube={videoIds => onPlayFromYouTube(album.name, videoIds)}
      sectionLength={sectionAlbums.length}
      isForLayout={isForLayout}
      boxSetAlbumId={boxSetAlbumId}
    />
  );
  return (
    <div className={styles.mainDiv}>
      {albums?.length == 0 ? (
        <div className={styles.noSelectionDiv}>
          <p className={styles.shortcutsTitle} style={{ color: theme.palette.primary.main }}>
            {strings.shortcuts_search_title}
          </p>
          <ShortcutsGrid
            isPublic={isPublic}
            showAttributesShortcut={true}
            minimalist
            onNewAlbum={onNewAlbum}
            onScanBarcode={onScanBarcode}
            onImportFromDiscogs={onImportFromDiscogs}
            onImportFromExcel={onImportFromExcel}
            onFilterAlbums={onFilterAlbums}
            onChangeCollection={onChangeCollection}
            onEditProfile={onEditProfile}
          />
        </div>
      ) : (
        <List
          disablePadding
          className={styles.mainList}
          onScroll={handleScroll}
          sx={{
            backgroundColor: isBoxSetList ? undefined : theme.palette.background.paper,
            overflow: albums ? "auto" : "hidden"
          }}
        >
          {!isBoxSetList && <div ref={topReference} />}
          {albums && albums.length > 0 ? (
            albums.map((sectionAlbums, section) => (
              <div
                key={`div_${section}_${dragDropId}`}
                style={{ height: sectionAlbums.length == 0 ? "100%" : undefined }}
              >
                <li
                  key={`li_album_${section}_${dragDropId}`}
                  style={{ height: sectionAlbums.length == 0 ? "100%" : undefined }}
                >
                  <Droppable
                    isDropDisabled={!canDropAlbums}
                    key={`drop_${section}_${dragDropId}`}
                    droppableId={`dr_${section}_${dragDropId}`}
                  >
                    {provided => (
                      <ul
                        key={`ul_${section}_${dragDropId}`}
                        className={styles.ul}
                        style={{ height: sectionAlbums.length == 0 ? "100%" : undefined }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {!isBoxSetList && (
                          <ListSubheader
                            key={`sub_${section}_${dragDropId}`}
                            className={styles.subheader}
                            sx={{
                              backgroundColor: "background.default",
                              lineHeight: 2
                            }}
                          >
                            {getHeader(sectionAlbums[0])}
                          </ListSubheader>
                        )}
                        {sectionAlbums.map((album, index) => {
                          return isForLayout ? (
                            <div
                              key={`div_${album.uuid}_${index}_${dragDropId}`}
                              className={styles.albumItemContainer}
                            >
                              {albumListItem(album, section, index, sectionAlbums)}
                              {index < sectionAlbums.length - 1 && <Divider variant="inset" />}
                              <AlbumSpineDraggable
                                isDragDisabled={!canMoveAlbums}
                                album={album}
                                shelving={shelving}
                                section={section}
                                index={index}
                              />
                            </div>
                          ) : (
                            <Draggable
                              isDragDisabled={!canMoveAlbums}
                              key={`${album.uuid}_${index}_${dragDropId}`}
                              draggableId={`${section}_${index}_${dragDropId}`}
                              index={index}
                            >
                              {provided => (
                                <div
                                  className={styles.albumItemContainer}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {albumListItem(album, section, index, sectionAlbums)}
                                  {index < sectionAlbums.length - 1 && <Divider variant="inset" />}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {!isForLayout && provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </li>
                {!noMoreAlbums && section == albums.length - 1 && (
                  <SkeletonAlbumList key={"li_skeletons"} albumCount={15} disableHeader />
                )}
              </div>
            ))
          ) : (
            <SkeletonAlbumList albumCount={albumCount} disableHeader={isBoxSetList} />
          )}
        </List>
      )}
    </div>
  );
};

export interface IAlbumListProps {
  dragDropId: string;
  isPublic: boolean;
  albums?: IAlbum[][];
  selectedAlbumId?: string;
  sortOption: FilterSortOption;
  canMoveAlbums: boolean;
  canDropAlbums: boolean;
  canLoadMoreAlbums: boolean;
  setNeedMoreAlbums: () => void;
  onAlbumSelected: (album: IAlbum) => void;
  onAlbumDeleted?: (deletedAlbum: IAlbum, section: number, row: number) => void;
  onAlbumDeletedFromBoxSet?: (newBoxSet: IAlbum) => void;
  onAlbumCopy?: (album: IAlbum) => void;
  onAlbumMarkAs?: (album: IAlbum, sellStatus: SellStatus | null) => void;
  setIsLoading: (loading: boolean) => void;
  noMoreAlbums?: boolean;
  albumCount?: number;
  onNewAlbum?: () => void;
  onScanBarcode?: () => void;
  onFilterAlbums: () => void;
  onImportFromDiscogs: () => void;
  onImportFromExcel: () => void;
  onChangeCollection: () => void;
  onEditProfile: () => void;
  onPlayFromYouTube: (title: string, videoIds: string[]) => void;
  scrollToTopTrigger: boolean;
  isForLayout: boolean;
  shelving?: IShelving;
  isBoxSetList?: boolean;
  boxSetAlbumId?: string;
}
