import { useBackend } from "../../services";
import { FilterElement } from "../../models/FilterElement";

export const useAssignToCollectionByFilter = () => {
  const { post } = useBackend();
  const assignToCollectionByFilter = (
    parameters: IAssignToCollectionByFilterParameters,
    filterElements: FilterElement[]
  ) => {
    const filters = FilterElement.queryItems(filterElements);
    if (!filters) return;
    return post("albums/assignToCollectionByFilter", { ...parameters, ...filters });
  };
  return { assignToCollectionByFilter };
};

export interface IAssignToCollectionByFilterParameters {
  assignToCollectionId: string;
}
