import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCS_4OshPphmdHKyMMGb7l4J3wWVznJZ7Q",
  authDomain: "my-collection-314721.firebaseapp.com",
  projectId: "my-collection-314721",
  storageBucket: "my-collection-314721.appspot.com",
  messagingSenderId: "264809982498",
  appId: "1:264809982498:web:ec899c27db6def3ec6787b",
  measurementId: "G-VDGZQSF70T"
};

export class Firebase {
  static shared = new Firebase("serviceWorker" in navigator);
  app;
  messaging;
  // Initialize Firebase
  constructor(isSupported: boolean) {
    if (!isSupported) return;
    this.app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(this.app);
  }
  getAnalytics() {
    try {
      return getAnalytics();
    } catch {
      return undefined;
    }
  }
  requestPermission() {
    Notification.requestPermission().then();
  }
  getFCMToken(callback: (token: string | undefined) => void) {
    if (!this.messaging) return;
    getToken(this.messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
      .then(callback)
      .catch(error => {
        if (error.code !== "messaging/permission-blocked") {
          callback(undefined);
        }
      });
  }
}
