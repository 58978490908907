import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grow,
  Stack,
  Toolbar,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import { AlertDialog } from "../AlertDialog";
import { StringFormat } from "../../models/StringFormat";
import { ContainedButton } from "../ContainedButton";
import { useGetDiscogsRequestToken } from "../../hooks/discogs/useGetDiscogsRequestToken";
import { useGetDiscogsIdentity } from "../../hooks/discogs/useGetDiscogsIdentity";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { StatusCodes } from "http-status-codes";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGrantDiscogsAccess } from "../../hooks/discogs/useGrantDiscogsAccess";
import { IDiscogsCollectionFolder, IDiscogsCollectionFolders } from "../../hooks/discogs";
import { useGetDiscogsCollectionFolders } from "../../hooks/discogs/useGetDiscogsCollectionFolders";
import { RightDetailListItem } from "../RightDetailListItem";
import { CloseRounded, FolderRounded, HelpOutlineRounded } from "@mui/icons-material";
import { useImportDiscogsCollection } from "../../hooks/discogs/useImportDiscogsCollection";
import { useGetDiscogsImportStatus } from "../../hooks/discogs/useGetDiscogsImportStatus";
import { IOngoingTask } from "../../models/OngoingTask";
import { useCancelDiscogsImport } from "../../hooks/discogs/useCancelDiscogsImport";
import { useConfirmDiscogsImport } from "../../hooks/discogs/useConfirmDiscogsImport";
import { CollectionsDialog } from "../collections/CollectionsDialog";
import { ICollection } from "../../hooks/collections";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { ToolbarButton } from "../toolbar/ToolbarButton";
import { useUser } from "../../hooks/session";
import { UserRole } from "../../hooks/users";
import { useBackend } from "../../services";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../services/Firebase";

export const DiscogsImportDialog: FunctionComponent<IDiscogsDialogProps> = ({
  isOpen,
  setIsOpen
}) => {
  const [state, setState] = useState<State>("starting");
  const analytics = Firebase.shared.getAnalytics();
  let token: string | undefined = undefined;
  let verifier: string | undefined = undefined;
  const { socket } = useBackend();
  const [currentUser] = useUser.useState();
  const [intervalId, setIntervalId] = useState<number>();
  const [selectedFolder, setSelectedFolder] = useState<IDiscogsCollectionFolder>();
  const [selectedCollection, setSelectedCollection] = useState<ICollection>();
  const [username, setUsername] = useState("");
  const [folders, setFolders] = useState<IDiscogsCollectionFolders>();
  const [task, setTask] = useState<IOngoingTask>();
  const [shouldSkipDuplicates, setShouldSkipDuplicates] = useState(true);
  const [shouldUploadImage, setShouldUploadImage] = useState(false);
  const getDiscogsIdentity = useGetDiscogsIdentity();
  const getDiscogsRequestToken = useGetDiscogsRequestToken();
  const grantDiscogsAccess = useGrantDiscogsAccess();
  const getDiscogsFolders = useGetDiscogsCollectionFolders();
  const importDiscogsCollection = useImportDiscogsCollection();
  const getDiscogsImportStatus = useGetDiscogsImportStatus();
  const cancelDiscogsImport = useCancelDiscogsImport();
  const confirmDiscogsImport = useConfirmDiscogsImport();
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
  const [isConfirmImportAlertOpen, setIsConfirmImportAlertOpen] = useState(false);
  const [isCancelImportAlertOpen, setIsCancelImportAlertOpen] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  useEffect(() => {
    const onImportUpdate = (data: { task: IOngoingTask }) => {
      setTask(data.task);
    };
    socket.on("discogs_import_progress", onImportUpdate);
    return () => {
      socket.off("discogs_import_progress", onImportUpdate);
    };
  });
  const importSubtitle = () => {
    switch (task?.status) {
      case "running":
        return strings.discogs_import_subtitle;
      case "finished":
        return strings.discogs_import_finished;
      case "cancelled":
        return strings.discogs_import_cancelled;
      case "errored":
        return strings.discogs_import_errored;
    }
  };
  const progressBarColor = () => {
    switch (task?.status) {
      case "running":
        return "info";
      case "finished":
        return "success";
      case "cancelled":
        return "error";
      case "errored":
        return "error";
    }
  };
  const getCollectionFolders = async (username: string) => {
    setState("folders");
    const { body, status } = await getDiscogsFolders(username);
    if (StatusCodesHelper.isSuccessful(status)) {
      setFolders(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const getDiscogsToken = async () => {
    const { body, status } = await getDiscogsRequestToken();
    if (StatusCodesHelper.isSuccessful(status)) {
      localStorage.setItem("discogs_temp", body.secret);
      localStorage.setItem("discogs_level", body.authLevel);
      window.open(body.authorizeUrl, "_self");
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const startUp = async () => {
    if (currentUser?.role == "guest") return;
    setIsLoading(true);
    const { body, status } = await getDiscogsImportStatus();
    logEvent(analytics, "import_discogs_init");
    if (StatusCodesHelper.isSuccessful(status)) {
      setTask(body);
      if (body.status != "confirmed") {
        setState("importing");
        setIsLoading(false);
      } else {
        await checkDiscogsAccess();
      }
    } else {
      await checkDiscogsAccess();
    }
  };
  const checkDiscogsAccess = async () => {
    const searchParams = queryString.parse(location.search);
    token = searchParams.oauth_token as string;
    verifier = searchParams.oauth_verifier as string;
    const { body, status } = await getDiscogsIdentity();
    if (StatusCodesHelper.isSuccessful(status)) {
      setUsername(body.username);
      await getCollectionFolders(body.username);
    } else if (status == StatusCodes.NOT_FOUND) {
      if (token && verifier) {
        await grantAccess(token, verifier);
      } else {
        await getDiscogsToken();
      }
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
      setIsLoading(false);
    }
  };
  const grantAccess = async (token: string, verifier: string) => {
    const secret = localStorage.getItem("discogs_temp");
    const authLevel = localStorage.getItem("discogs_level");
    if (secret == null || authLevel == null) return;
    localStorage.removeItem("discogs_temp");
    localStorage.removeItem("discogs_level");
    const { body, status } = await grantDiscogsAccess({
      token: token,
      secret: secret,
      authLevel: +authLevel,
      verifier: verifier
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      await checkDiscogsAccess();
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
      setIsLoading(false);
    }
  };
  const clearTimer = () => {
    clearInterval(intervalId);
    setIntervalId(undefined);
  };
  useEffect(() => {
    if (!isOpen) return;
    setState("starting");
    startUp().then();
  }, [isOpen]);
  const handleClose = async () => {
    if (isLoading) return;
    clearTimer();
    if (state == "importing" && task && task.status != "running") {
      setIsLoading(true);
      await confirmDiscogsImport();
      setIsLoading(false);
    }
    setIsOpen(false);
  };
  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose} TransitionComponent={Grow}>
      <div className={styles.dialog} style={{ backgroundColor: theme.palette.background.default }}>
        <Toolbar>
          <h3>{strings.option_discogs_import}</h3>
          <Box flexGrow={1} />
          <ToolbarButton
            href={`${window.location.protocol}//${
              window.location.host
            }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.importFromDiscogs()}`}
            target={"_blank"}
            tooltip={strings.help}
            icon={HelpOutlineRounded}
          />
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent>
          {state == "folders" && folders && (
            <Stack spacing={4}>
              <p className={styles.subtitle}>{strings.discogs_folders_subtitle}</p>
              <p className={styles.message}>{strings.discogs_folders_description}</p>
              <Card className={styles.foldersCard} elevation={5}>
                <Stack>
                  {folders.folders.map((folder, index) => (
                    <div key={folder.id}>
                      <RightDetailListItem
                        icon={FolderRounded}
                        title={folder.name}
                        detail={`${folder.count} ${
                          folder.count == 1 ? strings.album_singular : strings.albums_plural
                        }`}
                        disabled={isLoading}
                        onClick={() => {
                          setSelectedFolder(folder);
                          setIsCollectionsDialogOpen(true);
                        }}
                      />
                      {index < folders.folders.length - 1 && <Divider variant={"inset"} />}
                    </div>
                  ))}
                </Stack>
              </Card>
              <FormControlLabel
                className={styles.checkbox}
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={shouldSkipDuplicates}
                    onChange={() => setShouldSkipDuplicates(state => !state)}
                  />
                }
                label={strings.discogs_should_skip_duplicates}
              />
              <FormControlLabel
                className={styles.checkbox}
                sx={{ width: "100%" }}
                disabled={currentUser?.role != UserRole.premium}
                control={
                  <Checkbox
                    checked={shouldUploadImage}
                    onChange={() => setShouldUploadImage(state => !state)}
                  />
                }
                label={
                  currentUser?.role == UserRole.premium
                    ? strings.discogs_should_upload_image
                    : strings.discogs_should_upload_image_non_premium
                }
              />
            </Stack>
          )}
          {state == "importing" && task && (
            <Stack spacing={4}>
              <p className={styles.subtitle}>{importSubtitle()}</p>
              <p className={styles.message}>{strings.discogs_import_description}</p>
              <div className={styles.progressBar}>
                <CircularProgress
                  sx={{ display: "flex", position: "absolute" }}
                  color={"secondary"}
                  size={150}
                  variant={"determinate"}
                  value={100}
                />
                <CircularProgress
                  sx={{ display: "flex", position: "absolute" }}
                  color={progressBarColor()}
                  size={150}
                  variant={"determinate"}
                  value={(task.progress * 100) / task.objective}
                />
                <p className={styles.progressText}>{task.progress}</p>
              </div>
              {task.status == "running" && (
                <ContainedButton
                  sx={{ alignSelf: "center" }}
                  disabled={isLoading}
                  onClick={async () => {
                    setIsLoading(true);
                    const { body, status } = await cancelDiscogsImport();
                    if (StatusCodesHelper.isSuccessful(status)) {
                      setTask(body);
                    } else {
                      setAlertMessage(body.message);
                      setIsAlertOpen(true);
                    }
                    setIsLoading(false);
                  }}
                >
                  {strings.cancel_import}
                </ContainedButton>
              )}
            </Stack>
          )}
        </DialogContent>
      </div>
      <CollectionsDialog
        open={isCollectionsDialogOpen}
        setOpen={setIsCollectionsDialogOpen}
        canModifyCollections={false}
        displayStandardCollections={true}
        onCollectionSelected={collection => {
          setSelectedCollection(collection);
          setIsConfirmImportAlertOpen(true);
        }}
      />
      <AlertDialog
        message={StringFormat(
          strings.discogs_folders_confirmation,
          String(selectedFolder?.count),
          selectedFolder?.name ?? "",
          selectedCollection?.name ?? ""
        )}
        open={isConfirmImportAlertOpen}
        setOpen={setIsConfirmImportAlertOpen}
        isConfirm
        onConfirm={async () => {
          setIsCollectionsDialogOpen(false);
          setIsLoading(true);
          const { body, status } = await importDiscogsCollection({
            username,
            folderId: selectedFolder?.id ?? 0,
            collections: selectedCollection ? [selectedCollection] : [],
            shouldUploadImage,
            shouldSkipDuplicates
          });
          if (StatusCodesHelper.isSuccessful(status)) {
            setTask(body);
            setState("importing");
          } else {
            setAlertMessage(body.message);
            setIsAlertOpen(true);
          }
          setIsLoading(false);
        }}
      />
      <AlertDialog
        message={strings.discogs_import_cancel_confirmation}
        open={isCancelImportAlertOpen}
        setOpen={setIsCancelImportAlertOpen}
        isConfirm
        onConfirm={async () => {
          setIsLoading(true);
          const { body, status } = await cancelDiscogsImport();
          if (StatusCodesHelper.isSuccessful(status)) {
            setTask(body);
          } else {
            setAlertMessage(body.message);
            setIsAlertOpen(true);
          }
          setIsLoading(false);
        }}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </Dialog>
  );
};

type State = "starting" | "folders" | "importing";

export interface IDiscogsDialogProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}
