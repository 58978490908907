import { useBackend } from "../../services";
import {
  IFilterAlbumsParameters,
  IGetAlbumsParameters,
  IIsNegatedAlbumsParameters,
  useAlbumStorage
} from "./useAlbumStorage";
import { FilterElement, FilterSortOption, FilterSortOrder } from "../../models/FilterElement";

export const useSearchAlbums = <T>(
  isActive: boolean,
  isPublic: boolean,
  isFiltering: boolean,
  isNegated: IIsNegatedAlbumsParameters,
  pageSize: number,
  onLoading: (isLoading: boolean) => void,
  onError: (body: any) => void,
  shareLink?: string | null | (string | null)[],
  filterElements?: FilterElement[],
  sortOption?: FilterSortOption,
  sortOrder?: FilterSortOrder,
  dependencies: T[] = []
) => {
  const { post } = useBackend();
  const searchAlbums = (parameters: IGetAlbumsParameters, filters: IFilterAlbumsParameters) =>
    post("albums/search", { ...parameters, ...filters });
  const searchPublicAlbums = (parameters: IGetAlbumsParameters, filters: IFilterAlbumsParameters) =>
    post("albums/search/shared/public", { ...parameters, ...filters });
  const [
    albums,
    setAlbums,
    resetAlbumStorage,
    moveAlbum,
    deleteAlbum,
    noMoreAlbums,
    isFetchingAlbums
  ] = useAlbumStorage(
    isActive,
    isPublic,
    isFiltering,
    isNegated,
    pageSize,
    isPublic ? searchPublicAlbums : searchAlbums,
    onLoading,
    onError,
    shareLink,
    filterElements,
    sortOption,
    sortOrder,
    dependencies
  );
  return [
    albums,
    setAlbums,
    resetAlbumStorage,
    moveAlbum,
    deleteAlbum,
    noMoreAlbums,
    isFetchingAlbums
  ] as const;
};
