import React, { FunctionComponent, useState } from "react";
import {
  Badge,
  Box,
  Card,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SwipeableDrawer,
  useTheme
} from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import {
  AddCircleOutlineRounded,
  CollectionsBookmarkRounded,
  AssignmentOutlined,
  CasinoOutlined,
  CloudDownloadOutlined,
  LowPriorityRounded,
  ExitToAppRounded,
  FilterAltRounded,
  LayersRounded,
  QueryStatsRounded,
  SettingsRounded,
  PublicRounded,
  PersonAddRounded,
  InventoryRounded,
  DocumentScannerOutlined,
  LockOpenRounded,
  BorderInnerRounded,
  ForumRounded,
  DashboardRounded,
  PlaylistAddRounded
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { PreferencesDialog } from "../PreferencesDialog";
import { AlertDialog } from "../AlertDialog";
import { DiscogsImportDialog } from "../DiscogsImportDialog";
import { CollectionsDialog } from "../collections/CollectionsDialog";
import { ResponsiveIcon } from "../ResponsiveIcon";
import styles from "./styles.module.scss";
import { UserAvatar } from "../UserAvatar";
import { DiscreetLink } from "../DiscreetLink";
import { useSessionManager, useUser, useUserProfile } from "../../hooks/session";
import { MobileButtons } from "../MobileButtons";
import { ExcelImportDialog } from "../ExcelImportDialog";
import { useIsScreenWide } from "../../hooks/isScreenWide";
import { DrawerHeader } from "../DrawerHeader";
import { BuyMeACoffeeButton } from "../BuyMeACoffeeButton";

export const MainDrawer: FunctionComponent<IMainDrawerProps> = ({
  currentPage,
  isOpen,
  setIsOpen,
  isOpenDiscogsImport,
  setIsOpenDiscogsImport,
  isOpenExcelImport,
  setIsOpenExcelImport,
  onFilterAlbumsClicked,
  onRandomAlbumsClicked,
  onNewAlbumClicked,
  onNewBoxSetClicked,
  onBarcodeScanClicked
}) => {
  const theme = useTheme();
  const isScreenWide = useIsScreenWide();
  const navigate = useNavigate();
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const { closeSession } = useSessionManager();
  const [isOpenManageCollectionsDialog, setIsOpenManageCollectionsDialog] = useState(false);
  const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] = useState(false);
  const [isReorderAlertOpen, setIsReorderAlertOpen] = useState(false);
  const list = () => (
    <Box sx={{ width: 310, overflow: "auto" }} role="presentation">
      <List style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
        {currentUser?.role == "admin" && (
          <Card sx={{ margin: 1, marginBottom: 3 }}>
            <ListItemButton key={"admin"} onClick={() => navigate(RoutesBuilder.admin())}>
              <ListItemIcon>
                <ResponsiveIcon icon={LockOpenRounded} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={"Admin console"}
              />
            </ListItemButton>
          </Card>
        )}
        <Card sx={{ margin: 1, marginBottom: 3 }}>
          <DiscreetLink
            to={RoutesBuilder.userProfile()}
            disabled={
              currentUser === undefined ||
              currentUserProfile === undefined ||
              currentUser?.role == "guest"
            }
          >
            <ListItemButton key={"User"} selected={currentPage == "profile"}>
              <ListItemAvatar sx={{ minWidth: "48px" }}>
                <Badge
                  overlap={"circular"}
                  badgeContent={currentUserProfile?.unreadNotifications}
                  color={"error"}
                >
                  <UserAvatar className={styles.avatar} userProfile={currentUserProfile} />
                </Badge>
              </ListItemAvatar>
              {currentUserProfile ? (
                <ListItemText
                  disableTypography
                  className={styles.usernameTitle}
                  primary={
                    currentUser?.role != "guest" ? currentUserProfile.username : strings.guest_title
                  }
                />
              ) : (
                <Skeleton variant={"text"} width={"100%"} height={20} />
              )}
            </ListItemButton>
          </DiscreetLink>
          <Divider variant={"inset"} />
          {currentUser?.role == "guest" ? (
            <ListItemButton
              key={"NewUser"}
              onClick={() => {
                closeSession();
                navigate(RoutesBuilder.signIn());
              }}
            >
              <ListItemIcon>
                <ResponsiveIcon icon={PersonAddRounded} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={strings.login_create_user_or_login}
              />
            </ListItemButton>
          ) : (
            currentUser && (
              <ListItemButton
                key={"Chats"}
                selected={currentPage == "chats"}
                onClick={() => navigate(RoutesBuilder.chats())}
              >
                <ListItemIcon>
                  <ResponsiveIcon icon={ForumRounded} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={styles.itemTitle}
                  primary={strings.chats_title}
                />
                <Box
                  className={styles.betaTag}
                  style={{ backgroundColor: theme.palette.colors?.discount }}
                >
                  <p>BETA</p>
                </Box>
              </ListItemButton>
            )
          )}
        </Card>
        <Card sx={{ margin: 1 }}>
          <DiscreetLink to={RoutesBuilder.albums.list()}>
            <ListItemButton key={"Albums"} selected={currentPage == "albums"}>
              <ListItemIcon>
                <ResponsiveIcon icon={CollectionsBookmarkRounded} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={strings.albums_title}
              />
            </ListItemButton>
          </DiscreetLink>
          <Divider variant={"inset"} />
          <DiscreetLink to={RoutesBuilder.layout()}>
            <ListItemButton key={"Layout"} selected={currentPage == "layout"}>
              <ListItemIcon>
                <ResponsiveIcon icon={DashboardRounded} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={strings.layout_title}
              />
              <Box
                className={styles.betaTag}
                style={{ backgroundColor: theme.palette.colors?.discount }}
              >
                <p>BETA</p>
              </Box>
            </ListItemButton>
          </DiscreetLink>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"Collections"}
            onClick={() => {
              setIsOpenManageCollectionsDialog(true);
            }}
          >
            <ListItemIcon>
              <ResponsiveIcon icon={LayersRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.collections_manage_title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <DiscreetLink to={RoutesBuilder.attributes.manage()}>
            <ListItemButton key={"Attributes"} selected={currentPage == "attributes"}>
              <ListItemIcon>
                <ResponsiveIcon icon={AssignmentOutlined} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={strings.option_attributes}
              />
            </ListItemButton>
          </DiscreetLink>
          <Divider variant={"inset"} />
          <DiscreetLink to={currentUser?.role == "guest" ? "#" : RoutesBuilder.stats()}>
            <ListItemButton
              key={"Stats"}
              selected={currentPage == "stats"}
              disabled={currentUser?.role == "guest"}
            >
              <ListItemIcon>
                <ResponsiveIcon icon={QueryStatsRounded} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={strings.option_stats}
              />
            </ListItemButton>
          </DiscreetLink>
          <Divider variant={"inset"} />
          <DiscreetLink to={RoutesBuilder.marketplace()}>
            <ListItemButton
              key={"Marketplace"}
              selected={["marketplace", "publicAlbums"].includes(currentPage)}
            >
              <ListItemIcon>
                <ResponsiveIcon icon={PublicRounded} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={strings.option_marketplace}
              />
            </ListItemButton>
          </DiscreetLink>
        </Card>
        {currentPage == "albums" && (
          <Card sx={{ margin: 1 }}>
            <div>
              <ListItemButton key={"NewAlbum"} onClick={() => onNewAlbumClicked()}>
                <ListItemIcon>
                  <ResponsiveIcon icon={AddCircleOutlineRounded} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={styles.itemTitle}
                  primary={strings.ca_title}
                />
              </ListItemButton>
              <Divider variant={"inset"} />
              <ListItemButton
                key={"BarcodeScan"}
                onClick={() => {
                  if (onBarcodeScanClicked) {
                    onBarcodeScanClicked();
                  }
                }}
              >
                <ListItemIcon>
                  <ResponsiveIcon icon={DocumentScannerOutlined} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={styles.itemTitle}
                  primary={strings.option_scan_barcode}
                />
              </ListItemButton>
              <Divider variant={"inset"} />
              <ListItemButton key={"NewBoxSet"} onClick={() => onNewBoxSetClicked()}>
                <ListItemIcon>
                  <ResponsiveIcon icon={PlaylistAddRounded} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={styles.itemTitle}
                  primary={strings.new_box_set_title}
                />
              </ListItemButton>
              <Divider variant={"inset"} />
              <ListItemButton key={"Reorder"} onClick={() => setIsReorderAlertOpen(true)}>
                <ListItemIcon>
                  <ResponsiveIcon icon={LowPriorityRounded} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={styles.itemTitle}
                  primary={strings.option_reorder_albums}
                />
              </ListItemButton>
              <Divider variant={"inset"} />
              <ListItemButton
                key={"Filter"}
                onClick={() => {
                  if (onFilterAlbumsClicked) {
                    onFilterAlbumsClicked();
                  }
                }}
              >
                <ListItemIcon>
                  <ResponsiveIcon icon={FilterAltRounded} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={styles.itemTitle}
                  primary={strings.option_filter_albums}
                />
              </ListItemButton>
              <Divider variant={"inset"} />
              <ListItemButton
                key={"Random"}
                onClick={() => {
                  if (onRandomAlbumsClicked) {
                    onRandomAlbumsClicked();
                  }
                }}
              >
                <ListItemIcon>
                  <ResponsiveIcon icon={CasinoOutlined} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={styles.itemTitle}
                  primary={strings.option_random_albums}
                />
              </ListItemButton>
            </div>
          </Card>
        )}
        <Card sx={{ margin: 1 }}>
          <ListItemButton
            key={"DiscogsImport"}
            onClick={() => {
              setIsOpenDiscogsImport(true);
            }}
          >
            <ListItemIcon>
              <ResponsiveIcon icon={CloudDownloadOutlined} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.option_discogs_import}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"ExcelImport"}
            onClick={() => {
              setIsOpenExcelImport(true);
            }}
          >
            <ListItemIcon>
              <ResponsiveIcon icon={BorderInnerRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.option_excel_import}
            />
          </ListItemButton>
        </Card>
        <Card sx={{ margin: 1 }}>
          <ListItemButton
            key={"Preferences"}
            onClick={() => {
              setIsPreferencesDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <ResponsiveIcon icon={SettingsRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.option_preferences}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <DiscreetLink to={RoutesBuilder.documentation.home()}>
            <ListItemButton key={"Documentation"}>
              <ListItemIcon>
                <ResponsiveIcon icon={InventoryRounded} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={strings.documentation}
              />
            </ListItemButton>
          </DiscreetLink>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"CloseSession"}
            onClick={() => {
              closeSession();
              navigate(RoutesBuilder.signIn());
            }}
          >
            <ListItemIcon>
              <ResponsiveIcon icon={ExitToAppRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.option_close_session}
            />
          </ListItemButton>
        </Card>
        <MobileButtons />
        <BuyMeACoffeeButton />
      </List>
    </Box>
  );
  return (
    <div>
      <SwipeableDrawer
        anchor={"left"}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.colors?.drawerBackgroundColor,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.7)"
          }
        }}
        variant={isScreenWide ? "persistent" : "temporary"}
      >
        <DrawerHeader setIsOpen={setIsOpen} />
        {list()}
      </SwipeableDrawer>
      <CollectionsDialog
        open={isOpenManageCollectionsDialog}
        setOpen={setIsOpenManageCollectionsDialog}
        canModifyCollections={true}
        displayStandardCollections={false}
      />
      <PreferencesDialog
        isOpen={isPreferencesDialogOpen}
        setIsOpen={setIsPreferencesDialogOpen}
        canRemoveAccount={false}
      />
      <DiscogsImportDialog isOpen={isOpenDiscogsImport} setIsOpen={setIsOpenDiscogsImport} />
      <ExcelImportDialog isOpen={isOpenExcelImport} setIsOpen={setIsOpenExcelImport} />
      <AlertDialog
        message={strings.reorder_tutorial}
        open={isReorderAlertOpen}
        setOpen={setIsReorderAlertOpen}
        helpLink={`${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.orderAlbums()}`}
        hasCloseButton
      />
    </div>
  );
};

export interface IMainDrawerProps {
  currentPage:
    | "albums"
    | "attributes"
    | "stats"
    | "marketplace"
    | "publicAlbums"
    | "profile"
    | "chats"
    | "layout";
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isOpenDiscogsImport: boolean;
  setIsOpenDiscogsImport: (isOpen: boolean) => void;
  isOpenExcelImport: boolean;
  setIsOpenExcelImport: (isOpen: boolean) => void;
  onFilterAlbumsClicked?: () => void;
  onRandomAlbumsClicked?: () => void;
  onNewAlbumClicked?: () => void;
  onNewBoxSetClicked?: () => void;
  onBarcodeScanClicked?: () => void;
}
