import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { MainContent } from "../../components/MainContent";
import { IAlbum, IIsNegatedAlbumsParameters, useSearchAlbums } from "../../hooks/albums";
import { AlbumList } from "../../components/albums/AlbumList";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Stack,
  SvgIcon,
  SwipeableDrawer,
  Toolbar,
  useTheme
} from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { SearchBar } from "../../components/SearchBar";
import {
  CloseRounded,
  DragIndicator,
  ExpandCircleDownOutlined,
  FilterAltOffRounded,
  FilterAltRounded,
  HighlightRounded,
  ListAltRounded,
  RefreshRounded
} from "@mui/icons-material";
import { MainDrawer } from "../../components/MainDrawer";
import { AlertDialog } from "../../components/AlertDialog";
import { AlbumsFilterDialog } from "../../components/albums/AlbumsFilter";
import { FilterElement, FilterSortOption, FilterSortOrder } from "../../models/FilterElement";
import { useIsMounted } from "../../hooks/isMounted";
import { CollectionsDialog } from "../../components/collections/CollectionsDialog";
import "react-toastify/dist/ReactToastify.css";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { ResponsiveIcon } from "../../components/ResponsiveIcon";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { UserProfileCard } from "../../components/userProfiles/UserProfileCard";
import { PiperLogoButton } from "../../components/PiperLogoButton";
import { AppCredits } from "../../components/AppCredits";
import { ToolbarBox } from "../../components/toolbar/ToolbarBox";
import { ToolbarButton } from "../../components/toolbar/ToolbarButton";
import { ToolbarRawButton } from "../../components/toolbar/ToolbarRawButton";
import { useIsMobile } from "../../hooks/isMobile";
import {
  useCollectionManager,
  useCurrentPrivateCollection
} from "../../hooks/collections/useCollectionManager";
import { useUser, useUserProfile } from "../../hooks/session";
import { useIsScreenWide } from "../../hooks/isScreenWide";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { UserRole } from "../../hooks/users";
import {
  useAlbumFilterManager,
  useCurrentLayoutFilterElements,
  useCurrentLayoutFilterSortOption,
  useCurrentLayoutFilterSortOrder
} from "../../hooks/albums/useAlbumFilterManager";
import { LayoutDetail } from "../../components/layout/LayoutDetail";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import { useAddAlbumToShelfSegment } from "../../hooks/shelfSegments/useAddAlbumToShelfSegment";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { useGetShelvings } from "../../hooks/shelvings/useGetShelvings";
import { useRemoveAlbumFromShelfSegment } from "../../hooks/shelfSegments/useRemoveAlbumFromShelfSegment";
import { useReorderShelfSegmentAlbums } from "../../hooks/shelfSegments/useReorderShelfSegmentAlbums";
import { arrayMoveMutable } from "array-move";
import {
  IShelfSegment,
  IShelfSegmentAlbum,
  ShelfSegmentAlbumShape
} from "../../hooks/shelfSegments";
import { ModifyShelfSegmentAlbumDialog } from "../../components/layout/ModifyShelfSegmentAlbumDialog";
import { IShelving } from "../../hooks/shelvings";
import { useAssignAlbumsToShelfSegmentByFilter } from "../../hooks/shelfSegments/useAssignAlbumsToShelfSegmentByFilter";

export const Layout: FunctionComponent<ILayoutPageProps> = ({ onPlayFromYouTube }) => {
  const isMounted = useIsMounted();
  const theme = useTheme();
  const navigate = useNavigate();
  const isScreenWide = useIsScreenWide();
  const isMobile = useIsMobile();
  const [isDragging, setIsDragging] = useState(false);
  const [isLoadingAlbums, setIsLoadingAlbums] = useState(false);
  const [isLoadingShelvings, setIsLoadingShelvings] = useState(false);
  const [highlightFilteredAlbums, setHighlightFilteredAlbums] = useState(false);
  const [isAddAlbumsByFilterShapeDialogOpen, setIsAddAlbumsByFilterShapeDialogOpen] =
    useState(false);
  const [isModifyShelfSegmentAlbumDialogOpen, setIsModifyShelfSegmentAlbumDialogOpen] =
    useState(false);
  const [incomingAlbumId, setIncomingAlbumId] = useState<string>();
  const [incomingShelfSegmentId, setIncomingShelfSegmentId] = useState<string>();
  const [incomingIndex, setIncomingIndex] = useState<number>();
  const [shelvings, , setShelvings] = useGetShelvings(setIsLoadingShelvings);
  const { addAlbumToShelfSegment } = useAddAlbumToShelfSegment();
  const { assignAlbumsToShelfSegmentByFilter } = useAssignAlbumsToShelfSegmentByFilter();
  const { removeAlbumFromShelfSegment } = useRemoveAlbumFromShelfSegment();
  const { reorderShelfSegmentAlbums } = useReorderShelfSegmentAlbums();
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const [needMoreAllAlbums, setNeedMoreAllAlbums] = useState(false);
  const {
    getCurrentFilterElements,
    getCurrentIsNegated,
    getCurrentFilterSortOption,
    getCurrentFilterSortOrder,
    getFilterElementsWithSearchText,
    getSearchText
  } = useAlbumFilterManager(true);
  const [, setCurrentLayoutFilterElements] = useCurrentLayoutFilterElements.useState();
  const [filterElements, setFilterElements] = useState<FilterElement[]>(getCurrentFilterElements());
  const [isNegated, setIsNegated] = useState<IIsNegatedAlbumsParameters>(getCurrentIsNegated());
  const [, setCurrentLayoutFilterSortOption] = useCurrentLayoutFilterSortOption.useState();
  const [sortOption, setSortOption] = useState(getCurrentFilterSortOption());
  const [, setCurrentLayoutFilterSortOrder] = useCurrentLayoutFilterSortOrder.useState();
  const [sortOrder, setSortOrder] = useState(getCurrentFilterSortOrder());
  const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenWide);
  const [isAlbumListDrawerOpen, setIsAlbumListDrawerOpen] = useState(false);
  const [isOpenDiscogsImport, setIsOpenDiscogsImport] = useState(false);
  const [isOpenExcelImport, setIsOpenExcelImport] = useState(false);
  const [isAlbumsFilterDialogOpen, setIsAlbumsFilterDialogOpen] = useState(false);
  const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
  const [selectedShelving, setSelectedShelving] = useState<IShelving>();
  const [selectedAlbum, setSelectedAlbum] = useState<IAlbum>();
  const { getCurrentCollection } = useCollectionManager();
  const [currentPrivateCollection, setCurrentPrivateCollection] =
    useCurrentPrivateCollection.useState();
  const [scrollToTopAlbumList, setScrollToTopAlbumList] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  useEffect(() => {
    document.title = `${strings.app_name} - ${strings.albums_title}`;
  }, []);
  useEffect(() => {
    setFilterElements(getCurrentFilterElements());
    setIsNegated(getCurrentIsNegated());
    setSortOption(getCurrentFilterSortOption());
    setSortOrder(getCurrentFilterSortOrder());
  }, []);
  const onError = (body: any) => {
    if (isMounted.current) {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
  };
  const onLoadingAlbums = (isLoading: boolean) => {
    if (isMounted.current) {
      setIsLoadingAlbums(isLoading);
    }
  };
  const isFilterUnapplied = () => {
    return (
      (!filterElements || filterElements.length == 0) &&
      sortOption == FilterSortOption.ARTIST &&
      sortOrder == FilterSortOrder.ASCENDANT
    );
  };
  useEffect(() => {
    setCurrentLayoutFilterElements(filterElements);
  }, [filterElements]);
  useEffect(() => {
    setCurrentLayoutFilterSortOption(sortOption);
  }, [sortOption]);
  useEffect(() => {
    setCurrentLayoutFilterSortOrder(sortOrder);
  }, [sortOrder]);
  const [albums, , resetAlbumsStorage, , , noMoreAlbums, isFetchingAlbums] = useSearchAlbums(
    true,
    false,
    !isFilterUnapplied(),
    isNegated,
    PageSizeHelper.albums(currentUser?.role ?? UserRole.common),
    onLoadingAlbums,
    onError,
    undefined,
    filterElements,
    sortOption,
    sortOrder,
    [needMoreAllAlbums]
  );
  const resetAlbumStorage = () => {
    setScrollToTopAlbumList(state => !state);
    resetAlbumsStorage();
  };
  const setNeedMoreAlbums = () => {
    if (isMounted.current) {
      setNeedMoreAllAlbums(state => !state);
    }
  };
  const isThereNoMoreAlbums = () => {
    if (isMounted.current) {
      return noMoreAlbums;
    }
  };
  useEffect(() => {
    resetAlbumStorage();
    setNeedMoreAlbums();
  }, [isMobile, currentPrivateCollection]);
  const goToUserProfile = () => {
    navigate(RoutesBuilder.userProfile());
  };
  const getAlbumIds = () => {
    if (!albums) return [];
    const ids = albums.map(section => section.map(album => album.uuid));
    return ids.flat(1);
  };
  const refreshAlbumsOrder = (shelvingIndex: number, shelfIndex: number, segmentIndex: number) => {
    shelvings[shelvingIndex].shelves[shelfIndex].segments[segmentIndex].albums?.forEach(
      (album, index) => {
        album.order = index;
      }
    );
  };
  const addAlbum = async (
    albumId: string,
    shelfSegmentId: string,
    shape: ShelfSegmentAlbumShape,
    index: number
  ) => {
    const { status, body } = await addAlbumToShelfSegment({
      uuid: shelfSegmentId,
      albumId: albumId,
      shape: shape,
      order: index
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.shelf_album_added_message);
      return body;
    }
    if (StatusCodesHelper.isDuplicatedResource(status)) {
      toast.warning(strings.shelf_album_duplicate_error);
      return false;
    }
    setAlertMessage(body.message);
    setIsAlertOpen(true);
    return false;
  };
  const addAlbumsByFilter = async (
    shelfSegmentId: string,
    shape: ShelfSegmentAlbumShape,
    index: number
  ) => {
    const { status, body } = await assignAlbumsToShelfSegmentByFilter(
      {
        assignToShelfSegmentId: shelfSegmentId,
        shape: shape,
        order: index
      },
      filterElements
    );
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      return body;
    }
    setAlertMessage(body.message);
    setIsAlertOpen(true);
    return false;
  };
  const removeAlbum = async (albumId: string, shelfSegmentId: string) => {
    const { status, body } = await removeAlbumFromShelfSegment({
      uuid: shelfSegmentId,
      albumId: albumId
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.shelf_album_removed_message);
      return true;
    }
    setAlertMessage(body.message);
    setIsAlertOpen(true);
    return false;
  };
  const reorderAlbums = async (shelfSegmentId: string, fromIndex: number, toIndex: number) => {
    const { status, body } = await reorderShelfSegmentAlbums(shelfSegmentId, fromIndex, toIndex);
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      return true;
    }
    setAlertMessage(body.message);
    setIsAlertOpen(true);
    return false;
  };
  const moveAlbum = async (
    sourceSegmentId: string,
    destinationSegmentId: string,
    albumId: string,
    sourceIndex: number,
    destinationIndex: number,
    sourceShelvingIndex: number,
    sourceShelfIndex: number,
    sourceSegmentIndex: number,
    destinationShelvingIndex: number,
    destinationShelfIndex: number,
    destinationSegmentIndex: number
  ) => {
    if (
      sourceShelvingIndex == destinationShelvingIndex &&
      sourceShelfIndex == destinationShelfIndex &&
      sourceSegmentIndex == destinationSegmentIndex &&
      sourceIndex == destinationIndex
    ) {
      return;
    }
    const album =
      shelvings[sourceShelvingIndex].shelves[sourceShelfIndex].segments[sourceSegmentIndex].albums[
        sourceIndex
      ];
    if (sourceSegmentId != destinationSegmentId) {
      shelvings[sourceShelvingIndex].shelves[sourceShelfIndex].segments[
        sourceSegmentIndex
      ].albums.splice(sourceIndex, 1);
      shelvings[destinationShelvingIndex].shelves[destinationShelfIndex].segments[
        destinationSegmentIndex
      ].albums.splice(destinationIndex, 0, album);
      refreshAlbumsOrder(destinationShelvingIndex, destinationShelfIndex, destinationSegmentIndex);
      let removalSuccess;
      const addSuccess = await addAlbum(
        albumId,
        destinationSegmentId,
        album.shape,
        destinationIndex
      );
      if (addSuccess) {
        removalSuccess = await removeAlbum(albumId, sourceSegmentId);
      }
      if (!addSuccess || !removalSuccess) {
        shelvings[sourceShelvingIndex].shelves[sourceShelfIndex].segments[
          sourceSegmentIndex
        ].albums.splice(sourceIndex, 0, album);
        shelvings[destinationShelvingIndex].shelves[destinationShelfIndex].segments[
          destinationSegmentIndex
        ].albums.splice(destinationIndex, 1);
        refreshAlbumsOrder(sourceShelvingIndex, sourceShelfIndex, sourceSegmentIndex);
      }
    } else {
      arrayMoveMutable(
        shelvings[sourceShelvingIndex].shelves[sourceShelfIndex].segments[sourceSegmentIndex]
          .albums,
        sourceIndex,
        destinationIndex
      );
      refreshAlbumsOrder(sourceShelvingIndex, sourceShelfIndex, sourceSegmentIndex);
      const reorderSuccess = await reorderAlbums(sourceSegmentId, sourceIndex, destinationIndex);
      if (!reorderSuccess) {
        arrayMoveMutable(
          shelvings[sourceShelvingIndex].shelves[sourceShelfIndex].segments[sourceSegmentIndex]
            .albums,
          destinationIndex,
          sourceIndex
        );
        refreshAlbumsOrder(sourceShelvingIndex, sourceShelfIndex, sourceSegmentIndex);
      }
    }
  };
  const saveNewSegment = (newSegment: IShelfSegment) => {
    setShelvings(
      shelvings.map(shelving => {
        if (shelving.uuid == selectedShelving.uuid) {
          shelving.shelves = shelving.shelves.map(shelf => {
            shelf.segments = shelf.segments.map(segment =>
              segment.uuid == newSegment.uuid ? newSegment : segment
            );
            return shelf;
          });
        }
        return shelving;
      })
    );
  };
  const onDragEnd = async (result: DropResult) => {
    setIsDragging(false);
    const destinationSplit = result.destination?.droppableId.split("_");
    if (!result.destination || destinationSplit[0] != "shelf") return;
    const split = result.draggableId.split("_");
    if (split[0] == "drag") {
      setIsLoadingShelvings(true);
      await moveAlbum(
        split[1],
        destinationSplit[1],
        split[2],
        result.source.index,
        result.destination.index,
        +split[3],
        +split[4],
        +split[5],
        +destinationSplit[2],
        +destinationSplit[3],
        +destinationSplit[4]
      );
      setIsLoadingShelvings(false);
    } else {
      const section = +split[0];
      const album = albums[section][result.source.index];
      setIncomingAlbumId(album.uuid);
      setIncomingShelfSegmentId(destinationSplit[1]);
      setIncomingIndex(result.destination.index);
      setIsModifyShelfSegmentAlbumDialogOpen(true);
    }
  };
  const onRemoveAlbumFromShelfSegment = async (
    shelfSegmentAlbum: IShelfSegmentAlbum,
    shelvingIndex: number,
    shelfIndex: number,
    shelfSegmentIndex: number,
    albumIndex: number
  ) => {
    const shelfSegment = shelvings[shelvingIndex].shelves[shelfIndex].segments[shelfSegmentIndex];
    if (!shelfSegment) return;
    setIsLoadingShelvings(true);
    const { status, body } = await removeAlbumFromShelfSegment({
      uuid: shelfSegment.uuid,
      albumId: shelfSegmentAlbum.album.uuid
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      shelvings[shelvingIndex].shelves[shelfIndex].segments[shelfSegmentIndex].albums?.splice(
        albumIndex,
        1
      );
      refreshAlbumsOrder(shelvingIndex, shelfIndex, shelfSegmentIndex);
      toast.success(strings.generic_update_success);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingShelvings(false);
  };
  const albumList = () => (
    <AlbumList
      dragDropId={"main"}
      isPublic={false}
      albums={albums}
      setIsLoading={setIsLoadingAlbums}
      selectedAlbumId={selectedAlbum?.uuid}
      sortOption={sortOption}
      canMoveAlbums={!isLoadingAlbums && !isLoadingShelvings}
      canDropAlbums={false}
      canLoadMoreAlbums={!isDragging}
      setNeedMoreAlbums={setNeedMoreAlbums}
      onAlbumSelected={album => {
        setIsAlbumListDrawerOpen(false);
        if (selectedAlbum?.uuid != album.uuid) {
          setSelectedAlbum(album);
        } else {
          setSelectedAlbum(undefined);
        }
      }}
      noMoreAlbums={isThereNoMoreAlbums()}
      albumCount={undefined}
      onImportFromDiscogs={() => setIsOpenDiscogsImport(true)}
      onImportFromExcel={() => setIsOpenExcelImport(true)}
      onFilterAlbums={() => setIsAlbumsFilterDialogOpen(true)}
      onChangeCollection={() => setIsCollectionsDialogOpen(true)}
      onEditProfile={goToUserProfile}
      onPlayFromYouTube={onPlayFromYouTube}
      scrollToTopTrigger={scrollToTopAlbumList}
      isForLayout={true}
      shelving={selectedShelving}
    />
  );
  const toolbarButtons = () => (
    <Stack direction={"row"} style={{ alignItems: "center" }}>
      <ToolbarBox
        style={{ marginLeft: isMobile ? 0 : undefined, marginRight: isMobile ? 0 : undefined }}
      >
        <ToolbarRawButton
          onClick={() => {
            if (isLoadingAlbums) return;
            resetAlbumStorage();
            setNeedMoreAlbums();
          }}
          tooltip={strings.refresh_list}
          icon={RefreshRounded}
        />
        <Divider orientation="vertical" flexItem />
        <ToolbarRawButton
          onClick={() => setIsAlbumsFilterDialogOpen(true)}
          tooltip={strings.option_filter_albums}
          icon={FilterAltRounded}
          highlighted={!isFilterUnapplied()}
        />
      </ToolbarBox>
      <SearchBar
        className={styles.searchBar}
        onSearchRequested={searchText => {
          setFilterElements(getFilterElementsWithSearchText(searchText, filterElements));
          resetAlbumStorage();
          setNeedMoreAlbums();
        }}
        onSearchDismissed={() => {
          setFilterElements(getFilterElementsWithSearchText("", filterElements));
          resetAlbumStorage();
          setNeedMoreAlbums();
        }}
        timed
        onLoading={onLoadingAlbums}
        forceThisText={getSearchText(filterElements)}
        disabled={isFetchingAlbums}
      />
    </Stack>
  );
  return (
    <MainContent showingPersistentDrawer={isScreenWide && isDrawerOpen}>
      <AppBar
        position="sticky"
        sx={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.colors?.appBar
        }}
      >
        <Toolbar className={styles.toolbar} disableGutters>
          {(!isScreenWide || !isDrawerOpen) && (
            <PiperLogoButton
              className={styles.piperLogo}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          )}
          <MainDrawer
            currentPage={"layout"}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            isOpenDiscogsImport={isOpenDiscogsImport}
            setIsOpenDiscogsImport={setIsOpenDiscogsImport}
            isOpenExcelImport={isOpenExcelImport}
            setIsOpenExcelImport={setIsOpenExcelImport}
          />
          {isMobile && (
            <ToolbarButton
              onClick={() => setIsAlbumListDrawerOpen(true)}
              tooltip={strings.open_albums_list}
              icon={ListAltRounded}
            />
          )}
          <ToolbarBox
            style={{ marginLeft: isMobile ? 0 : 16, marginRight: isMobile ? 0 : undefined }}
          >
            <Button
              sx={{ maxHeight: isMobile ? 30 : 36, maxWidth: isMobile ? 60 : undefined }}
              onClick={() => setIsCollectionsDialogOpen(true)}
            >
              <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <div className={styles.collectionTitle}>
                  {getCurrentCollection(false) ? (
                    <p className={styles.collectionTitleText}>
                      {getCurrentCollection(false)?.name}
                    </p>
                  ) : (
                    <p className={styles.collectionTitleText}>{strings.collection_all_albums}</p>
                  )}
                </div>
              </Box>
              {<ResponsiveIcon icon={ExpandCircleDownOutlined} />}
            </Button>
          </ToolbarBox>
          {!isMobile && toolbarButtons()}
          <ToolbarButton
            style={{ marginLeft: isMobile ? 0 : undefined, marginRight: isMobile ? 0 : undefined }}
            onClick={() => {
              setSortOption(FilterSortOption.ARTIST);
              setSortOrder(FilterSortOrder.ASCENDANT);
              setFilterElements([]);
              resetAlbumStorage();
              setNeedMoreAlbums();
            }}
            disabled={isFilterUnapplied() || isLoadingAlbums}
            loading={isLoadingAlbums || isLoadingShelvings}
            tooltip={isLoadingAlbums || isLoadingShelvings ? "" : strings.clear_filter}
            icon={FilterAltOffRounded}
            color={theme.palette.colors?.remove}
          />
          <ToolbarButton
            onClick={() => setHighlightFilteredAlbums(state => !state)}
            tooltip={strings.highlight_filtered_albums}
            icon={HighlightRounded}
            highlighted={highlightFilteredAlbums}
          />
          <Box flexGrow={1} />
          <UserProfileCard
            isPublic={false}
            userProfile={currentUserProfile}
            onClick={() => {
              if (!currentUserProfile || currentUser?.role == "guest") return;
              goToUserProfile();
            }}
          />
        </Toolbar>
      </AppBar>
      <DragDropContext
        onBeforeCapture={() => setIsAlbumListDrawerOpen(false)}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={onDragEnd}
      >
        <PanelGroup className={styles.mainBody} autoSaveId={"persistence"} direction={"horizontal"}>
          {!isMobile && (
            <Panel
              className={styles.albumListContainer}
              style={{ backgroundColor: theme.palette.background.default }}
              minSize={6}
              defaultSize={28}
              maxSize={40}
            >
              {albumList()}
            </Panel>
          )}
          <PanelResizeHandle
            className={styles.resizeHandle}
            style={{
              backgroundColor: theme.palette.colors?.main
            }}
          >
            {!isMobile && (
              <SvgIcon
                component={DragIndicator}
                sx={{ alignSelf: "center", color: theme.palette.secondary.main, fontSize: 14 }}
              />
            )}
          </PanelResizeHandle>
          <Panel
            className={styles.layoutDetail}
            style={{ backgroundColor: theme.palette.colors?.main }}
          >
            <LayoutDetail
              shelvings={shelvings}
              setShelvings={setShelvings}
              selectedAlbumIds={
                selectedAlbum ? [selectedAlbum.uuid] : highlightFilteredAlbums ? getAlbumIds() : []
              }
              isLoading={isLoadingShelvings}
              setIsLoading={setIsLoadingShelvings}
              onShelfSegmentAlbumSelected={shelfSegmentAlbum =>
                setSelectedAlbum(shelfSegmentAlbum?.album)
              }
              onAssignAlbumsToShelfSegmentByFilter={shelfSegment => {
                setIncomingShelfSegmentId(shelfSegment.uuid);
                setIncomingIndex(shelfSegment.albums?.length ?? 0);
                setIsAddAlbumsByFilterShapeDialogOpen(true);
              }}
              onRemoveAlbumFromShelfSegment={onRemoveAlbumFromShelfSegment}
              onSelectedShelving={setSelectedShelving}
            />
            <AppCredits />
          </Panel>
        </PanelGroup>
        {isMobile && (
          <SwipeableDrawer
            PaperProps={{
              sx: {
                backgroundColor: theme.palette.background.default
              }
            }}
            ModalProps={{ keepMounted: true }}
            anchor={"left"}
            open={isAlbumListDrawerOpen}
            onOpen={() => setIsAlbumListDrawerOpen(true)}
            onClose={() => setIsAlbumListDrawerOpen(false)}
          >
            <div
              style={{
                width: "80vw",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <AppBar position={"sticky"}>
                <Toolbar className={styles.toolbar} disableGutters>
                  {toolbarButtons()}
                  <Box flexGrow={1} />
                  <ToolbarButton
                    style={{
                      marginLeft: isMobile ? 0 : undefined,
                      marginRight: isMobile ? 0 : undefined
                    }}
                    loading={isLoadingAlbums}
                    onClick={() => setIsAlbumListDrawerOpen(false)}
                    tooltip={strings.close}
                    icon={CloseRounded}
                  />
                </Toolbar>
              </AppBar>
              {albumList()}
            </div>
          </SwipeableDrawer>
        )}
      </DragDropContext>
      <AlbumsFilterDialog
        isPublic={false}
        isOpen={isAlbumsFilterDialogOpen}
        setIsOpen={setIsAlbumsFilterDialogOpen}
        initialFilterElements={filterElements}
        initialIsNegated={isNegated}
        initialSortOption={sortOption}
        initialSortOrder={sortOrder}
        onFilterApplied={(filterElements, isNegated, sortOption, sortOrder) => {
          setIsNegated(isNegated);
          setSortOption(sortOption);
          setSortOrder(sortOrder);
          setFilterElements(filterElements);
          resetAlbumStorage();
          setNeedMoreAlbums();
        }}
      />
      <CollectionsDialog
        open={isCollectionsDialogOpen}
        setOpen={setIsCollectionsDialogOpen}
        filterElements={filterElements}
        canModifyCollections={false}
        displayStandardCollections={true}
        onCollectionSelected={collection => {
          setIsCollectionsDialogOpen(false);
          setCurrentPrivateCollection(collection);
          setSelectedAlbum(undefined);
          setIsAlbumListDrawerOpen(true);
        }}
      />
      <ModifyShelfSegmentAlbumDialog
        open={isModifyShelfSegmentAlbumDialogOpen}
        setOpen={setIsModifyShelfSegmentAlbumDialogOpen}
        onSuccess={async shape => {
          setIsLoadingShelvings(true);
          const newSegment = await addAlbum(
            incomingAlbumId,
            incomingShelfSegmentId,
            shape,
            incomingIndex
          );
          if (newSegment) {
            saveNewSegment(newSegment);
          }
          setIsLoadingShelvings(false);
        }}
      />
      <ModifyShelfSegmentAlbumDialog
        open={isAddAlbumsByFilterShapeDialogOpen}
        setOpen={setIsAddAlbumsByFilterShapeDialogOpen}
        onSuccess={async shape => {
          setIsLoadingShelvings(true);
          const newSegment = await addAlbumsByFilter(incomingShelfSegmentId, shape, incomingIndex);
          if (newSegment) {
            saveNewSegment(newSegment);
          }
          setIsLoadingShelvings(false);
        }}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </MainContent>
  );
};

export interface ILayoutPageProps {
  onPlayFromYouTube: (title: string, videoIds: string[]) => void;
}
