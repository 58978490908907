import React, { FunctionComponent } from "react";
import { IAlbum } from "../../../../hooks/albums";
import { Divider, List } from "@mui/material";
import styles from "./styles.module.scss";
import { AlbumContentItem } from "../AlbumContentItem";
import { IAlbumContent } from "../../../../hooks/albumContents";
import { strings } from "../../../../localization/LocalizedStrings";
import { capitalizeFirstLetter } from "../../../../models/StringFormat";

export const AlbumContentsTable: FunctionComponent<IAlbumContentsTableProps> = ({
  isPublic,
  album,
  onModify,
  onMoveDown,
  onMoveUp,
  onDelete
}) => {
  return (
    <List className={styles.list}>
      {album.isBoxSet && album.boxSetAlbums.length > 0 && (
        <>
          <AlbumContentItem
            isPublic={isPublic}
            albumContent={{
              uuid: "",
              name:
                album.boxSetAlbums.length > 1
                  ? capitalizeFirstLetter(strings.albums_plural)
                  : strings.album_title,
              quantity: album.boxSetAlbums.length,
              order: -1,
              type: "other"
            }}
            canMoveDown={false}
            canMoveUp={false}
            canModify={false}
          />
          {album.albumContents && album.albumContents.length > 0 && <Divider />}
        </>
      )}
      {album.albumContents?.map((albumContent, index) => (
        <div key={index}>
          <AlbumContentItem
            isPublic={isPublic}
            albumContent={albumContent}
            onModify={() => onModify(albumContent)}
            canModify
            canMoveDown={index < album.albumContents?.length - 1}
            canMoveUp={index > 0}
            onMoveDown={() => onMoveDown(index)}
            onMoveUp={() => onMoveUp(index)}
            onDelete={() => onDelete(albumContent)}
          />
          {index < album.albumContents?.length - 1 && <Divider />}
        </div>
      ))}
    </List>
  );
};

export interface IAlbumContentsTableProps {
  isPublic: boolean;
  album: IAlbum;
  onModify: (albumContent: IAlbumContent) => void;
  onMoveDown: (index: number) => void;
  onMoveUp: (index: number) => void;
  onDelete: (albumContent: IAlbumContent) => void;
}
