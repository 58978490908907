import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { IAlbum, ITrack, SellStatus } from "../../../hooks/albums";
import {
  Box,
  Card,
  Fade,
  IconButton,
  LinearProgress,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  useTheme
} from "@mui/material";
import { useGetImage, useUploadImage } from "../../../hooks/images";
import genericSleeveImage from "../../../images/generic_sleeve.jpg";
import { strings } from "../../../localization/LocalizedStrings";
import { AlbumHeaderListSection } from "../AlbumHeaderListSection";
import { AlbumLabelListSection } from "../AlbumLabelListSection";
import { AlbumYearListSection } from "../AlbumYearListSection";
import { AlbumGradesListSection } from "../AlbumGradesListSection";
import { AlbumFinancialsListSection } from "../AlbumFinancialsListSection";
import { AlbumFormatListSection } from "../AlbumFormatListSection";
import { AlbumImpactListSection } from "../AlbumImpactListSection";
import { ImageDropzone } from "../../ImageDropzone";
import { TrackListHeaderListItem } from "../../attributes/TrackListHeaderListItem";
import { AlbumTrackList } from "../AlbumTrackList";
import { useFetchTracks } from "../../../hooks/albums/useFetchTracks";
import { StatusCodes } from "http-status-codes";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { ImageCache } from "../../../models/ImageCache";
import { AlbumNotesListSection } from "../AlbumNotesListSection";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { AlertDialog } from "../../AlertDialog";
import { AlbumCollectionsListSection } from "../AlbumCollectionsListSection";
import { toast } from "react-toastify";
import { ShortcutsGrid } from "../../ShortcutsGrid";
import { useIsMobile } from "../../../hooks/isMobile";
import { useUser, useUserProfile } from "../../../hooks/session";
import { ImageDetailDialog } from "../../ImageDetailDialog";
import { useContextMenu } from "../../../hooks/contextMenu";
import {
  DriveFileRenameOutlineRounded,
  FilterRounded,
  ImageSearchRounded,
  PushPinOutlined
} from "@mui/icons-material";
import { ImageGalleryDialog } from "../imageGallery/ImageGalleryDialog";
import useLongPress from "../../../hooks/longPress";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { AlbumMessagesListSection } from "../AlbumMessagesListSection";
import { useCurrentPublicCollection } from "../../../hooks/collections/useCollectionManager";
import { AlbumLocationListSection } from "../AlbumLocationListSection";
import { BoxSetAlbumList } from "../BoxSetAlbumList";

export const AlbumDetail: FunctionComponent<IAlbumDetailProps> = ({
  isPublic,
  isLoadingSharedAlbum,
  album,
  onAttributeUpdated,
  onArtistUpdated,
  onPlayFromYouTube,
  onNewAlbum,
  onScanBarcode,
  onImportFromDiscogs,
  onImportFromExcel,
  onFilterAlbums,
  onRandomAlbums,
  onChangeCollection,
  onEditProfile,
  onCopy,
  onDelete,
  onMarkAs,
  onDeleteFromBoxSet,
  onAlbumSelected,
  onBoxSetSelected,
  setIsLoading
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  const { getImage } = useGetImage();
  const { updateAlbum } = useUpdateAlbum();
  const { uploadImage } = useUploadImage();
  const { fetchTracks } = useFetchTracks();
  const [isLoadingTrackList, setIsLoadingTrackList] = useState(false);
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const [albumImage, setAlbumImage] = useState();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [shouldOpenFileDialog, setShouldOpenFileDialog] = useState(false);
  const [isImageGalleryDialogOpen, setIsImageGalleryDialogOpen] = useState(false);
  const [isImageDetailDialogOpen, setIsImageDetailDialogOpen] = useState(false);
  const [detailImage, setDetailImage] = useState<string>();
  const [detailImageTitle, setDetailImageTitle] = useState("");
  const [detailImageFilename, setDetailImageFilename] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const currentAlbumUuid = useRef("");
  const fetchAlbumImage = async () => {
    currentAlbumUuid.current = album?.uuid;
    setAlbumImage(undefined);
    if (album === undefined || !album.hasImage) {
      setIsLoadingImage(false);
      return;
    }
    setIsLoadingImage(true);
    const response = await getImage({
      name: album.uuid,
      width: ImageCache.bigAlbumImageSize,
      height: ImageCache.bigAlbumImageSize
    });
    // Discard image if album changed
    if (currentAlbumUuid.current != album?.uuid) return;

    if (StatusCodesHelper.isSuccessful(response.status)) {
      setAlbumImage(response.body);
    } else {
      setAlbumImage(undefined);
    }
    setIsLoadingImage(false);
  };
  const fetchTrackListFromDiscogs = async () => {
    if (album === undefined) return;
    setIsLoadingTrackList(true);
    const { status, body } = await fetchTracks(album.uuid);
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      album = body;
      onAttributeUpdated(album);
    } else if (status == StatusCodes.UNPROCESSABLE_ENTITY) {
      album.trackList = [];
      onAttributeUpdated(album);
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoadingTrackList(false);
  };
  const updateTrackList = async (newTrackList: ITrack[]) => {
    if (album === undefined) return;
    setIsLoadingTrackList(true);
    const { status, body } = await updateAlbum({ uuid: album.uuid, albumTracks: newTrackList });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      album.trackList = newTrackList;
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoadingTrackList(false);
  };
  const onOpenImageDetail = (image: string, title: string, filename: string) => {
    setDetailImage(image);
    setDetailImageTitle(title);
    setDetailImageFilename(filename);
    setIsImageDetailDialogOpen(true);
  };
  useEffect(() => {
    fetchAlbumImage().then();
  }, [album?.uuid]);
  const handleLongPress = useLongPress({ onLongPress: handleContextMenu });
  return (
    <Card className={styles.mainContainer}>
      {isLoadingSharedAlbum ? (
        <Fade in unmountOnExit style={{ transitionDelay: "1000ms" }}>
          <Stack className={styles.loadingStack} spacing={3}>
            <p>{strings.album_shared_loading}</p>
            <LinearProgress className={styles.progressBar} variant={"indeterminate"} />
          </Stack>
        </Fade>
      ) : !album ? (
        currentUser && (
          <Stack className={styles.noSelectionDiv}>
            {isPublic && currentPublicCollection && (
              <Card className={styles.publicCollectionCard} elevation={4}>
                <p className={styles.publicCollectionName}>{currentPublicCollection.name}</p>
                <p
                  className={styles.publicCollectionDescription}
                  style={{ color: theme.palette.primary.subtitle }}
                >
                  {currentPublicCollection.description}
                </p>
              </Card>
            )}
            <p className={styles.shortcutsTitle}>{strings.shortcuts_title}</p>
            <ShortcutsGrid
              className={styles.shortcutsGrid}
              isPublic={isPublic}
              showAttributesShortcut={true}
              onNewAlbum={onNewAlbum}
              onScanBarcode={onScanBarcode}
              onImportFromDiscogs={onImportFromDiscogs}
              onImportFromExcel={onImportFromExcel}
              onFilterAlbums={onFilterAlbums}
              onRandomAlbums={onRandomAlbums}
              onChangeCollection={onChangeCollection}
              onEditProfile={onEditProfile}
            />
          </Stack>
        )
      ) : (
        <Stack className={styles.mainDiv} direction={isMobile ? "column" : "row"}>
          <Stack display={"flex"}>
            <Card
              className={styles.albumImageCard}
              elevation={5}
              style={{ width: isMobile ? undefined : 360 }}
              onContextMenu={handleContextMenu}
              {...handleLongPress}
            >
              {album.sellStatus && !["new", "highlighted"].includes(album.sellStatus) && (
                <Box
                  className={styles.reservedBox}
                  style={{
                    backgroundColor: AlbumUtilities.getSellStatusBackgroundColor(album.sellStatus)
                  }}
                >
                  <p className={styles.reservedText}>
                    {AlbumUtilities.getSellStatusDescription(album.sellStatus)}
                  </p>
                </Box>
              )}
              <Stack className={styles.hoverButtonsStack} spacing={1}>
                <Tooltip title={strings.image_gallery} arrow disableInteractive>
                  <IconButton
                    className={styles.hoverButton}
                    style={{ backgroundColor: theme.palette.colors?.counterBackground }}
                    onClick={() => setIsImageGalleryDialogOpen(true)}
                  >
                    <ResponsiveIcon icon={FilterRounded} />
                  </IconButton>
                </Tooltip>
                {albumImage && (
                  <Tooltip title={strings.option_view_image} arrow disableInteractive>
                    <IconButton
                      className={styles.hoverButton}
                      style={{ backgroundColor: theme.palette.colors?.counterBackground }}
                      onClick={() => onOpenImageDetail(albumImage, album.name, album.name)}
                    >
                      <ResponsiveIcon icon={ImageSearchRounded} />
                    </IconButton>
                  </Tooltip>
                )}
                {!isPublic && (
                  <Tooltip title={strings.modify_image} arrow disableInteractive>
                    <IconButton
                      className={styles.hoverButton}
                      style={{ backgroundColor: theme.palette.colors?.counterBackground }}
                      onClick={() => setShouldOpenFileDialog(state => !state)}
                    >
                      <ResponsiveIcon icon={DriveFileRenameOutlineRounded} />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
              <ImageDropzone
                disabled={isPublic || isLoadingImage}
                className={styles.albumImage}
                onImageChanged={async file => {
                  setIsLoadingImage(true);
                  const newThumbnail = await uploadImage(
                    { name: album.uuid, file },
                    ImageCache.bigAlbumImageSize,
                    ImageCache.bigAlbumImageSize,
                    ImageCache.smallAlbumImageSize,
                    ImageCache.smallAlbumImageSize
                  );
                  if (newThumbnail) {
                    album.hasImage = true;
                    album.thumbnail = newThumbnail;
                    onAttributeUpdated(album);
                    await fetchAlbumImage();
                  }
                  setIsLoadingImage(false);
                }}
                placeholderImage={genericSleeveImage}
                isLoading={isLoadingImage}
                initialImage={
                  albumImage
                    ? `data:image/png;base64,${albumImage}`
                    : album.thumbnail
                      ? `data:image/png;base64,${album.thumbnail}`
                      : undefined
                }
                shouldOpenFileDialog={shouldOpenFileDialog}
                onClick={() => setIsImageGalleryDialogOpen(true)}
              />
            </Card>
            <TrackListHeaderListItem
              isPublic={isPublic}
              className={styles.tracksHeader}
              album={album}
              onDiscogsIdUpdated={async newValue => {
                if (
                  newValue !== null &&
                  (album?.trackList == undefined || album?.trackList?.length == 0) &&
                  !album.isBoxSet
                ) {
                  await fetchTrackListFromDiscogs();
                }
                onAttributeUpdated(album);
              }}
              onLengthUpdated={() => onAttributeUpdated(album)}
              onTracksUpdated={() => onAttributeUpdated(album)}
              onCopy={onCopy}
              onMarkAs={onMarkAs}
              onDelete={onDelete}
              onPlayFromYouTube={videoIds => onPlayFromYouTube(album.name, videoIds)}
            />
            {!(isPublic && !album.trackList) && !album.isBoxSet && (
              <AlbumTrackList
                isPublic={isPublic}
                className={isMobile ? styles.trackListMobile : styles.trackList}
                trackList={album.trackList}
                artistName={album.artist?.name}
                albumName={album.name}
                fetchFromDiscogsEnabled={
                  album.discogsReleaseId !== undefined && album.discogsReleaseId != ""
                }
                isLoading={isLoadingTrackList}
                onChange={async newTrackList => {
                  await updateTrackList(newTrackList);
                  onAttributeUpdated(album);
                }}
                onPlayFromYouTube={videoIds => onPlayFromYouTube(album.name, videoIds)}
                onFetchTrackListFromDiscogs={fetchTrackListFromDiscogs}
              />
            )}
            {album.isBoxSet && (
              <BoxSetAlbumList
                isPublic={isPublic}
                className={isMobile ? styles.trackListMobile : styles.trackList}
                boxSet={album}
                onDeleteFromBoxSet={onDeleteFromBoxSet}
                onAlbumSelected={onAlbumSelected}
                setIsLoading={setIsLoading}
              />
            )}
          </Stack>
          <List
            disablePadding
            className={isMobile ? styles.mobileList : styles.list}
            style={{ overflow: isMobile ? "unset" : "auto" }}
          >
            <Card
              elevation={5}
              className={isMobile ? styles.firstItemCardMobile : styles.firstItemCard}
            >
              <AlbumHeaderListSection
                key={"header"}
                isPublic={isPublic}
                album={album}
                onNameUpdated={onAttributeUpdated}
                onArtistUpdated={onArtistUpdated}
              />
            </Card>
            <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
              <AlbumLabelListSection
                key={"label"}
                isPublic={isPublic}
                album={album}
                onAttributeUpdated={onAttributeUpdated}
              />
            </Card>
            <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
              <AlbumYearListSection
                key={"year"}
                isPublic={isPublic}
                album={album}
                onAttributeUpdated={onAttributeUpdated}
              />
            </Card>
            <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
              <AlbumFormatListSection
                key={"format"}
                isPublic={isPublic}
                album={album}
                onAttributeUpdated={onAttributeUpdated}
              />
            </Card>
            <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
              <AlbumGradesListSection
                key={"grades"}
                isPublic={isPublic}
                isMobile={isMobile}
                album={album}
                onAttributeUpdated={onAttributeUpdated}
              />
            </Card>
            <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
              <AlbumFinancialsListSection
                key={"financials"}
                isPublic={isPublic}
                album={album}
                onAttributeUpdated={onAttributeUpdated}
              />
            </Card>
            {!isPublic && (
              <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
                <AlbumLocationListSection
                  key={"location"}
                  album={album}
                  onBoxSetSelected={onBoxSetSelected}
                />
              </Card>
            )}
            {!isPublic && (
              <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
                <AlbumCollectionsListSection key={"collections"} album={album} />
              </Card>
            )}
            {!(isPublic && album.impact === undefined) && (
              <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
                <AlbumImpactListSection
                  key={"impact"}
                  isPublic={isPublic}
                  album={album}
                  onAttributeUpdated={onAttributeUpdated}
                />
              </Card>
            )}
            {!(isPublic && album.notes === undefined) && (
              <Card elevation={5} className={isMobile ? styles.itemCardMobile : styles.itemCard}>
                <AlbumNotesListSection
                  key={"notes"}
                  isPublic={isPublic}
                  album={album}
                  onAttributeUpdated={onAttributeUpdated}
                />
              </Card>
            )}
            <Card
              elevation={5}
              className={isMobile ? styles.lastItemCardMobile : styles.lastItemCard}
            >
              <AlbumMessagesListSection key={"messages"} isPublic={isPublic} album={album} />
            </Card>
            {!isPublic && currentUserProfile?.shouldShowAlbumId && (
              <p className={styles.albumId} style={{ color: theme.palette.secondary.main }}>
                ID: {album.uuid}
              </p>
            )}
          </List>
        </Stack>
      )}
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsImageGalleryDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <FilterRounded />
          </ListItemIcon>
          {strings.image_gallery}
        </MenuItem>
        {album && albumImage && (
          <div>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                onOpenImageDetail(albumImage, album.name, album.name);
              }}
              dense
            >
              <ListItemIcon>
                <ImageSearchRounded />
              </ListItemIcon>
              {strings.option_view_image}
            </MenuItem>
          </div>
        )}
        {!isPublic && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setShouldOpenFileDialog(state => !state);
            }}
            dense
          >
            <ListItemIcon>
              <DriveFileRenameOutlineRounded />
            </ListItemIcon>
            {strings.modify_image}
          </MenuItem>
        )}
        {!isPublic && album?.sellStatus && (
          <MenuItem
            key={"RemoveMark"}
            onClick={async () => {
              handleMenuClose();
              onMarkAs(null);
            }}
            dense
          >
            <ListItemIcon>
              <PushPinOutlined fontSize={"small"} />
            </ListItemIcon>
            {strings.remove_mark_title}
          </MenuItem>
        )}
      </Menu>
      {album && (
        <ImageGalleryDialog
          isPublic={isPublic}
          album={album}
          isOpen={isImageGalleryDialogOpen}
          setIsOpen={setIsImageGalleryDialogOpen}
          onAlbumImageUpdated={async albumImage => {
            album.hasImage = true;
            album.thumbnail = albumImage.thumbnail;
            onAttributeUpdated(album);
            await fetchAlbumImage();
          }}
          onOpenImageDetail={onOpenImageDetail}
        />
      )}
      <ImageDetailDialog
        title={detailImageTitle}
        isOpen={isImageDetailDialogOpen}
        setIsOpen={setIsImageDetailDialogOpen}
        filename={detailImageFilename}
        image={detailImage}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </Card>
  );
};

export interface IAlbumDetailProps {
  isPublic: boolean;
  isMobile: boolean;
  isLoadingSharedAlbum: boolean;
  album?: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
  onArtistUpdated: () => void;
  onPlayFromYouTube: (title: string, videoIds: string[]) => void;
  onNewAlbum: () => void;
  onScanBarcode: () => void;
  onImportFromDiscogs: () => void;
  onImportFromExcel: () => void;
  onFilterAlbums: () => void;
  onRandomAlbums: () => void;
  onChangeCollection: () => void;
  onEditProfile: () => void;
  onCopy: () => void;
  onMarkAs: (sellStatus: SellStatus | null) => void;
  onDelete: () => void;
  onDeleteFromBoxSet?: (newBoxSet: IAlbum) => void;
  onAlbumSelected: (album: IAlbum) => void;
  onBoxSetSelected: () => void;
  setIsLoading: (isLoading: boolean) => void;
}
