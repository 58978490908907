import { useBackend } from "../../services";

export const useRemoveAlbumFromBoxSet = () => {
  const { deleteRequest } = useBackend();
  const removeAlbumFromBoxSet = (parameters: IRemoveAlbumFromBoxSetParameters) =>
    deleteRequest("boxSets", parameters);
  return { removeAlbumFromBoxSet };
};

export interface IRemoveAlbumFromBoxSetParameters {
  boxSetAlbumId: string;
  albumId: string;
}
