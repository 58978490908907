import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IAlbum, SellStatus } from "../../../hooks/albums";
import { Box, ListItemButton, Stack, useTheme } from "@mui/material";
import { useGetImage } from "../../../hooks/images";
import genericSleeveImage from "../../../images/generic_sleeve.jpg";
import { GoldmineRatingCard } from "../../attributes/GoldmineRatingCard";
import { strings } from "../../../localization/LocalizedStrings";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { ImageCache } from "../../../models/ImageCache";
import { FilterSortOption } from "../../../models/FilterElement";
import { LazyImageAspectFit } from "../../LazyImageAspectFit";
import { useCollectionManager } from "../../../hooks/collections/useCollectionManager";
import useLongPress from "../../../hooks/longPress";
import { useUserProfile } from "../../../hooks/session";
import { useCurrencyFormatter } from "../../../hooks/currencyFormatter";
import { AlbumOptionsButton } from "../AlbumOptionsButton";
import classNames from "classnames";

export const AlbumListItem: FunctionComponent<IAlbumListItemProps> = ({
  isPublic,
  sortOption,
  album,
  highlighted,
  onClick,
  onDelete,
  onDeleteFromBoxSet,
  onCopy,
  onMarkAs,
  onPlayFromYouTube,
  setIsLoading,
  sectionLength,
  isForLayout,
  isBoxSetList,
  boxSetAlbumId
}) => {
  const theme = useTheme();
  const [userProfile] = useUserProfile.useState();
  const collectionManager = useCollectionManager();
  const currencyFormatter = useCurrencyFormatter();
  const { getImage } = useGetImage();
  const [albumImage, setAlbumImage] = useState();
  const [imageUpdateCount, setImageUpdateCount] = useState(0);
  const [subtitle, setSubtitle] = useState("");
  const [userEvent, setUserEvent] = useState<React.MouseEvent | React.TouchEvent>();
  const sellValueDetail = () => {
    if (!album.sellValue?.pesos) return;
    const currentCollection = collectionManager.getCurrentCollection(isPublic);
    if (currentCollection && currentCollection.discount > 0) {
      return (
        <Stack>
          <p className={styles.oldPrice} style={{ color: theme.palette.secondary.main }}>
            {currencyFormatter.format(album.sellValue?.pesos, userProfile, isPublic, 2)}
          </p>
          <p className={styles.newPrice} style={{ color: theme.palette.primary.main }}>
            {currencyFormatter.format(
              (album.sellValue?.pesos ?? 0) * (1 - currentCollection.discount),
              userProfile,
              isPublic,
              2
            )}
          </p>
        </Stack>
      );
    } else {
      return (
        <p className={styles.newPrice} style={{ color: theme.palette.primary.main }}>
          {currencyFormatter.format(album.sellValue?.pesos, userProfile, isPublic, 2)}
        </p>
      );
    }
  };
  const fetchAlbumImage = async () => {
    if (!album.hasImage) return;
    const response = await getImage({
      name: album.uuid,
      width: ImageCache.smallAlbumImageSize,
      height: ImageCache.smallAlbumImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setAlbumImage(response.body);
    }
  };
  useEffect(() => {
    const subtitle = AlbumUtilities.getSubtitle(album, userProfile);
    setSubtitle(subtitle);
  }, [
    album.name,
    album.albumContents,
    album.albumContents?.length,
    album.originalYear,
    album.releaseYear,
    album.albumType,
    album.order,
    album.sleeveGrade,
    album.mediaGrade,
    album.country
  ]);
  useEffect(() => {
    if (imageUpdateCount > 0) {
      setAlbumImage(undefined);
      fetchAlbumImage().then();
    } else {
      setImageUpdateCount(state => state + 1);
    }
  }, [album.thumbnail, album.hasImage]);
  const handleLongPress = useLongPress({ onLongPress: setUserEvent });
  return (
    <div onContextMenu={setUserEvent} className={styles.container} {...handleLongPress}>
      <ListItemButton
        onClick={onClick}
        sx={{
          height: "100%",
          backgroundColor: highlighted
            ? theme.palette.colors?.highlight
            : isBoxSetList
              ? undefined
              : theme.palette.background.paper
        }}
      >
        <LazyImageAspectFit
          className={styles.albumImage}
          image={albumImage}
          thumbnail={album.thumbnail}
          defaultImage={genericSleeveImage}
          beforeLoad={fetchAlbumImage}
          alt={album.name}
        />
        <Stack className={styles.rightContainer} sx={{ width: "100%" }}>
          <Stack direction={"row"}>
            <p className={styles.name} style={{ color: theme.palette.primary.main }}>
              {album.name}
            </p>
            <Box flexGrow={1} />
            {isPublic && sellValueDetail()}
          </Stack>
          <p className={styles.subtitle} style={{ color: theme.palette.primary.subtitle }}>
            {subtitle}
          </p>
          {!isPublic &&
            sortOption == FilterSortOption.ARTIST &&
            sectionLength > 1 &&
            album.order == -1 && (
              <p className={styles.orderWarning} style={{ color: theme.palette.colors?.warning }}>
                {strings.order_warning}
              </p>
            )}
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction={"row"}
            spacing={"0.3rem"}
            sx={{ display: "flex", alignItems: "center", marginLeft: 1, marginTop: 1 }}
          >
            <p className={styles.gradeText} style={{ color: theme.palette.primary.main }}>
              {album.isBoxSet ? strings.box_set_sleeve_grade : strings.album_sleeve_grade}
            </p>
            <GoldmineRatingCard
              points={album.sleeveGrade?.points ?? 0}
              goldmineGrading={album.sleeveGrade?.goldmineGrade ?? "-"}
            />
            {!album.isBoxSet && (
              <>
                <p className={styles.gradeText} style={{ color: theme.palette.primary.main }}>
                  {strings.album_media_grade}
                </p>
                <GoldmineRatingCard
                  points={album.mediaGrade?.points ?? 0}
                  goldmineGrading={album.mediaGrade?.goldmineGrade ?? "-"}
                />
              </>
            )}
          </Stack>
        </Stack>
      </ListItemButton>
      <Stack
        className={styles.hoverButtonsStack}
        style={{ visibility: isForLayout ? "hidden" : undefined }}
        direction={"row"}
        spacing={1}
      >
        <AlbumOptionsButton
          isPublic={isPublic}
          className={classNames(styles.hoverButton, styles.hoverButtonRadius)}
          buttonClassName={styles.hoverButtonRadius}
          style={{ backgroundColor: theme.palette.colors?.counterBackground }}
          album={album}
          userEvent={userEvent}
          onCopy={onCopy}
          onPlayFromYouTube={onPlayFromYouTube}
          onMarkAs={onMarkAs}
          onDelete={onDelete}
          onDeleteFromBoxSet={onDeleteFromBoxSet}
          setIsLoading={setIsLoading}
          isForLayout={isForLayout}
          isBoxSetAlbum={isBoxSetList}
          boxSetAlbumId={boxSetAlbumId}
        />
      </Stack>
      {album.sellStatus && (
        <>
          {["new", "highlighted"].includes(album.sellStatus) && (
            <Box
              className={styles.highlightTag}
              style={{
                backgroundColor: AlbumUtilities.getSellStatusBackgroundColor(album.sellStatus),
                color: theme.palette.primary.main
              }}
            >
              <p className={styles.highlightText}>
                {AlbumUtilities.getSellStatusDescription(album.sellStatus)}
              </p>
            </Box>
          )}
          {["upcoming"].includes(album.sellStatus) && (
            <>
              <div
                className={styles.upcomingPerimeter}
                style={{
                  borderColor: AlbumUtilities.getSellStatusBackgroundColor(album.sellStatus)
                }}
              />
              <Box className={styles.upcomingBox} style={{ color: theme.palette.primary.main }}>
                <div
                  className={styles.backgroundCircle}
                  style={{
                    backgroundColor: AlbumUtilities.getSellStatusBackgroundColor(album.sellStatus)
                  }}
                >
                  <p className={styles.upcomingText}>
                    {AlbumUtilities.getSellStatusDescription(album.sellStatus)}
                  </p>
                </div>
              </Box>
            </>
          )}
          {["reserved", "sold"].includes(album.sellStatus) && (
            <Box
              className={styles.reservedBox}
              style={{
                backgroundColor: AlbumUtilities.getSellStatusBackgroundColor(album.sellStatus),
                color: theme.palette.primary.main
              }}
            >
              <p className={styles.reservedText}>
                {AlbumUtilities.getSellStatusDescription(album.sellStatus)}
              </p>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export interface IAlbumListItemProps {
  isPublic: boolean;
  sortOption: FilterSortOption;
  album: IAlbum;
  highlighted: boolean;
  onClick: () => void;
  onDelete: () => void;
  onDeleteFromBoxSet?: (newBoxSet: IAlbum) => void;
  onCopy: () => void;
  onMarkAs: (sellStatus: SellStatus | null) => void;
  onPlayFromYouTube: (videoIds: string[]) => void;
  setIsLoading: (loading: boolean) => void;
  sectionLength: number;
  isForLayout: boolean;
  isBoxSetList?: boolean;
  boxSetAlbumId?: string;
}
