import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Stack,
  Toolbar,
  useTheme
} from "@mui/material";
import { ILabel } from "../../../../hooks/labels";
import { strings } from "../../../../localization/LocalizedStrings";
import { TextField } from "../../../TextField";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useAddLabel } from "../../../../hooks/labels/useAddLabel";
import { useModifyLabel } from "../../../../hooks/labels/useModifyLabel";
import { CloseRounded, Delete } from "@mui/icons-material";
import { useDeleteLabel } from "../../../../hooks/labels/useDeleteLabel";
import { AlertDialog } from "../../../AlertDialog";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { FieldTitle } from "../../../Text/FieldTitle";
import { useUploadImage } from "../../../../hooks/images";
import { ImageCache } from "../../../../models/ImageCache";
import { ImageDropzone } from "../../../ImageDropzone";
import genericImage from "../../../../images/generic_label.png";
import { ContainedButton } from "../../../ContainedButton";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../../hooks/isMobile";
import { ModifyFreeTextAttributeDialog } from "../../ModifyFreeTextAttributeDialog";
import { FreeTextListItem } from "../../../FreeTextListItem";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../../../services/Firebase";

export const ModifyLabelDialog: FunctionComponent<IModifyLabelDialogProps> = ({
  open,
  setOpen,
  label,
  originalImageData,
  onNewItem,
  onModifiedItem,
  onDeletedItem
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const analytics = Firebase.shared.getAnalytics();
  const [dialogTitle, setDialogTitle] = useState(strings.ml_new_title);
  const { addLabel } = useAddLabel();
  const { modifyLabel } = useModifyLabel();
  const { deleteLabel } = useDeleteLabel();
  const { uploadImage } = useUploadImage();
  const [imageFile, setImageFile] = useState<File>();
  const [isModifyProfileDialogOpen, setIsModifyProfileDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [didChangeImage, setDidChangeImage] = useState(false);
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const tryToClose = () => {
    if (isLoading) return;
    if (formik.dirty) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(strings.ml_missing_error)
  });
  const formik = useFormik({
    initialValues: {
      name: label?.name ?? "",
      profile: label?.profile,
      discogsId: label?.discogsId,
      website: label?.website
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ name, profile, discogsId, website }) => {
      setIsLoading(true);
      if (label) {
        const { status, body } = await modifyLabel({
          uuid: label.uuid,
          name: name,
          hasImage: label.hasImage,
          profile: profile,
          discogsId: discogsId,
          website: website
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_label_action", { isNew: false });
          if (didChangeImage && imageFile && label.uuid) {
            const newThumbnail = await uploadImage(
              { name: label.uuid, file: imageFile },
              ImageCache.bigLabelImageSize,
              ImageCache.bigLabelImageSize,
              ImageCache.smallLabelImageSize,
              ImageCache.smallLabelImageSize
            );
            if (newThumbnail) {
              body.hasImage = true;
              body.thumbnail = newThumbnail;
            }
          }
          if (onModifiedItem != undefined) {
            onModifiedItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      } else {
        const { status, body } = await addLabel({
          name: name,
          profile: profile,
          discogsId: discogsId,
          website: website
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_label_action", { isNew: true });
          if (didChangeImage && imageFile && body.uuid) {
            const newThumbnail = await uploadImage(
              { name: body.uuid, file: imageFile },
              ImageCache.bigLabelImageSize,
              ImageCache.bigLabelImageSize,
              ImageCache.smallLabelImageSize,
              ImageCache.smallLabelImageSize
            );
            if (newThumbnail) {
              body.hasImage = true;
              body.thumbnail = newThumbnail;
            }
          }
          if (onNewItem != undefined) {
            onNewItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      }
      setIsLoading(false);
    },
    validationSchema: validationSchema
  });
  const onDelete = async () => {
    if (!label?.uuid) return;
    setIsLoading(true);
    const { status, body } = await deleteLabel({ uuid: label.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (onDeletedItem != undefined) {
        onDeletedItem(label);
      }
      handleClose();
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!open) return;
    setDialogTitle(label ? strings.label_title : strings.ml_new_title);
    formik.resetForm();
    setImageFile(undefined);
    setDidChangeImage(false);
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClose={tryToClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{dialogTitle}</h3>
          <Box sx={{ flexGrow: 1 }} />
          {label && (
            <ToolbarButton
              onClick={onDelete}
              tooltip={strings.delete}
              icon={Delete}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton
            loading={isLoading}
            onClick={tryToClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Stack direction={"row"} spacing={2}>
            <ImageDropzone
              className={styles.dropzone}
              onImageChanged={file => {
                setImageFile(file);
                setDidChangeImage(true);
              }}
              placeholderImage={genericImage}
              initialImage={
                originalImageData
                  ? `data:image/png;base64,${originalImageData}`
                  : label?.thumbnail
                    ? `data:image/png;base64,${label.thumbnail}`
                    : undefined
              }
              isLoading={false}
            />
            <Stack sx={{ width: "100%" }}>
              <FieldTitle>{strings.ml_field_title}</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="name"
                  name="name"
                  size="small"
                  autoFocus={!isMobile}
                  placeholder={strings.ml_placeholder}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
              <FieldTitle>{strings.ma_website_title}</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="website"
                  name="website"
                  size="small"
                  placeholder={strings.ma_website_placeholder}
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
            </Stack>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <div style={{ width: "100%" }}>
              <FieldTitle>{strings.ma_discogs_id_title}</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="discogsId"
                  name="discogsId"
                  size="small"
                  placeholder={strings.ma_discogs_id_placeholder}
                  value={formik.values.discogsId}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
            </div>
          </Stack>
          <FieldTitle>{strings.ma_profile_title}</FieldTitle>
          <Card className={styles.profileCard}>
            <FreeTextListItem
              hideTitle
              detail={formik.values.profile ?? "--"}
              maxLines={4}
              onClick={() => setIsModifyProfileDialogOpen(true)}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={handleClose}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
      <ModifyFreeTextAttributeDialog
        title={strings.ma_profile_placeholder}
        description={strings.edit_profile_description}
        placeholder={strings.ma_profile_placeholder}
        value={formik.values.profile}
        open={isModifyProfileDialogOpen}
        setOpen={setIsModifyProfileDialogOpen}
        onChange={newValue => formik.setFieldValue("profile", newValue)}
        onClearValue={() => formik.setFieldValue("profile", null)}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifyLabelDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  label?: ILabel;
  originalImageData?: string;
  onNewItem?: (item: ILabel) => void;
  onModifiedItem?: (item: ILabel) => void;
  onDeletedItem?: (item: ILabel) => void;
}
