export const RoutesBuilder = {
  home: () => "/",
  signIn: () => "/signIn",
  albums: {
    list: () => "/albums"
  },
  attributes: {
    manage: () => "/attributes"
  },
  userProfile: () => "/profile",
  stats: () => "/stats",
  chats: () => "/chats",
  marketplace: () => "/marketplace",
  layout: () => "/layout",
  admin: () => "/admin",
  share: () => "/share",
  private: () => "/private",
  payment: () => "/payment",
  paymentPatreon: () => "/paymentPatreon",
  notFound: () => "/notFound",
  maintenance: () => "/maintenance",
  documentation: {
    home: () => "/doc",
    about: (at?: string) => `/about${at ? `#${at}` : ""}`,
    users: {
      home: () => "/users",
      signUp: (at?: string) => `/users/signUp${at ? `#${at}` : ""}`,
      guest: () => "/users/guest",
      recoverPassword: () => "/users/recoverPassword"
    },
    album: {
      home: (at?: string) => `/album${at ? `#${at}` : ""}`,
      filterAlbums: () => "/album/filter",
      orderAlbums: () => "/album/order",
      markingAlbums: () => "/album/marks",
      scanBarcode: () => "/album/scanBarcode",
      importFromDiscogs: () => "/album/importFromDiscogs",
      importFromExcel: () => "/album/importFromSpreadsheet",
      imageGallery: () => "/album/imageGallery",
      removeFilteredAlbums: () => "/album/removeFiltered"
    },
    albumAttributes: {
      home: () => "/albumAttributes",
      artists: () => "/albumAttributes/artists"
    },
    collections: {
      home: (at?: string) => `/collections${at ? `#${at}` : ""}`,
      attributes: (at?: string) => `/collections/attributes${at ? `#${at}` : ""}`,
      publicCollections: () => "/collections/public"
    },
    userProfile: (at?: string) => `/userProfile${at ? `#${at}` : ""}`,
    subscriptions: {
      home: () => `/subscriptions`,
      mercadoPago: () => "/subscriptions/mercadoPago"
    },
    policy: () => "/policy",
    terms: () => "/terms"
  }
};
