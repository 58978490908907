import { IToken, IUser, UserRole } from "../users";
import { IUserProfile } from "../userProfiles";
import { createState } from "state-pool";
import { useCurrentPrivateCollection } from "../collections/useCollectionManager";
import { useState } from "react";
import {
  useCurrentPrivateFilterElements,
  useCurrentPrivateFilterSortOption,
  useCurrentPrivateFilterSortOrder,
  useCurrentLayoutFilterElements,
  useCurrentLayoutFilterSortOption,
  useCurrentLayoutFilterSortOrder,
  useCurrentPrivateIsNegated,
  useCurrentLayoutIsNegated
} from "../albums/useAlbumFilterManager";
import { FilterSortOption, FilterSortOrder } from "../../models/FilterElement";
import { filterIsNegatedInit } from "../albums";

export const useUserProfile = createState<IUserProfile | undefined>(undefined);
export const usePublicUserProfile = createState<IUserProfile | undefined>(undefined);
export const useUser = createState<IUser | undefined>(undefined);

export const useSessionManager = () => {
  const [didUpdateToken, setDidUpdateToken] = useState(false);
  const [user, setUser] = useUser.useState();
  const [, setCurrentPrivateCollection] = useCurrentPrivateCollection.useState();
  const [, setCurrentPrivateFilterElements] = useCurrentPrivateFilterElements.useState();
  const [, setCurrentPrivateIsNegated] = useCurrentPrivateIsNegated.useState();
  const [, setCurrentPrivateFilterSortOption] = useCurrentPrivateFilterSortOption.useState();
  const [, setCurrentPrivateFilterSortOrder] = useCurrentPrivateFilterSortOrder.useState();
  const [, setCurrentLayoutFilterElements] = useCurrentLayoutFilterElements.useState();
  const [, setCurrentLayoutIsNegated] = useCurrentLayoutIsNegated.useState();
  const [, setCurrentLayoutFilterSortOption] = useCurrentLayoutFilterSortOption.useState();
  const [, setCurrentLayoutFilterSortOrder] = useCurrentLayoutFilterSortOrder.useState();
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    if (tokenString != null) {
      const token = JSON.parse(tokenString);
      return token?.token;
    }
    return null;
  };
  const setToken = (token: IToken) => {
    localStorage.setItem("token", JSON.stringify(token));
    setDidUpdateToken(state => !state);
  };
  const isCurrentUserPremium = () => {
    if (!user?.role) return false;
    return [UserRole.premium, UserRole.admin, UserRole.guest].includes(user.role);
  };
  const isCurrentUserAdmin = () => {
    if (!user?.role) return false;
    return user.role == UserRole.admin;
  };
  const closeSession = () => {
    setCurrentPrivateCollection(undefined);
    setCurrentPrivateFilterElements([]);
    setCurrentPrivateIsNegated(filterIsNegatedInit());
    setCurrentPrivateFilterSortOption(FilterSortOption.ARTIST);
    setCurrentPrivateFilterSortOrder(FilterSortOrder.ASCENDANT);
    setCurrentLayoutFilterElements([]);
    setCurrentLayoutIsNegated(filterIsNegatedInit());
    setCurrentLayoutFilterSortOption(FilterSortOption.ARTIST);
    setCurrentLayoutFilterSortOrder(FilterSortOrder.ASCENDANT);
    setUser(undefined);
    useUserProfile.setValue(undefined);
    localStorage.removeItem("token");
    setDidUpdateToken(state => !state);
  };
  return {
    getToken,
    setToken,
    didUpdateToken,
    isCurrentUserPremium,
    isCurrentUserAdmin,
    closeSession
  };
};
