import React, { FunctionComponent, useState } from "react";
import styles from "./styles.module.scss";
import { Card, CardActionArea, ListItem, Stack } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { IAlbum, SellStatus } from "../../../hooks/albums";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { ModifyIncrementalAttributeDialog } from "../ModifyIncrementalAttributeDialog";
import { AlertDialog } from "../../AlertDialog";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";
import { ModifyGenericAttributeDialog } from "../ModifyGenericAttributeDialog";
import { ModifyLengthAttributeDialog } from "../ModifyLengthAttributeDialog";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { TimeInterval } from "../../../models/TimeInterval";
import { toast } from "react-toastify";
import { AlbumOptionsButton } from "../../albums/AlbumOptionsButton";

export const TrackListHeaderListItem: FunctionComponent<ITrackListHeaderListItemProps> = ({
  isPublic,
  className,
  album,
  onDiscogsIdUpdated,
  onLengthUpdated,
  onTracksUpdated,
  onCopy,
  onDelete,
  onMarkAs,
  onPlayFromYouTube
}) => {
  const { updateAlbum } = useUpdateAlbum();
  const [isTracksDialogOpen, setIsTracksDialogOpen] = useState(false);
  const [isLoadingTracks, setIsLoadingTracks] = useState(false);
  const [isLengthDialogOpen, setIsLengthDialogOpen] = useState(false);
  const [isLoadingLength, setIsLoadingLength] = useState(false);
  const [isDiscogsIdDialogOpen, setIsDiscogsIdDialogOpen] = useState(false);
  const [isLoadingDiscogsId, setIsLoadingDiscogsId] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const updateTracks = async (newValue: number | null) => {
    setIsLoadingTracks(true);
    const { status, body } = await updateAlbum({ uuid: album.uuid, tracks: newValue });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      album.tracks = newValue ?? undefined;
      onTracksUpdated();
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingTracks(false);
  };

  const updateLength = async (newValue: number) => {
    setIsLoadingLength(true);
    const { status, body } = await updateAlbum({
      uuid: album.uuid,
      length: newValue
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      album.length = newValue;
      onLengthUpdated();
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingLength(false);
  };

  const updateDiscogsId = async (newValue: string | null) => {
    setIsLoadingDiscogsId(true);
    const { status, body } = await updateAlbum({
      uuid: album.uuid,
      discogsReleaseId: newValue
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      album.discogsReleaseId = newValue ?? undefined;
      onDiscogsIdUpdated(newValue);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingDiscogsId(false);
  };

  return (
    <ListItem className={className} disablePadding disableGutters sx={{ padding: "0 !important" }}>
      <Card elevation={5} className={styles.itemDiv}>
        <Stack direction={"row"} flexGrow={1} justifyContent={"space-around"} minHeight={44}>
          {!album.isBoxSet && (
            <CardActionArea
              disabled={isPublic}
              onClick={() => {
                setIsTracksDialogOpen(true);
              }}
            >
              <Stack margin={"2px"} spacing={"3px"} alignItems={"center"}>
                <p className={styles.title}>{strings.track_count_title_big}</p>
                {isLoadingTracks ? (
                  <CustomLoadingIndicator className={styles.loadingIndicator} />
                ) : (
                  <p className={styles.value}>{album.tracks ?? "-"}</p>
                )}
              </Stack>
            </CardActionArea>
          )}
          {!album.isBoxSet && (
            <CardActionArea
              disabled={isPublic}
              onClick={() => {
                setIsLengthDialogOpen(true);
              }}
            >
              <Stack margin={"2px"} spacing={"3px"} alignItems={"center"}>
                <p className={styles.title}>{strings.album_length_title_big}</p>
                {isLoadingLength ? (
                  <CustomLoadingIndicator className={styles.loadingIndicator} />
                ) : (
                  <p className={styles.value}>
                    {album.length ? TimeInterval.toHHMMSS(album.length) : "-"}
                  </p>
                )}
              </Stack>
            </CardActionArea>
          )}
          {!isPublic && (
            <CardActionArea
              onClick={() => {
                setIsDiscogsIdDialogOpen(true);
              }}
            >
              <Stack margin={"2px"} spacing={"3px"} alignItems={"center"}>
                <p className={styles.title}>{strings.discogs_id_title_big}</p>
                {isLoadingDiscogsId ? (
                  <CustomLoadingIndicator className={styles.loadingIndicator} />
                ) : (
                  <p className={styles.value}>
                    {album.discogsReleaseId && album.discogsReleaseId != ""
                      ? album.discogsReleaseId
                      : "-"}
                  </p>
                )}
              </Stack>
            </CardActionArea>
          )}
          <AlbumOptionsButton
            isPublic={isPublic}
            album={album}
            userEvent={undefined}
            onCopy={onCopy}
            onPlayFromYouTube={onPlayFromYouTube}
            onMarkAs={onMarkAs}
            onDelete={onDelete}
            style={{ width: isPublic && album.isBoxSet ? "100%" : undefined }}
          />
        </Stack>
      </Card>
      <ModifyIncrementalAttributeDialog
        title={strings.track_count_title}
        description={strings.track_count_message}
        value={album.tracks}
        canClear={true}
        open={isTracksDialogOpen}
        setOpen={setIsTracksDialogOpen}
        onChange={updateTracks}
      />
      <ModifyLengthAttributeDialog
        title={strings.album_length_title}
        description={strings.album_length_message}
        missingText={strings.field_missing}
        trackList={album.trackList}
        value={album.length}
        open={isLengthDialogOpen}
        setOpen={setIsLengthDialogOpen}
        onChange={updateLength}
        onClearValue={() => updateLength(0)}
      />
      <ModifyGenericAttributeDialog
        title={strings.discogs_id_title}
        description={strings.discogs_id_message}
        placeholder={strings.ma_discogs_id_placeholder}
        missingText={strings.field_missing}
        value={album.discogsReleaseId}
        canClear={true}
        open={isDiscogsIdDialogOpen}
        setOpen={setIsDiscogsIdDialogOpen}
        isNumber={false}
        onChange={async newValue => {
          await updateDiscogsId(newValue);
        }}
        onClearValue={async () => {
          await updateDiscogsId(null);
        }}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </ListItem>
  );
};

export interface ITrackListHeaderListItemProps {
  isPublic: boolean;
  className?: string;
  album: IAlbum;
  onDiscogsIdUpdated: (newValue: string | null) => void;
  onLengthUpdated: () => void;
  onTracksUpdated: () => void;
  onCopy: () => void;
  onPlayFromYouTube: (videoIds: string[]) => void;
  onMarkAs: (sellStatus: SellStatus | null) => void;
  onDelete: () => void;
}
