import React, { FunctionComponent, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { Dialog, Divider, Grow } from "@mui/material";
import { RightDetailListItem } from "../../RightDetailListItem";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { SleeveGradesDesktop } from "../../attributes/sleeveGrades/SleeveGradesDesktop";
import { AlertDialog } from "../../AlertDialog";
import { IUpdateAlbumParameters, useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { MediaGradesDesktop } from "../../attributes/mediaGrades/MediaGradesDesktop";
import { BooleanAttributesListItem } from "../../attributes/BooleanAttributesListItem";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { useIsMobile } from "../../../hooks/isMobile";
import { GoldmineRatingCard } from "../../attributes/GoldmineRatingCard";
import { IMediaGrade } from "../../../hooks/mediaGrades";
import { ISleeveGrade } from "../../../hooks/sleeveGrades";

export const AlbumGradesListSection: FunctionComponent<IAlbumGradesListSectionProps> = ({
  isPublic,
  album,
  onAttributeUpdated
}) => {
  const isMobile = useIsMobile();
  const { updateAlbum } = useUpdateAlbum();
  const [isCleanLoading, setIsCleanLoading] = useState(false);
  const [isGatefoldLoading, setIsGatefoldLoading] = useState(false);
  const [hasLyricsLoading, setHasLyricsLoading] = useState(false);
  const [hasInsertLoading, setHasInsertLoading] = useState(false);
  const [isSleeveGradeLoading, setIsSleeveGradeLoading] = useState(false);
  const [isSleeveGradesDialogOpen, setIsSleeveGradesDialogOpen] = useState(false);
  const [isMediaGradeLoading, setIsMediaGradeLoading] = useState(false);
  const [isMediaGradesDialogOpen, setIsMediaGradesDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const updateAlbumAttribute = async (parameters: IUpdateAlbumParameters) => {
    const { status, body } = await updateAlbum(parameters);
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      return true;
    } else if (!StatusCodesHelper.isSessionExpired(status)) {
      toast.error(body.message);
      return false;
    }
  };
  const updateMediaGrade = async (newValue: IMediaGrade | undefined) => {
    setIsMediaGradeLoading(true);
    setIsMediaGradesDialogOpen(false);
    const { body, status } = await updateAlbum({
      uuid: album.uuid,
      mediaGradeId: newValue?.id ?? null
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.mediaGradeId = newValue?.id;
      album.mediaGrade = newValue;
      onAttributeUpdated(album);
    }
    setIsMediaGradeLoading(false);
  };
  const updateSleeveGrade = async (newValue: ISleeveGrade | undefined) => {
    setIsSleeveGradeLoading(true);
    setIsSleeveGradesDialogOpen(false);
    const { body, status } = await updateAlbum({
      uuid: album.uuid,
      sleeveGradeId: newValue?.id ?? null
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.sleeveGradeId = newValue?.id;
      album.sleeveGrade = newValue;
      onAttributeUpdated(album);
    }
    setIsSleeveGradeLoading(false);
  };
  return (
    <div>
      <RightDetailListItem
        disabled={isPublic}
        title={album.isBoxSet ? strings.sleeve_grade_box_set_title : strings.sleeve_grade_title}
        detail={album.sleeveGrade ? undefined : strings.unknown}
        leftSubtitle={album.sleeveGrade?.description}
        isLoading={isSleeveGradeLoading}
        onClick={() => setIsSleeveGradesDialogOpen(true)}
      >
        {album.sleeveGrade && (
          <>
            <p className={styles.gradeNumber}>
              {album.sleeveGrade.points <= 10 ? album.sleeveGrade.points : strings.mint}
            </p>
            <GoldmineRatingCard
              className={styles.goldmineRatingCard}
              points={album.sleeveGrade.points}
              goldmineGrading={album.sleeveGrade.goldmineGrade}
            />
          </>
        )}
      </RightDetailListItem>
      <Divider variant={"inset"} />
      {!album.isBoxSet && (
        <>
          <RightDetailListItem
            disabled={isPublic}
            title={strings.media_grade_singular_title}
            detail={album.mediaGrade ? undefined : strings.unknown}
            leftSubtitle={album.mediaGrade?.description}
            isLoading={isMediaGradeLoading}
            onClick={() => setIsMediaGradesDialogOpen(true)}
          >
            {album.mediaGrade && (
              <>
                <p className={styles.gradeNumber}>
                  {album.mediaGrade.points <= 10 ? album.mediaGrade.points : strings.mint}
                </p>
                <GoldmineRatingCard
                  className={styles.goldmineRatingCard}
                  points={album.mediaGrade.points}
                  goldmineGrading={album.mediaGrade.goldmineGrade}
                />
              </>
            )}
          </RightDetailListItem>
          <Divider variant={"inset"} />
        </>
      )}
      <BooleanAttributesListItem
        isPublic={isPublic}
        hideIsGatefold={(isPublic && album.isGatefold === undefined) || album.isBoxSet}
        hideIsClean={isPublic && album.isClean === undefined}
        isCleanLoading={isCleanLoading}
        isGatefoldLoading={isGatefoldLoading}
        hasLyricsLoading={hasLyricsLoading}
        hasInsertLoading={hasInsertLoading}
        onHasInsertChanged={async newValue => {
          setHasInsertLoading(true);
          if (await updateAlbumAttribute({ uuid: album.uuid, hasInsert: newValue })) {
            album.hasInsert = newValue;
            onAttributeUpdated(album);
          }
          setHasInsertLoading(false);
        }}
        onHasLyricsChanged={async newValue => {
          setHasLyricsLoading(true);
          if (await updateAlbumAttribute({ uuid: album.uuid, hasLyrics: newValue })) {
            album.hasLyrics = newValue;
            onAttributeUpdated(album);
          }
          setHasLyricsLoading(false);
        }}
        onIsCleanChanged={async newValue => {
          setIsCleanLoading(true);
          if (await updateAlbumAttribute({ uuid: album.uuid, isClean: newValue })) {
            album.isClean = newValue;
            onAttributeUpdated(album);
          }
          setIsCleanLoading(false);
        }}
        onIsGatefoldChanged={async newValue => {
          setIsGatefoldLoading(true);
          if (await updateAlbumAttribute({ uuid: album.uuid, isGatefold: newValue })) {
            album.isGatefold = newValue;
            onAttributeUpdated(album);
          }
          setIsGatefoldLoading(false);
        }}
        originalIsGatefold={album.isGatefold}
        originalHasInsert={album.hasInsert}
        originalHasLyrics={album.hasLyrics}
        originalIsClean={album.isClean}
      />
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isSleeveGradesDialogOpen}
        onClose={() => setIsSleeveGradesDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <SleeveGradesDesktop
            handleClose={() => {
              setIsSleeveGradesDialogOpen(false);
            }}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={album.sleeveGrade?.id}
            onSleeveGradeSelected={updateSleeveGrade}
            onClearValue={() => updateSleeveGrade(undefined)}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isMediaGradesDialogOpen}
        onClose={() => setIsMediaGradesDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <MediaGradesDesktop
            handleClose={() => {
              setIsMediaGradesDialogOpen(false);
            }}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={album.mediaGrade?.id}
            onMediaGradeSelected={updateMediaGrade}
            onClearValue={() => updateMediaGrade(undefined)}
          />
        </div>
      </Dialog>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </div>
  );
};

export interface IAlbumGradesListSectionProps {
  isPublic: boolean;
  isMobile: boolean;
  album: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
}
