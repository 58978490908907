import { useBackend } from "../../services";
import { IBoxSetAlbum } from "./interfaces";

export const useReorderBoxSetAlbums = () => {
  const { post } = useBackend();
  const reorderBoxSetAlbums = (boxSetAlbumId: string, boxSetAlbums: IBoxSetAlbum[]) => {
    const sortedUuids = boxSetAlbums.map(boxSetAlbum => boxSetAlbum.albumId);
    return post("boxSets/reorder", { boxSetAlbumId, sortedUuids });
  };
  return { reorderBoxSetAlbums };
};
