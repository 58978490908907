import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Dialog, DialogContent, Grow, Stack, Toolbar, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { useIsMounted } from "../../../hooks/isMounted";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { CloseRounded, HelpOutlineRounded } from "@mui/icons-material";
import { useGetLatestPayment } from "../../../hooks/payments/useGetLatestPayment";
import { IPayment } from "../../../hooks/payments/interfaces";
import styles from "./styles.module.scss";
import { PaymentStatus, PaymentStatusCard } from "../PaymentStatusCard";
import { format } from "date-fns";
import { ContainedButton } from "../../ContainedButton";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { SubscriptionHelper } from "../../../models/SubscriptionHelper";
import { SubscriptionsDialog } from "../SubscriptionsDialog";
import { AlertDialog } from "../../AlertDialog";
import { StatusCodes } from "http-status-codes";
import { LoginAgainDialog } from "../../LoginAgainDialog";
import { OAuthLoginButton } from "../../OAuthLoginButton";

export const MySubscriptionDialog: FunctionComponent<IMySubscriptionDialogProps> = ({
  isOpen,
  setIsOpen
}) => {
  const theme = useTheme();
  const isMounted = useIsMounted();
  const { getLatestPayment } = useGetLatestPayment();
  const [payment, setPayment] = useState<IPayment>();
  const [loading, setLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSubscriptionsDialogOpen, setIsSubscriptionsDialogOpen] = useState(false);
  const [isSignInAgainDialogOpen, setIsSignInAgainDialogOpen] = useState(false);
  useEffect(() => {
    if (!isOpen || !isMounted.current) return;
    fetchLatestPayment().then();
  }, [isOpen]);
  const fetchLatestPayment = async () => {
    setLoading(true);
    const { status, body } = await getLatestPayment();
    if (StatusCodesHelper.isSuccessful(status)) {
      setPayment(body);
    } else if (status == StatusCodes.FORBIDDEN) {
      setIsSignInAgainDialogOpen(true);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setLoading(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <h3>{strings.my_subscription_title}</h3>
        <Box flexGrow={1} />
        <ToolbarButton
          href={`${window.location.protocol}//${
            window.location.host
          }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.subscriptions.home()}`}
          target={"_blank"}
          tooltip={strings.help}
          icon={HelpOutlineRounded}
        />
        <ToolbarButton
          loading={loading}
          onClick={() => {
            setIsOpen(false);
          }}
          tooltip={strings.close}
          icon={CloseRounded}
        />
      </Toolbar>
      <DialogContent className={styles.content}>
        {payment ? (
          <Stack className={styles.paymentStack} spacing={1}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <p className={styles.paymentStatus}>{strings.subscription_status}:</p>
              <PaymentStatusCard
                status={(payment.externalSubscriptionStatus ?? "pending") as PaymentStatus}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <p className={styles.paymentStatus}>{strings.payment_last_status}:</p>
              <PaymentStatusCard status={payment.status as PaymentStatus} />
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <p className={styles.paymentStatus}>
                {SubscriptionHelper.isAlive(payment.externalSubscriptionStatus)
                  ? strings.payment_next_date
                  : strings.subscription_end_date}
                :
              </p>
              <p className={styles.date}>
                {format(payment.expirationUnixTimestamp * 1000, "d MMMM yyyy", {
                  locale: strings.fnsLocale
                })}
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <p className={styles.paymentStatus}>{strings.payment_last_update}:</p>
              <p className={styles.date}>
                {format(payment.updatedUnixTimestamp * 1000, "d MMMM yyyy, HH:mm", {
                  locale: strings.fnsLocale
                })}
              </p>
            </div>
          </Stack>
        ) : (
          !loading && (
            <Stack className={styles.paymentStack} spacing={1}>
              <p className={styles.paymentStatus}>{strings.payment_is_processing_message}</p>
            </Stack>
          )
        )}
        {payment?.kind == "mercadoPago" ? (
          <a
            href={"https://www.mercadopago.com.ar/subscriptions"}
            target={"_blank"}
            style={{ textDecoration: "none", color: theme.palette.primary.main, display: "flex" }}
          >
            <OAuthLoginButton
              className={styles.manageButton}
              imageSrc={
                "https://piper-public-images.s3.us-east-2.amazonaws.com/MercadoPagoLogo.svg"
              }
              buttonText={strings.subscription_manage_in_mercadopago}
            />
          </a>
        ) : payment?.kind == "patreon" ? (
          <a
            href={"https://pateron.com"}
            style={{ textDecoration: "none", color: theme.palette.primary.main, display: "flex" }}
          >
            <OAuthLoginButton
              className={styles.manageButton}
              imageSrc={"https://piper-public-images.s3.us-east-2.amazonaws.com/PatreonLogo.png"}
              buttonText={strings.subscription_manage_in_patreon}
            />
          </a>
        ) : (
          <a
            href={"https://apple.co/3P2tpt7"}
            style={{ textDecoration: "none", color: theme.palette.primary.main, display: "flex" }}
          >
            <OAuthLoginButton
              className={styles.manageButton}
              imageSrc={"https://piper-public-images.s3.us-east-2.amazonaws.com/AppleLogo.svg"}
              buttonText={strings.subscription_manage_in_apple}
            />
          </a>
        )}
        {payment && (
          <div style={{ display: "flex" }}>
            <ContainedButton
              className={styles.manageButton}
              onClick={() => setIsSubscriptionsDialogOpen(true)}
            >
              {SubscriptionHelper.isAlive(payment.externalSubscriptionStatus)
                ? strings.subscription_change
                : strings.subscription_renew}
            </ContainedButton>
          </div>
        )}
      </DialogContent>
      <SubscriptionsDialog
        isOpen={isSubscriptionsDialogOpen}
        setIsOpen={setIsSubscriptionsDialogOpen}
      />
      <LoginAgainDialog
        isOpen={isSignInAgainDialogOpen}
        setIsOpen={setIsSignInAgainDialogOpen}
        loginCallback={async () => {
          setIsSignInAgainDialogOpen(false);
          await fetchLatestPayment();
        }}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </Dialog>
  );
};

export interface IMySubscriptionDialogProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}
