import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { SearchRounded } from "@mui/icons-material";
import { InputBase, LinearProgress, Stack, styled, useTheme } from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { useTimer } from "react-timer-hook";
import styles from "./styles.module.scss";
import { ToolbarBox } from "../toolbar/ToolbarBox";
import { useIsMobile } from "../../hooks/isMobile";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.main
}));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4, 0, 0, 0)})`,
    transition: theme.transitions.create("width"),
    color: theme.palette.primary.main,
    width: "100% !important",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  }
}));

export const SearchBar: FunctionComponent<ISearchBarProps> = ({
  className,
  style,
  placeholder,
  onSearchRequested,
  onSearchDismissed,
  timed,
  minimumSearchLength,
  onLoading,
  disabled,
  autoFocus,
  forceThisText
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [searchText, setSearchText] = useState("");
  const [lastSearchText, setLastSearchText] = useState("");
  const [timerProgress, setTimerProgress] = useState(0);
  const isSearchTextValid = (searchText: string) => {
    return searchText.length >= (minimumSearchLength || 3) && searchText != lastSearchText;
  };
  const expiryTimestamp = () => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 3);
    return date;
  };
  const {
    pause: pauseTimer,
    restart: startTimer,
    isRunning: isTimerRunning
  } = useTimer({
    expiryTimestamp: expiryTimestamp(),
    onExpire: () => {
      const trimmedSearchText = searchText.trim();
      setLastSearchText(trimmedSearchText);
      onSearchRequested(trimmedSearchText);
    }
  });
  const stopTimer = () => {
    pauseTimer();
    setTimerProgress(0);
  };
  const restartTimer = (newExpiryTimestamp: Date, autoStart?: boolean | undefined) => {
    startTimer(newExpiryTimestamp, autoStart);
    setTimerProgress(0);
  };
  const clearSearch = () => {
    setSearchText("");
    setLastSearchText("");
    onSearchDismissed();
  };
  useEffect(() => {
    setSearchText(forceThisText ?? "");
    setLastSearchText(forceThisText ?? "");
  }, [forceThisText]);
  useEffect(() => {
    stopTimer();
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.preventDefault();
    const searchText = event.target.value;
    setSearchText(searchText ?? "");
    const trimmedSearchText = searchText.trim();
    if (trimmedSearchText == "") {
      clearSearch();
      if (onLoading) {
        onLoading(false);
      }
      stopTimer();
      return;
    }
    if (timed) {
      if (isSearchTextValid(trimmedSearchText)) {
        if (onLoading) {
          onLoading(true);
        }
        restartTimer(expiryTimestamp());
      } else {
        if (onLoading) {
          onLoading(false);
        }
        stopTimer();
      }
    } else {
      setLastSearchText(trimmedSearchText);
      onSearchRequested(trimmedSearchText);
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setTimerProgress(oldProgress => {
        if (oldProgress == 100) {
          return 100;
        }
        return oldProgress + 10;
      });
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Stack className={className} style={style} spacing={0}>
      <ToolbarBox
        style={{ marginTop: 4, marginBottom: 0, height: isMobile ? 30 : 36, width: "auto" }}
      >
        <SearchIconWrapper className={styles.iconWrapper}>
          <SearchRounded className={styles.icon} />
        </SearchIconWrapper>
        <StyledInputBase
          className={styles.input}
          value={searchText}
          placeholder={placeholder ?? strings.search}
          disabled={disabled}
          autoFocus={autoFocus}
          inputProps={{ "aria-label": "search" }}
          onChange={handleChange}
        />
      </ToolbarBox>
      <LinearProgress
        className={styles.progressBar}
        sx={{
          width: isMobile ? "84%" : "93%",
          backgroundColor: theme.palette.colors?.buttonBackground,
          opacity: timed ? 0.5 : 0
        }}
        variant="determinate"
        value={isTimerRunning ? timerProgress : 0}
      />
    </Stack>
  );
};

export interface ISearchBarProps {
  className?: string;
  style?: CSSProperties;
  placeholder?: string;
  onSearchRequested: (searchText: string) => void;
  onSearchDismissed: () => void;
  timed?: boolean;
  minimumSearchLength?: number;
  onLoading?: (isLoading: boolean) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  forceThisText?: string;
}
