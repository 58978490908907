import React, { FunctionComponent } from "react";
import { Button, CardActionArea, Stack, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { FilterElement } from "../../../models/FilterElement";

export const GenericFilterComponent: FunctionComponent<IGenericFilterComponentProps> = ({
  filterElement,
  isNegated,
  onClick,
  onNegatedChange
}) => {
  const theme = useTheme();
  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"}>
      {filterElement.canBeNegated && onNegatedChange && (
        <Button
          onClick={() => onNegatedChange(!isNegated)}
          sx={{
            fontSize: "16px",
            fontWeight: "normal",
            padding: 0,
            borderWidth: 1,
            borderColor: theme.palette.colors?.border,
            borderStyle: "solid",
            color: isNegated ? theme.palette.colors.remove : undefined
          }}
        >
          {isNegated ? strings.filter_is_not : strings.filter_is}
        </Button>
      )}
      <CardActionArea
        className={styles.filterComponentTitle}
        sx={{
          borderWidth: 1,
          borderColor: theme.palette.colors?.border,
          borderStyle: "solid"
        }}
        onClick={onClick}
      >
        <p
          className={styles.genericFilterTitle}
          style={{
            color: filterElement.textValue
              ? theme.palette.primary.main
              : theme.palette.colors?.disabled
          }}
        >
          {filterElement.textValue ?? strings.no_value}
        </p>
      </CardActionArea>
    </Stack>
  );
};

export interface IGenericFilterComponentProps {
  filterElement: FilterElement;
  isNegated: boolean;
  onClick: (event: React.MouseEvent) => void;
  onNegatedChange?: (newValue: boolean) => void;
}
