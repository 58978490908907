import { useBackend } from "../../services";
import { ILabel } from "../labels";
import { IStore } from "../stores";
import { IArtist } from "../artists";
import { IImpact } from "../impacts";
import { IAlbumType } from "../albumTypes";
import { IAlbumFormat } from "../albumFormats";
import { ISleeveGrade } from "../sleeveGrades";
import { IMediaGrade } from "../mediaGrades";
import { ITag } from "../tags";

export const useCreateStats = () => {
  const { post } = useBackend();
  const createStats = (parameters: ICreateStatsParameters) => post("conclusions", parameters);
  return { createStats };
};

export interface ICreateStatsParameters {
  collectionId?: string;
}

export interface IStats {
  totalAlbumCount: number;
  totalBoxSetCount: number;
  cleanCount: number;
  gatefoldCount: number;
  insertCount: number;
  lyricsCount: number;
  impactCounts?: IGradeCount<IImpact>[];
  sleeveGradeCounts?: IGradeCount<ISleeveGrade>[];
  mediaGradeCounts?: IGradeCount<IMediaGrade>[];
  averageOriginalYear: number;
  averageReleaseYear: number;
  totalDiscs: number;
  totalLength: number;
  albumAverageLength: number;
  discAverageLength?: number;
  trackAverageLength: number;
  tracksByAlbum: number;
  artistCount: number;
  artist?: IArtist;
  store?: IStore;
  label?: ILabel;
  coloredAlbumsCount: number;
  totalPurchasePrice: number;
  totalSellValue: number;
  albumTypeCounts?: IGradeCount<IAlbumType>[];
  albumFormatCounts?: IGradeCount<IAlbumFormat>[];
  tagCounts?: IGradeCount<ITag>[];
  createdUnixTimestamp: number;
}

export interface IGradeCount<T> {
  grade: T;
  count: number;
}
