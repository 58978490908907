import React, { FunctionComponent, useState } from "react";
import { IShelfSegment, IShelfSegmentAlbum } from "../../../hooks/shelfSegments";
import { ShelfSegmentAlbumRepresentation } from "../ShelfSegmentAlbumRepresentation";
import { Stack } from "@mui/system";
import styles from "./styles.module.scss";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { Divider, ListItemIcon, Menu, MenuItem, Tooltip, useTheme } from "@mui/material";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { useUserProfile } from "../../../hooks/session";
import { useContextMenu } from "../../../hooks/contextMenu";
import { ArrowOutwardRounded, RemoveCircleOutlineRounded } from "@mui/icons-material";
import { strings } from "../../../localization/LocalizedStrings";
import { IShelving } from "../../../hooks/shelvings";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { useGetPrivateLink } from "../../../hooks/share/useGetPrivateLink";
import { IAlbum } from "../../../hooks/albums";
import { ShelvingUtilities } from "../../../models/ShelvingUtilities";

export const ShelfSegmentRepresentation: FunctionComponent<IShelfSegmentRepresentationProps> = ({
  shelving,
  shelfSegment,
  selectedAlbumIds,
  isLoading,
  setIsLoading,
  onShelfSegmentAlbumSelected,
  onRemoveAlbumFromShelfSegment,
  shelvingIndex,
  shelfIndex,
  segmentIndex,
  canRemove
}) => {
  const theme = useTheme();
  const [currentUserProfile] = useUserProfile.useState();
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const [selectedAlbum, setSelectedAlbum] = useState<IShelfSegmentAlbum>();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>();
  const [selectedAlbumIndex, setSelectedAlbumIndex] = useState<number>();
  const { getPrivateLink } = useGetPrivateLink();
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const openOnPrivateCollection = async () => {
    if (!selectedAlbum) return;
    setIsLoading(true);
    const { status, body } = await getPrivateLink({ albumId: selectedAlbum.album.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      openInNewTab(body.shareLink);
    } else {
      toast.error(body.message);
    }
    setIsLoading(false);
  };
  const albumDetail = (album: IAlbum) => (
    <div className={styles.tooltipDiv}>
      <p className={styles.tooltipAlbumName}>{album.name}</p>
      <p className={styles.tooltipArtistName}>{album.artist?.name ?? strings.unknown}</p>
      <p className={styles.tooltipSubtitle} style={{ color: theme.palette.primary.subtitle }}>
        {AlbumUtilities.getSubtitle(album, currentUserProfile)}
      </p>
    </div>
  );
  return (
    <li
      className={styles.segment}
      key={`li_segment_${shelfSegment.uuid}`}
      style={{
        border: "5px solid transparent",
        borderImage: `url(${ShelvingUtilities.getThemeImage(shelving.theme)}) 100`,
        flexDirection: shelving.orientation == "horizontal" ? "column" : "row",
        backgroundImage: `url(${ShelvingUtilities.getThemeImage(shelving.theme)})`,
        backgroundColor: "rgba(0,0,0,0.7)",
        backgroundBlendMode: "darken",
        backgroundRepeat: "repeat"
      }}
    >
      <Droppable
        key={`st_${shelfSegment.uuid}`}
        droppableId={`shelf_${shelfSegment.uuid}_${shelvingIndex}_${shelfIndex}_${segmentIndex}`}
        direction={shelving.orientation}
      >
        {provided => (
          <Stack
            className={styles.segment}
            direction={shelving.orientation == "horizontal" ? "row" : "column"}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div
              className={styles.albumsContainer}
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginTop: "auto",
                width: shelving.orientation == "horizontal" ? undefined : "100%",
                height: shelving.orientation == "horizontal" ? "100%" : undefined,
                minWidth: shelving.orientation == "horizontal" ? "120px" : undefined,
                minHeight: shelving.orientation == "horizontal" ? undefined : "120px"
              }}
            >
              <div
                className={styles.albumsDiv}
                style={{
                  width: shelving.orientation == "horizontal" ? undefined : "100%",
                  height: shelving.orientation == "horizontal" ? "100%" : undefined,
                  padding: shelving.orientation == "horizontal" ? "0 2px 0 2px" : "2px 0 2px 0",
                  flexDirection: shelving.orientation == "horizontal" ? "row" : "column"
                }}
              >
                {shelfSegment.albums
                  ?.sort((a, b) => a.order - b.order)
                  .map((shelfSegmentAlbum, index) => (
                    <Draggable
                      key={`seg_${shelfSegment.uuid}_${shelfSegmentAlbum.album.uuid}_${index}`}
                      draggableId={`drag_${shelfSegment.uuid}_${shelfSegmentAlbum.album.uuid}_${shelvingIndex}_${shelfIndex}_${segmentIndex}_${index}`}
                      index={index}
                      isDragDisabled={isLoading}
                    >
                      {provided => (
                        <Tooltip
                          title={albumDetail(shelfSegmentAlbum.album)}
                          disableInteractive
                          followCursor
                        >
                          <div
                            className={
                              shelving.orientation == "horizontal"
                                ? styles.albumContainerHorizontal
                                : styles.albumContainerVertical
                            }
                            onContextMenu={event => {
                              setSelectedAlbum(shelfSegmentAlbum);
                              setSelectedSegmentIndex(segmentIndex);
                              setSelectedAlbumIndex(index);
                              handleContextMenu(event);
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => {
                              setSelectedAlbum(shelfSegmentAlbum);
                              setSelectedSegmentIndex(segmentIndex);
                              setSelectedAlbumIndex(index);
                              selectedAlbumIds.length == 1 &&
                              selectedAlbumIds[0] == shelfSegmentAlbum.album.uuid
                                ? onShelfSegmentAlbumSelected(undefined)
                                : onShelfSegmentAlbumSelected(shelfSegmentAlbum);
                            }}
                          >
                            <ShelfSegmentAlbumRepresentation
                              shelving={shelving}
                              shelfSegment={shelfSegment}
                              shelfSegmentAlbum={shelfSegmentAlbum}
                              isHighlighted={selectedAlbumIds.includes(
                                shelfSegmentAlbum.album.uuid
                              )}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </Draggable>
                  ))}
              </div>
            </div>
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
      <Menu {...menuProps}>
        {selectedAlbum && albumDetail(selectedAlbum.album)}
        <Divider style={{ marginTop: "6px", marginBottom: "4px" }} />
        <MenuItem
          key={"OpenOnPrivateCollection"}
          onClick={async () => {
            handleMenuClose();
            await openOnPrivateCollection();
          }}
          dense
        >
          <ListItemIcon>
            <ArrowOutwardRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.option_view_detail}
        </MenuItem>
        {canRemove && (
          <MenuItem
            key={"Remove"}
            onClick={() => {
              handleMenuClose();
              onRemoveAlbumFromShelfSegment(
                selectedAlbum,
                selectedSegmentIndex,
                selectedAlbumIndex
              );
            }}
            style={{ color: theme.palette.colors?.remove }}
            dense
          >
            <ListItemIcon>
              <RemoveCircleOutlineRounded
                style={{ color: theme.palette.colors?.remove }}
                fontSize={"small"}
              />
            </ListItemIcon>
            {strings.shelf_remove_album}
          </MenuItem>
        )}
      </Menu>
    </li>
  );
};

export interface IShelfSegmentRepresentationProps {
  shelving: IShelving;
  shelfSegment: IShelfSegment;
  selectedAlbumIds: string[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onShelfSegmentAlbumSelected: (shelfSegmentAlbum: IShelfSegmentAlbum | undefined) => void;
  onRemoveAlbumFromShelfSegment: (
    shelfSegmentAlbum: IShelfSegmentAlbum,
    segmentIndex: number,
    albumIndex: number
  ) => void;
  shelvingIndex: number;
  shelfIndex: number;
  segmentIndex: number;
  canRemove: boolean;
}
