import { useBackend } from "../../services";

export const useRefreshCollectionHighlights = () => {
  const { post } = useBackend();
  return async (props: IRefreshHighlightsProps) => post("admin/collections/highlights", props);
};

export const useRefreshAlbumHighlights = () => {
  const { post } = useBackend();
  return async () => post("admin/albums/highlights", undefined);
};

interface IRefreshHighlightsProps {
  quantity: number;
}
