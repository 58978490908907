import { IFilterAlbumsParameters } from "../hooks/albums";

export class FilterElement {
  title: string;
  placeholder?: string;
  elementId?: string;
  textValue?: string;
  filterType: FilterType;
  isFromCatering: boolean;
  canBeNegated: boolean;

  constructor(
    title: string,
    filterType: FilterType,
    isFromCatering: boolean,
    canBeNegated: boolean,
    elementId?: string,
    textValue?: string,
    placeholder?: string
  ) {
    this.title = title;
    this.placeholder = placeholder;
    this.filterType = filterType;
    this.elementId = elementId;
    this.textValue = textValue;
    this.isFromCatering = isFromCatering;
    this.canBeNegated = canBeNegated;
  }

  static copy(filterElement: FilterElement) {
    return new FilterElement(
      filterElement.title,
      filterElement.filterType,
      false,
      filterElement.canBeNegated,
      filterElement.elementId,
      filterElement.textValue,
      filterElement.placeholder
    );
  }

  static queryItems(
    filterElements?: FilterElement[],
    sortOption?: FilterSortOption,
    sortOrder?: FilterSortOrder
  ) {
    const queryItems: IFilterAlbumsParameters = {
      sortOption: sortOption ?? "artist",
      sortOrder: sortOrder ?? "asc"
    };
    filterElements.forEach(filterElement => {
      const value = filterElement.elementId;
      if (value === undefined && filterElement.filterType != FilterType.SEARCH_TEXT) {
        queryItems.isNull =
          queryItems.isNull === undefined
            ? filterElement.filterType
            : queryItems.isNull + `,${filterElement.filterType}`;
        return;
      }
      const oldValue = queryItems[filterElement.filterType];
      if (oldValue) {
        if (Array.isArray(oldValue)) {
          queryItems[filterElement.filterType] = [...oldValue, value];
        } else {
          queryItems[filterElement.filterType] = [oldValue, value];
        }
      } else {
        queryItems[filterElement.filterType] = value;
      }
    });
    return queryItems;
  }
}

export enum FilterType {
  IS_GATEFOLD = "isGatefold",
  HAS_INSERT = "hasInsert",
  HAS_LYRICS = "hasLyrics",
  IS_CLEAN = "isClean",
  STORE = "storeId",
  LABEL = "labelId",
  IMPACT = "impactId",
  SLEEVE_GRADE = "sleeveGradeId",
  MEDIA_GRADE = "mediaGradeId",
  SELL_VALUE = "sellValueId",
  ARTIST = "artistId",
  ALBUM_TYPE = "albumTypeId",
  ALBUM_FORMAT = "albumFormatId",
  COUNTRY = "country",
  RELEASE_YEAR = "releaseYear",
  ORIGINAL_YEAR = "originalYear",
  SELL_STATUS = "sellStatus",
  TAG = "tagId",
  SEARCH_TEXT = "searchText",
  BOX_SET = "isBoxSet"
}

export enum FilterSortOption {
  ARTIST = "artist",
  LABEL = "label",
  ORIGINAL_YEAR = "originalYear",
  RELEASE_YEAR = "releaseYear",
  STORE = "store",
  COUNTRY = "country",
  PURCHASE_PRICE = "purchasePrice",
  SELL_VALUE = "sellValue",
  SELL_VALUE_DOLLARS = "sellValueDollars",
  REVENUE = "revenue",
  PURCHASE_DATE = "purchaseDate",
  TRACK_COUNT = "trackCount",
  LENGTH = "length",
  RANDOM = "random"
}

export enum FilterSortOrder {
  ASCENDANT = "asc",
  DESCENDANT = "desc"
}
