import React, { FunctionComponent, useState } from "react";
import { Box, Dialog, DialogContent, Grow, Stack, Toolbar } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { ISubscription } from "../../../hooks/subscriptions";
import { AlertDialog } from "../../AlertDialog";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { CloseRounded } from "@mui/icons-material";
import styles from "./styles.module.scss";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { useSessionManager, useUser } from "../../../hooks/session";
import { SubscriptionOptionCard } from "../SubscriptionOptionCard";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { useGetPatreonUserId } from "../../../hooks/patreon";

export const SubscriptionDialog: FunctionComponent<ISubscriptionDialogProps> = ({
  subscription,
  isOpen,
  setIsOpen
}) => {
  const navigate = useNavigate();
  const getPatreonUserId = useGetPatreonUserId();
  const { closeSession } = useSessionManager();
  const [isPayWithMercadoPagoAlertOpen, setIsPayWithMercadoPagoAlertOpen] = useState(false);
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [currentUser] = useUser.useState();
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <h3>{subscription.name}</h3>
        <Box flexGrow={1} />
        <ToolbarButton
          onClick={() => {
            setIsOpen(false);
          }}
          tooltip={strings.close}
          icon={CloseRounded}
        />
      </Toolbar>
      <DialogContent>
        <Stack className={styles.textStack} spacing={2}>
          <p className={styles.description}>{subscription.description}</p>
          <SubscriptionOptionCard
            price={subscription.finalPriceARS}
            currency={"ARS"}
            imageSrc={"https://piper-public-images.s3.us-east-2.amazonaws.com/MercadoPagoLogo.jpg"}
            buttonText={strings.subscription_pay_with_mercadopago}
            description={strings.subscription_pay_info}
            optionText={strings.pay_option_mercado_pago}
            onClick={() => setIsPayWithMercadoPagoAlertOpen(true)}
          />
          <SubscriptionOptionCard
            price={subscription.finalPriceUSD}
            currency={"USD"}
            imageSrc={"https://piper-public-images.s3.us-east-2.amazonaws.com/PatreonLogo.png"}
            buttonText={strings.subscription_pay_with_patreon}
            description={strings.subscription_pay_info_patreon}
            optionText={strings.pay_option_patreon}
            onClick={async () => {
              const { status } = await getPatreonUserId();
              if (StatusCodesHelper.isSuccessful(status)) {
                window.location.href =
                  "https://patreon.com/Piper107?utm_medium=piper&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink";
              } else {
                window.location.href = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_PATREON_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}/paymentPatreon&state=${currentUser.uuid}`;
              }
            }}
          />
          <SubscriptionOptionCard
            price={subscription.finalPriceUSD}
            currency={"USD"}
            imageSrc={"https://piper-public-images.s3.us-east-2.amazonaws.com/AppleLogo.svg"}
            buttonText={strings.subscription_pay_with_apple}
            description={strings.subscription_pay_info_apple}
            optionText={strings.pay_option_apple}
            onClick={() => (window.location.href = `https://apple.co/3P2tpt7`)}
          />
        </Stack>
      </DialogContent>
      <AlertDialog
        message={strings.subscription_pay_with_mercadopago_alert}
        open={isPayWithMercadoPagoAlertOpen}
        setOpen={setIsPayWithMercadoPagoAlertOpen}
        isConfirm
        onConfirm={() =>
          (window.location.href = `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=${subscription.mercadoPagoPlanId}`)
        }
      />
      <AlertDialog
        title={strings.app_name}
        message={strings.subscription_success}
        open={isSuccessAlertOpen}
        setOpen={setIsSuccessAlertOpen}
        onConfirm={() => {
          closeSession();
          navigate(RoutesBuilder.home());
        }}
      />
    </Dialog>
  );
};

export interface ISubscriptionDialogProps {
  subscription: ISubscription;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}
