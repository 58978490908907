import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Toolbar,
  useTheme
} from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { TextField } from "../../../TextField";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CloseRounded, Delete } from "@mui/icons-material";
import { AlertDialog } from "../../../AlertDialog";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { FieldTitle } from "../../../Text/FieldTitle";
import { ContainedButton } from "../../../ContainedButton";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../../hooks/isMobile";
import { FreeTextListItem } from "../../../FreeTextListItem";
import { ModifyFreeTextAttributeDialog } from "../../ModifyFreeTextAttributeDialog";
import { ITag } from "../../../../hooks/tags";
import { useAddTag } from "../../../../hooks/tags/useAddTag";
import { useModifyTag } from "../../../../hooks/tags/useModifyTag";
import { useDeleteTag } from "../../../../hooks/tags/useDeleteTag";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../../../services/Firebase";

export const ModifyTagDialog: FunctionComponent<IModifyTagDialogProps> = ({
  open,
  setOpen,
  tag,
  onNewItem,
  onModifiedItem,
  onDeletedItem
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const analytics = Firebase.shared.getAnalytics();
  const [dialogTitle, setDialogTitle] = useState(strings.ms_new_title);
  const { addTag } = useAddTag();
  const { modifyTag } = useModifyTag();
  const { deleteTag } = useDeleteTag();
  const [isLoading, setIsLoading] = useState(false);
  const [isModifyDescriptionDialogOpen, setIsModifyDescriptionDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    if (isLoading) return;
    setOpen(false);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(strings.ms_missing_error)
  });
  const formik = useFormik({
    initialValues: {
      name: tag?.name ?? "",
      description: tag?.description
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async ({ name, description }) => {
      setIsLoading(true);
      if (tag) {
        const { status, body } = await modifyTag({
          uuid: tag.uuid,
          name: name,
          description: description
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_tag_action", { isNew: false });
          if (onModifiedItem != undefined) {
            onModifiedItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      } else {
        const { status, body } = await addTag({
          name: name,
          description: description
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_tag_action", { isNew: true });
          if (onNewItem != undefined) {
            onNewItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      }
      setIsLoading(false);
    },
    validationSchema: validationSchema
  });
  const onDelete = async () => {
    if (!tag) return;
    setIsLoading(true);
    const { status, body } = await deleteTag({ uuid: tag.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (onDeletedItem != undefined) {
        onDeletedItem(tag);
      }
      handleClose();
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!open) return;
    setDialogTitle(tag ? strings.tag_title : strings.new_tag_title);
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <Toolbar>
          <h3>{dialogTitle}</h3>
          <Box sx={{ flexGrow: 1 }} />
          {tag && (
            <ToolbarButton
              onClick={onDelete}
              tooltip={strings.delete}
              icon={Delete}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FieldTitle>{strings.name_title.toUpperCase()}</FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="name"
              name="name"
              size="small"
              autoFocus={!isMobile}
              placeholder={strings.name_title}
              value={formik.values.name}
              onChange={formik.handleChange}
              enterAction={formik.submitForm}
            />
          </div>
          <FieldTitle>{strings.collection_description.toUpperCase()}</FieldTitle>
          <Card className={styles.profileCard}>
            <FreeTextListItem
              hideTitle
              detail={formik.values.description ?? "--"}
              maxLines={4}
              onClick={() => setIsModifyDescriptionDialogOpen(true)}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <ModifyFreeTextAttributeDialog
        title={strings.collection_description}
        description={strings.edit_description_description}
        placeholder={strings.collection_description}
        value={formik.values.description}
        open={isModifyDescriptionDialogOpen}
        setOpen={setIsModifyDescriptionDialogOpen}
        onChange={newValue => formik.setFieldValue("description", newValue)}
        onClearValue={() => formik.setFieldValue("description", null)}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifyTagDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  tag?: ITag;
  onNewItem?: (item: ITag) => void;
  onModifiedItem?: (item: ITag) => void;
  onDeletedItem?: (item: ITag) => void;
}
