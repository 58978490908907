import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Box, CardActionArea, Dialog, DialogContent, Grow, Toolbar } from "@mui/material";
import { CloseRounded, HelpOutlineRounded } from "@mui/icons-material";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import { ToolbarButton } from "../toolbar/ToolbarButton";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useImportFromSpreadsheet } from "../../hooks/xlsx/useImportFromSpreadsheet";
import { CollectionsDialog } from "../collections/CollectionsDialog";
import { ICollection } from "../../hooks/collections";
import { StringFormat } from "../../models/StringFormat";
import { AlertDialog } from "../AlertDialog";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { ContainedButton } from "../ContainedButton";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../services/Firebase";

export const ExcelImportDialog: FunctionComponent<IExcelImportDialogProps> = ({
  isOpen,
  setIsOpen
}) => {
  const [file, setFile] = useState<File & { preview: string }>();
  const analytics = Firebase.shared.getAnalytics();
  const { importFromSpreadsheet } = useImportFromSpreadsheet();
  const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<ICollection>();
  const [isConfirmImportAlertOpen, setIsConfirmImportAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isOpen) return;
    setFile(undefined);
  }, [isOpen]);
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    padding: "80px 20px 80px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out"
  };
  const focusedStyle = {
    borderColor: "#2196f3"
  };
  const acceptStyle = {
    borderColor: "#00e676"
  };
  const rejectStyle = {
    borderColor: "#ff1744"
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles, filesRejection) => {
      if (filesRejection.length > 0) {
        toast.error(strings.upload_multiple_files_error);
        return;
      }
      const file = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      });
      setFile(file);
    }
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const uploadFile = async () => {
    setIsCollectionsDialogOpen(false);
    setIsLoading(true);
    if (!file) return;
    const { status, body } = await importFromSpreadsheet(
      file,
      selectedCollection ? [selectedCollection] : []
    );
    if (StatusCodesHelper.isSuccessful(status)) {
      if (body.problems.length == 0) {
        setIsOpen(false);
        logEvent(analytics, "import_spreadsheet");
        toast.success(strings.generic_update_success);
      } else {
        setAlertMessage(StringFormat(strings.excel_import_problems, body.problems.join("\n")));
        setIsAlertOpen(true);
      }
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <h3>{strings.excel_import_title}</h3>
        <Box flexGrow={1} />
        <ToolbarButton
          href={`${window.location.protocol}//${
            window.location.host
          }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.importFromExcel()}`}
          target={"_blank"}
          tooltip={strings.help}
          icon={HelpOutlineRounded}
        />
        <ToolbarButton
          onClick={() => setIsOpen(false)}
          tooltip={strings.close}
          icon={CloseRounded}
          loading={isLoading}
        />
      </Toolbar>
      <DialogContent className={styles.content}>
        <p className={styles.message}>{strings.excel_import_message}</p>
        <ContainedButton className={styles.templateButton} href={strings.excel_import_template_url}>
          {strings.excel_import_download_template}
        </ContainedButton>
        <CardActionArea {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p className={styles.dropzonePlaceholder}>
            {file ? file.name : strings.dropzone_placeholder}
          </p>
        </CardActionArea>
        <ContainedButton
          className={styles.confirmButton}
          disabled={file === undefined || isLoading}
          onClick={() => setIsCollectionsDialogOpen(true)}
        >
          {strings.alert_confirm}
        </ContainedButton>
      </DialogContent>
      <CollectionsDialog
        open={isCollectionsDialogOpen}
        setOpen={setIsCollectionsDialogOpen}
        canModifyCollections={false}
        displayStandardCollections={true}
        onCollectionSelected={collection => {
          setSelectedCollection(collection);
          setIsConfirmImportAlertOpen(true);
        }}
      />
      <AlertDialog
        message={StringFormat(
          strings.excel_import_confirmation,
          file?.name ?? "",
          selectedCollection?.name ?? ""
        )}
        open={isConfirmImportAlertOpen}
        setOpen={setIsConfirmImportAlertOpen}
        isConfirm
        onConfirm={async () => uploadFile()}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </Dialog>
  );
};

export interface IExcelImportDialogProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}
