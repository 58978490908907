import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  useTheme
} from "@mui/material";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CloseRounded, Delete } from "@mui/icons-material";
import {
  CollectionVisibility,
  ICollection,
  useAddCollection,
  useModifyCollection
} from "../../../hooks/collections";
import { strings } from "../../../localization/LocalizedStrings";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { TextField } from "../../TextField";
import { AlertDialog } from "../../AlertDialog";
import { SwitchListItem } from "../../SwitchListItem";
import { SelectChipsListItem } from "../../SelectChipsListItem";
import { CollectionBannedFieldsDialog } from "../CollectionBannedFieldsDialog";
import { CollectionBannedFieldsManager } from "../../../models/CollectionBannedFieldsManager";
import { toast } from "react-toastify";
import { FieldTitle } from "../../Text/FieldTitle";
import { ContainedButton } from "../../ContainedButton";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";
import { FreeTextListItem } from "../../FreeTextListItem";
import { ModifyFreeTextAttributeDialog } from "../../attributes/ModifyFreeTextAttributeDialog";
import genericImage from "../../../images/collection.jpg";
import { ImageDropzone } from "../../ImageDropzone";
import { ImageCache } from "../../../models/ImageCache";
import { useGetImage, useUploadImage } from "../../../hooks/images";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../../services/Firebase";

export const ModifyCollectionDialog: FunctionComponent<IModifyCollectionDialogProps> = ({
  open,
  setOpen,
  collection,
  callback,
  onDelete
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const analytics = Firebase.shared.getAnalytics();
  const [dialogTitle, setDialogTitle] = useState(strings.collection_new_title);
  const { addCollection } = useAddCollection();
  const { modifyCollection } = useModifyCollection();
  const { uploadImage } = useUploadImage();
  const [isModifyDescriptionDialogOpen, setIsModifyDescriptionDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bannedFieldsDialogOpen, setBannedFieldsDialogOpen] = useState(false);
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File>();
  const [didChangeImage, setDidChangeImage] = useState(false);
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const possibleDiscounts = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
  const fetchImage = async () => {
    if (!collection?.hasImage) return;
    const response = await getImage({
      name: collection.uuid,
      width: ImageCache.bigCollectionImageWidth,
      height: ImageCache.bigCollectionImageHeight
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  useEffect(() => {
    setImage(undefined);
    fetchImage().then();
  }, [collection]);
  const tryToClose = () => {
    if (isLoading) return;
    if (formik.dirty) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(strings.field_missing)
  });
  const formik = useFormik({
    initialValues: {
      name: collection?.name ?? "",
      description: collection?.description,
      visibility: collection?.visibility ?? "private",
      bannedFields: collection?.bannedFields ?? [],
      discount: collection?.discount ?? 0
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ name, description, visibility, bannedFields, discount }) => {
      setIsLoading(true);
      let statusCode, responseBody;
      if (collection) {
        const { status, body } = await modifyCollection({
          uuid: collection.uuid,
          name: name,
          description: description,
          visibility: visibility,
          bannedFields: bannedFields,
          discount: discount
        });
        statusCode = status;
        responseBody = body;
      } else {
        const { status, body } = await addCollection({
          name: name,
          description: description,
          visibility: visibility,
          bannedFields: bannedFields,
          discount: discount
        });
        statusCode = status;
        responseBody = body;
      }
      if (!StatusCodesHelper.isSuccessful(statusCode)) {
        setAlertMessage(responseBody.message);
        setAlertIsOpen(true);
      } else {
        logEvent(analytics, "collection_action");
        if (didChangeImage && imageFile && responseBody.uuid) {
          await uploadImage(
            { name: responseBody.uuid, file: imageFile },
            ImageCache.bigCollectionImageWidth,
            ImageCache.bigCollectionImageHeight,
            ImageCache.smallCollectionImageWidth,
            ImageCache.smallCollectionImageHeight
          );
        }
        if (callback != undefined) {
          callback();
        }
        handleClose();
        toast.success(strings.generic_update_success);
      }
      setIsLoading(false);
    },
    validationSchema: validationSchema
  });
  useEffect(() => {
    if (!open) return;
    setDialogTitle(collection ? strings.collection_title : strings.collection_new_title);
    formik.resetForm();
  }, [open]);
  const handleVisibilityChange = (
    event: React.MouseEvent<HTMLElement>,
    visibility: CollectionVisibility
  ) => {
    if (visibility !== null) {
      formik.setFieldValue("visibility", visibility);
    }
  };
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"xs"} open={open} onClose={tryToClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{dialogTitle}</h3>
          <Box sx={{ flexGrow: 1 }} />
          {collection && (
            <ToolbarButton
              disabled={isLoading}
              onClick={onDelete}
              tooltip={strings.delete}
              icon={Delete}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton
            loading={isLoading}
            onClick={tryToClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <ImageDropzone
            className={styles.dropzone}
            onImageChanged={file => {
              setImageFile(file);
              setDidChangeImage(true);
            }}
            placeholderImage={genericImage}
            initialImage={
              image
                ? `data:image/png;base64,${image}`
                : collection?.thumbnail
                  ? `data:image/png;base64,${collection.thumbnail}`
                  : undefined
            }
            isLoading={false}
          />
          <p className={styles.subtitle} style={{ color: theme.palette.primary.subtitle }}>
            {strings.collection_image_info}
          </p>
          <FieldTitle>{strings.name_title.toUpperCase()}</FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="name"
              name="name"
              size="small"
              autoFocus={!isMobile}
              placeholder={strings.collection_name_placeholder}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          <FieldTitle>{strings.collection_visibility.toUpperCase()}</FieldTitle>
          {!collection && (
            <p className={styles.subtitle} style={{ color: theme.palette.primary.subtitle }}>
              {strings.collection_create_visibility}
            </p>
          )}
          <SwitchListItem
            className={styles.visibility}
            labels={[strings.private_collection, strings.public_collection]}
            values={["private", "public"]}
            value={formik.values.visibility}
            fullWidth={true}
            disableGutters
            disabled={collection === undefined}
            handleChange={handleVisibilityChange}
          />
          {collection && (
            <FieldArray
              name={"bannedFields"}
              render={arrayHelpers => (
                <div>
                  <FieldTitle>{strings.collection_banned_fields.toUpperCase()}</FieldTitle>
                  <SelectChipsListItem
                    className={styles.bannedFields}
                    disableGutters
                    title={""}
                    chips={CollectionBannedFieldsManager.shared
                      .getNames(formik.values.bannedFields)
                      .map(name => ({
                        id: name,
                        name: name
                      }))}
                    onAdd={() => setBannedFieldsDialogOpen(true)}
                    onDelete={label => {
                      const id = CollectionBannedFieldsManager.shared.getId(label);
                      const bannedFields = formik.values.bannedFields;
                      const index = bannedFields?.indexOf(id);
                      arrayHelpers.remove(index);
                    }}
                  />
                  <CollectionBannedFieldsDialog
                    open={bannedFieldsDialogOpen}
                    setOpen={setBannedFieldsDialogOpen}
                    onSelect={value => {
                      arrayHelpers.push(value);
                      setBannedFieldsDialogOpen(false);
                    }}
                    alreadySelected={formik.values.bannedFields}
                  />
                </div>
              )}
            />
          )}
          <FieldTitle>{strings.collection_discount.toUpperCase()}</FieldTitle>
          <Select
            value={String(formik.values.discount)}
            renderValue={() => <p>{`${String(formik.values.discount * 100)} %`}</p>}
            className={styles.selectDiscount}
            onChange={(event: SelectChangeEvent) => {
              formik.setFieldValue("discount", +event.target.value);
            }}
            sx={{ height: "30px" }}
          >
            {possibleDiscounts.map((discount, index) => (
              <MenuItem key={`item_${index}`} value={discount}>
                {`${String(discount * 100)} %`}
              </MenuItem>
            ))}
          </Select>
          <FieldTitle>{strings.collection_description.toUpperCase()}</FieldTitle>
          <Card className={styles.descriptionCard}>
            <FreeTextListItem
              hideTitle
              detail={formik.values.description ?? "--"}
              maxLines={4}
              onClick={() => setIsModifyDescriptionDialogOpen(true)}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <ModifyFreeTextAttributeDialog
        title={strings.collection_description}
        description={strings.edit_description_description}
        placeholder={strings.collection_description}
        value={formik.values.description}
        open={isModifyDescriptionDialogOpen}
        setOpen={setIsModifyDescriptionDialogOpen}
        onChange={newValue => formik.setFieldValue("description", newValue)}
        onClearValue={() => formik.setFieldValue("description", null)}
      />
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={handleClose}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifyCollectionDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  collection?: ICollection;
  callback?: () => void;
  onDelete?: () => void;
}
