export const doc_album_attributes_en = {
  title: "Album attributes",
  home_title: "General attributes",
  description: `In this section you will find all the attributes you can manage for each album, what they mean, and how you can use them.
  
  Let's start with the simplest ones here and you can find the rest of them in a list at the bottom.
  
`,
  name_title: "Name",
  name_description: `The album name. It can't be larger than 512 characters. This is the only mandatory attribute.
  
`,
  image_title: "Image",
  image_description: `You can change the front cover image of the album. If you have a premium subscription plan, you can also add images for the back cover, spine, and up to 50 additional images of the album.
  
`,
  country_title: "Country",
  country_description: `The country of origin for this specific release, where it was originally distributed.
  
`,
  original_year_title: "Original year",
  original_year_description: `Enter the year when the album originally came out.
  
`,
  release_year_title: "Release year",
  release_year_description: `Enter the year when this specific release came out. This will probably match with the original year for first editions and will differ for re-issues.
  
`,
  catalogue_number_title: "Catalogue number",
  catalogue_number_description: `The catalog number from the label for this release.
  
`,
  discogs_id_title: "Discogs ID",
  discogs_id_description: `The Discogs release ID for this particular release. You can find this number at the release page on Discogs at the top right corner or inside the URL.
  
  This ID can be used to later retrieve the album's track list automatically. It also enables a direct link to the release page on Discogs.
  
`,
  purchase_date_title: "Purchase date",
  purchase_date_description: `The date when you purchased this album. This date will be used to automatically calculate the purchase price using the closest exchange rate.
  
`,
  purchase_price_title: "Purchase price",
  purchase_price_description: `The price that you payed when you purchased this album.
  
  The amount has to be expressed in your own currency (The one you selected in your user profile). Once you update this value, the amount will be converted using the last exchange rate and the closest exchange rate to the purchase date, and it will be displayed that way. It will also display the amount in USD.
  
`,
  track_count_title: "Number of tracks",
  track_count_description: `The number of tracks for this album. 
  
`,
  length_title: "Length",
  length_description: `The album length in this format: HH:mm:ss.
  
  For example, if an album has a length of 33 minutes and 2 seconds you have to enter: '00:33:02'.
  
`,
  collections_title: "Collections",
  collections_description: `Use this attribute to assign an album to one or multiple collections. Assigning an album to a collection doesn't duplicate it. You can assign and unassign albums as you want without fearing to lose any data.
  
  Refer to the collections section on this documentation for more info.
  
`,
  played_title: "Played",
  played_description: `Whether you already played this album. This can be helpful if you want to keep track of the albums that you already listened or not.
  
`,
  has_insert_title: "Has insert",
  has_insert_description: `Whether this album has the original insert/inner sleeve or not.
  
`,
  has_lyrics_title: "Has lyrics",
  has_lyrics_description: `Whether this album has printed lyrics. Sometimes lyrics are included in the insert, the inner sleeve, or the back cover. 
  
`,
  gatefold_title: "Gatefold",
  gatefold_description: `Whether this album's jacket is a gatefold jacket or not. Gatefold jackets fold like a book or a wallet.
  
`,
  sleeve_grade_title: "Jacket gradings",
  sleeve_grade_description: `The preservation status for the album's jacket. This uses the Goldmine grading system.
  
`,
  media_grade_title: "Media grading",
  media_grade_description: `The preservation status for the album's disc/s. This uses the Goldmine grading system.
  
`,
  impact_title: "Rating",
  impact_description: `A personal rating you can give to the album, from 0 to 5 stars.
  
`,
  notes_title: "Notes",
  notes_description: `A free text field where you can put anything you want about the album.
  
`,
  more_attributes: "More attributes"
};
