import { useBackend } from "../../services";

export const useAddAlbumToBoxSet = () => {
  const { put } = useBackend();
  const addAlbumToBoxSet = (parameters: IAddAlbumToBoxSetParameters) => put("boxSets", parameters);
  return { addAlbumToBoxSet };
};

export interface IAddAlbumToBoxSetParameters {
  boxSetAlbumId: string;
  albumId: string;
  order: number;
}
