import React, { FunctionComponent } from "react";
import { Card } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { useIsMobile } from "../../../hooks/isMobile";
import { ContentUnavailableNotice } from "../../attributes/ContentUnavailableNotice";
import { ListAltRounded } from "@mui/icons-material";
import { IAlbum } from "../../../hooks/albums";
import { AlbumList } from "../AlbumList";
import { FilterSortOption } from "../../../models/FilterElement";

export const BoxSetAlbumList: FunctionComponent<IBoxSetAlbumListProps> = ({
  isPublic,
  className,
  boxSet,
  onDeleteFromBoxSet,
  onAlbumSelected,
  setIsLoading
}) => {
  const isMobile = useIsMobile();
  return (
    <Card
      elevation={5}
      className={className}
      sx={{
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignSelf: isMobile ? "center" : "stretch"
      }}
    >
      <AlbumList
        dragDropId={"box"}
        isBoxSetList={true}
        boxSetAlbumId={boxSet.uuid}
        albums={[boxSet.boxSetAlbums.map(boxSetAlbum => boxSetAlbum.album)]}
        isPublic={isPublic}
        sortOption={FilterSortOption.ARTIST}
        canMoveAlbums={true}
        canDropAlbums={true}
        canLoadMoreAlbums={false}
        noMoreAlbums={true}
        setNeedMoreAlbums={() => {
          //
        }}
        onAlbumSelected={onAlbumSelected}
        setIsLoading={setIsLoading}
        onFilterAlbums={() => {
          //
        }}
        onImportFromDiscogs={() => {
          //
        }}
        onImportFromExcel={() => {
          //
        }}
        onChangeCollection={() => {
          //
        }}
        onEditProfile={() => {
          //
        }}
        onPlayFromYouTube={() => {
          //
        }}
        onAlbumDeletedFromBoxSet={onDeleteFromBoxSet}
        scrollToTopTrigger={false}
        isForLayout={false}
      />
      {(!boxSet.boxSetAlbums || boxSet.boxSetAlbums.length == 0) && (
        <ContentUnavailableNotice
          isLoading={false}
          items={[]}
          emptyIcon={ListAltRounded}
          emptyTitle={strings.box_set_no_albums_title}
          emptyDescription={strings.box_set_no_albums_description}
          searchText={""}
        />
      )}
    </Card>
  );
};

export interface IBoxSetAlbumListProps {
  isPublic: boolean;
  className?: string;
  boxSet: IAlbum;
  onDeleteFromBoxSet: (newBoxSet: IAlbum) => void;
  onAlbumSelected: (album: IAlbum) => void;
  setIsLoading: (isLoading: boolean) => void;
}
