import React, { FunctionComponent, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { Divider } from "@mui/material";
import { RightDetailListItem } from "../../RightDetailListItem";
import { strings } from "../../../localization/LocalizedStrings";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { ModifyGenericAttributeDialog } from "../../attributes/ModifyGenericAttributeDialog";
import { AlertDialog } from "../../AlertDialog";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns/AdapterDateFns";

export const AlbumYearListSection: FunctionComponent<IAlbumYearListSectionProps> = ({
  isPublic,
  album,
  onAttributeUpdated
}) => {
  const [isLoadingOriginalYear, setIsLoadingOriginalYear] = useState(false);
  const [isOriginalYearPickerOpen, setIsOriginalYearPickerOpen] = useState(false);
  const [isLoadingReleaseYear, setIsLoadingReleaseYear] = useState(false);
  const [isReleaseYearPickerOpen, setIsReleaseYearPickerOpen] = useState(false);
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [isDialogOpenCountry, setIsDialogOpenCountry] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const { updateAlbum } = useUpdateAlbum();

  const updateOriginalYear = async (newValue: string | null) => {
    setIsLoadingOriginalYear(true);
    const { status, body } = await updateAlbum({ uuid: album.uuid, originalYear: newValue });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.originalYear = newValue ?? undefined;
      onAttributeUpdated(album);
    }
    setIsLoadingOriginalYear(false);
  };

  const updateReleaseYear = async (newValue: string | null) => {
    setIsLoadingReleaseYear(true);
    const { status, body } = await updateAlbum({ uuid: album.uuid, releaseYear: newValue });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.releaseYear = newValue ?? undefined;
      onAttributeUpdated(album);
    }
    setIsLoadingReleaseYear(false);
  };

  const updateCountry = async (newValue: string | null) => {
    setIsLoadingCountry(true);
    const { status, body } = await updateAlbum({ uuid: album.uuid, country: newValue });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.country = newValue ?? undefined;
      onAttributeUpdated(album);
    }
    setIsLoadingCountry(false);
  };

  return (
    <div>
      <LocalizationProvider
        localeText={
          strings.datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
        }
        dateAdapter={DateAdapter}
        adapterLocale={strings.fnsLocale}
      >
        <MobileDatePicker
          slotProps={{
            actionBar: {
              actions: ["clear", "cancel", "accept"]
            }
          }}
          views={["year"]}
          format={"yyyy"}
          minDate={new Date(strings.minDate)}
          value={album.originalYear ? new Date(`${album.originalYear}-06`) : null}
          onAccept={async date => {
            const originalYear = date == null ? null : (date as unknown as Date).getFullYear();
            await updateOriginalYear(originalYear ? String(originalYear) : null);
          }}
          onClose={() => setIsOriginalYearPickerOpen(false)}
          open={isOriginalYearPickerOpen}
          slots={{
            field: () => (
              <RightDetailListItem
                disabled={isPublic}
                title={strings.original_year_title}
                detail={album.originalYear ?? strings.unknown}
                onClick={() => setIsOriginalYearPickerOpen(true)}
                isLoading={isLoadingOriginalYear}
              />
            )
          }}
        />
      </LocalizationProvider>
      <Divider variant={"inset"} />
      <LocalizationProvider
        localeText={
          strings.datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
        }
        dateAdapter={DateAdapter}
        adapterLocale={strings.fnsLocale}
      >
        <MobileDatePicker
          slotProps={{
            actionBar: {
              actions: ["clear", "cancel", "accept"]
            }
          }}
          views={["year"]}
          format={"yyyy"}
          minDate={new Date(strings.minDate)}
          value={album.releaseYear ? new Date(`${album.releaseYear}-06`) : null}
          onAccept={async date => {
            const releaseYear = date == null ? null : (date as unknown as Date).getFullYear();
            await updateReleaseYear(releaseYear ? String(releaseYear) : null);
          }}
          onClose={() => setIsReleaseYearPickerOpen(false)}
          open={isReleaseYearPickerOpen}
          slots={{
            field: () => (
              <RightDetailListItem
                disabled={isPublic}
                title={strings.release_year_title}
                detail={album.releaseYear ?? strings.unknown}
                onClick={() => setIsReleaseYearPickerOpen(true)}
                isLoading={isLoadingReleaseYear}
              />
            )
          }}
        />
      </LocalizationProvider>
      <Divider variant={"inset"} />
      <RightDetailListItem
        disabled={isPublic}
        title={strings.country_title}
        detail={album.country && album.country != "" ? album.country : strings.unknown}
        isLoading={isLoadingCountry}
        onClick={() => setIsDialogOpenCountry(true)}
      />
      <ModifyGenericAttributeDialog
        title={strings.country_title}
        description={strings.country_message}
        placeholder={strings.country_placeholder}
        missingText={strings.field_missing}
        canClear={true}
        open={isDialogOpenCountry}
        setOpen={setIsDialogOpenCountry}
        isNumber={false}
        value={album.country}
        onChange={async newValue => {
          await updateCountry(newValue);
        }}
        onClearValue={async () => {
          await updateCountry(null);
        }}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </div>
  );
};

export interface IAlbumYearListSectionProps {
  isPublic: boolean;
  album: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
}
