import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grow,
  Toolbar,
  useTheme
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useSignup } from "../../hooks/users";
import { strings } from "../../localization/LocalizedStrings";
import { TextField } from "../TextField";
import { AlertDialog } from "../AlertDialog";
import { StatusCodes } from "http-status-codes";
import { useCheckEmail } from "../../hooks/checkEmail";
import { useTimer } from "react-timer-hook";
import { useCheckPassword } from "../../hooks/checkPassword";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useRecoverPassword } from "../../hooks/users/useRecoverPassword";
import { ContainedButton } from "../ContainedButton";
import { AcceptanceText } from "../AcceptanceText";
import { ToolbarButton } from "../toolbar/ToolbarButton";
import { CloseRounded, HelpOutlineRounded } from "@mui/icons-material";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../services/Firebase";

export const UserDataFormDialog: FunctionComponent<IUserDataFormDialogProps> = ({
  open,
  setOpen,
  callback,
  onClose,
  title,
  subtitle,
  description,
  behavior
}) => {
  const theme = useTheme();
  const analytics = Firebase.shared.getAnalytics();
  const { signup } = useSignup();
  const { recoverPassword } = useRecoverPassword();
  const checkEmail = useCheckEmail();
  const checkPassword = useCheckPassword();
  const [isEmailUsed, setIsEmailUsed] = useState(false);
  const [isPasswordWeak, setIsPasswordWeak] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleClose = () => {
    if (isLoading) return;
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(strings.email_invalid)
      .required(strings.email_invalid)
      .test("isUsed", strings.email_error_duplicated, () => !isEmailUsed),
    password: Yup.string()
      .required(strings.password_missing)
      .test("isPasswordWeak", strings.password_weak, () => !isPasswordWeak),
    repeatPassword: Yup.string()
      .required(strings.password_repeat_missing)
      .oneOf([Yup.ref("password"), ""], strings.password_mismatch)
  });
  const initialValues: IFormProps = {
    email: "",
    password: "",
    repeatPassword: ""
  };
  const performSignup = async (email: string, password: string) => {
    setIsLoading(true);
    const { body, status } = await signup({
      email: email,
      password: password
    });
    setIsLoading(false);
    if (StatusCodesHelper.isSuccessful(status)) {
      logEvent(analytics, "new_user_manual");
      if (callback != undefined) {
        callback(email, password);
      }
      handleClose();
    } else {
      if (status == StatusCodes.BAD_REQUEST) {
        if (body.field == "email") {
          setAlertMessage(strings.email_error_invalid);
        } else {
          setAlertMessage(body.message);
        }
      } else if (status == 520) {
        setAlertMessage(strings.email_error_duplicated);
      } else {
        setAlertMessage(body.message);
      }
      setIsAlertOpen(true);
    }
  };
  const performRecoverPassword = async (email: string, password: string) => {
    setIsLoading(true);
    const { body, status } = await recoverPassword({
      email: email
    });
    setIsLoading(false);
    if (StatusCodesHelper.isSuccessful(status)) {
      logEvent(analytics, "reset_password");
      if (callback != undefined) {
        callback(email, password);
      }
      handleClose();
    } else {
      if (status == StatusCodes.NOT_FOUND) {
        setAlertMessage(strings.recover_password_email_not_found);
      } else {
        setAlertMessage(body.message);
      }
      setIsAlertOpen(true);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async ({ email, password }) => {
      if (!acceptedTerms) return;
      if (behavior == "signup") {
        await performSignup(email, password);
      } else {
        await performRecoverPassword(email, password);
      }
    },
    validationSchema: validationSchema
  });
  const expiryTimestamp = () => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 1);
    return date;
  };
  const { pause: stopEmailTimer, restart: restartEmailTimer } = useTimer({
    expiryTimestamp: expiryTimestamp(),
    onExpire: () => {
      // Check duplicated email only on sign up
      if (behavior == "signup") {
        checkEmail(formik.values.email).then(isEmailUsed => {
          setIsEmailUsed(isEmailUsed);
        });
      }
    }
  });
  const { pause: stopPasswordTimer, restart: restartPasswordTimer } = useTimer({
    expiryTimestamp: expiryTimestamp(),
    onExpire: () => {
      checkPassword(formik.values.password).then(result => {
        setIsPasswordWeak([0, 1].includes(result?.id ?? -1));
      });
    }
  });
  useEffect(() => {
    stopEmailTimer();
    stopPasswordTimer();
  }, []);
  useEffect(() => {
    setIsEmailUsed(false);
    if (formik.values.email.length < 5 || formik.errors.email != undefined) {
      stopEmailTimer();
      return;
    }
    restartEmailTimer(expiryTimestamp());
  }, [formik.values.email]);
  useEffect(() => {
    setIsPasswordWeak(false);
    if (formik.values.password == "" || formik.errors.password != undefined) {
      stopPasswordTimer();
      return;
    }
    restartPasswordTimer(expiryTimestamp());
  }, [formik.values.password]);
  useEffect(() => {
    if (!open) return;
    setAcceptedTerms(false);
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <Toolbar>
          <h3>{title}</h3>
          <Box flexGrow={1} />
          <ToolbarButton
            href={`${window.location.protocol}//${
              window.location.host
            }${RoutesBuilder.documentation.home()}${
              behavior == "signup"
                ? RoutesBuilder.documentation.users.signUp()
                : RoutesBuilder.documentation.users.recoverPassword()
            }`}
            target={"_blank"}
            tooltip={strings.help}
            icon={HelpOutlineRounded}
          />
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <p className={styles.subtitle}>{subtitle}</p>
        <p className={styles.description} style={{ color: theme.palette.primary.subtitle }}>
          {description}
        </p>
        <DialogContent sx={{ paddingTop: 2, paddingBottom: 0 }}>
          <div className={styles.textField}>
            <TextField
              id="email"
              name="email"
              type="email"
              size="small"
              placeholder={strings.signup_email}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.textField}>
            <TextField
              id="password"
              name="password"
              type="password"
              size="small"
              placeholder={strings.login_password}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.textField}>
            <TextField
              id="repeatPassword"
              name="repeatPassword"
              type="password"
              size="small"
              placeholder={strings.signup_repeat_password_placeholder}
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
            />
          </div>
          <FormControlLabel
            sx={{ width: "100%" }}
            control={
              <Checkbox
                checked={acceptedTerms}
                onChange={() => {
                  setAcceptedTerms(state => !state);
                }}
              />
            }
            label={<AcceptanceText />}
          />
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </FormikProvider>
  );
};

interface IFormProps {
  email: string;
  password: string;
  repeatPassword: string;
}

export type UserDataFormBehavior = "signup" | "recoverPassword";

export interface IUserDataFormDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  callback?: (email: string, password: string) => void;
  onClose?: () => void;
  title: string;
  subtitle: string;
  description: string;
  behavior: UserDataFormBehavior;
}
