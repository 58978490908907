import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  Divider,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  useTheme
} from "@mui/material";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import { FilterElement, FilterType } from "../../../models/FilterElement";
import { strings } from "../../../localization/LocalizedStrings";
import { BooleanFilterComponent } from "./BooleanFilterElementComponent";
import styles from "./styles.module.scss";
import { ArtistsDesktop } from "../../attributes/artists/ArtistsDesktop";
import { AlbumFormatsDesktop } from "../../attributes/albumFormats/AlbumFormatsDesktop";
import { AlbumTypesDesktop } from "../../attributes/albumTypes/AlbumTypesDesktop";
import { StoresDesktop } from "../../attributes/stores/StoresDesktop";
import { LabelsDesktop } from "../../attributes/labels/LabelsDesktop";
import { ImpactsDesktop } from "../../attributes/impacts/ImpactsDesktop";
import { SleeveGradesDesktop } from "../../attributes/sleeveGrades/SleeveGradesDesktop";
import { MediaGradesDesktop } from "../../attributes/mediaGrades/MediaGradesDesktop";
import { SellValuesDesktop } from "../../attributes/sellValues/SellValuesDesktop";
import { ModifyGenericAttributeDialog } from "../../attributes/ModifyGenericAttributeDialog";
import { capitalizeFirstLetter } from "../../../models/StringFormat";
import { ImpactHelper } from "../../../models/ImpactHelper";
import { AlbumGradeHelper } from "../../../models/AlbumGradeHelper";
import { GenericFilterComponent } from "./GenericFilterElementComponent";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { useIsMobile } from "../../../hooks/isMobile";
import { useCurrencyFormatter } from "../../../hooks/currencyFormatter";
import { useCurrentPublicCollection } from "../../../hooks/collections/useCollectionManager";
import { useUserProfile } from "../../../hooks/session";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { useContextMenu } from "../../../hooks/contextMenu";
import { TagsDesktop } from "../../attributes/tags/TagsDesktop";
import { IIsNegatedAlbumsParameters } from "../../../hooks/albums";

export const AlbumsFilter: FunctionComponent<IAlbumsFilterProps> = ({
  isPublic,
  initialFilterElements,
  initialIsNegated,
  onFilterChanged
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  const currencyFormatter = useCurrencyFormatter();
  const [currentUserProfile] = useUserProfile.useState();
  const [leftChecked, setLeftChecked] = useState<number[]>([]);
  const [rightChecked, setRightChecked] = useState<number[]>([]);
  const [left, setLeft] = useState([
    new FilterElement(strings.filter_artist_title, FilterType.ARTIST, true, true),
    new FilterElement(strings.filter_album_format_title, FilterType.ALBUM_FORMAT, true, true),
    new FilterElement(strings.filter_sleeve_grade_title, FilterType.SLEEVE_GRADE, true, true),
    new FilterElement(strings.filter_media_grade_title, FilterType.MEDIA_GRADE, true, true),
    new FilterElement(strings.filter_tag_title, FilterType.TAG, true, true),
    new FilterElement(
      strings.filter_sell_status_title,
      FilterType.SELL_STATUS,
      true,
      true,
      undefined,
      undefined,
      strings.filter_sell_status_title
    ),
    new FilterElement(strings.filter_label_title, FilterType.LABEL, true, true),
    new FilterElement(strings.filter_box_set_title, FilterType.BOX_SET, true, false, "0"),
    new FilterElement(strings.filter_clean_title, FilterType.IS_CLEAN, true, false, "0"),
    new FilterElement(strings.filter_insert_title, FilterType.HAS_INSERT, true, false, "0"),
    new FilterElement(strings.filter_lyrics_title, FilterType.HAS_LYRICS, true, false, "0"),
    new FilterElement(strings.filter_gatefold_title, FilterType.IS_GATEFOLD, true, false, "0"),
    new FilterElement(strings.filter_album_type_title, FilterType.ALBUM_TYPE, true, true),
    new FilterElement(strings.filter_store_title, FilterType.STORE, true, true),
    new FilterElement(strings.filter_impact_title, FilterType.IMPACT, true, true),
    new FilterElement(strings.filter_sell_value_title, FilterType.SELL_VALUE, true, true),
    new FilterElement(
      strings.filter_country_title,
      FilterType.COUNTRY,
      true,
      true,
      undefined,
      undefined,
      strings.country_placeholder
    ),
    new FilterElement(
      strings.filter_original_year_title,
      FilterType.ORIGINAL_YEAR,
      true,
      true,
      undefined,
      undefined,
      strings.original_year_placeholder
    ),
    new FilterElement(
      strings.filter_release_year_title,
      FilterType.RELEASE_YEAR,
      true,
      true,
      undefined,
      undefined,
      strings.release_year_placeholder
    )
  ]);
  const [filterElements] = useState<FilterElement[]>(initialFilterElements);
  const [isNegated, setIsNegated] = useState<IIsNegatedAlbumsParameters>(initialIsNegated);
  const [right, setRight] = useState<FilterElement[]>(initialFilterElements);

  const [selectedFilterElement, setSelectedFilterElement] = useState<FilterElement>();
  const [isAlbumFormatsDialogOpen, setIsAlbumFormatsDialogOpen] = useState(false);
  const [isAlbumTypesDialogOpen, setIsAlbumTypesDialogOpen] = useState(false);
  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState(false);
  const [isGenericAttributeDialogOpen, setIsGenericAttributeDialogOpen] = useState(false);
  const [isArtistsDialogOpen, setIsArtistsDialogOpen] = useState(false);
  const [isStoresDialogOpen, setIsStoresDialogOpen] = useState(false);
  const [isLabelsDialogOpen, setIsLabelsDialogOpen] = useState(false);
  const [isImpactsDialogOpen, setIsImpactsDialogOpen] = useState(false);
  const [isSleeveGradesDialogOpen, setIsSleeveGradesDialogOpen] = useState(false);
  const [isMediaGradesDialogOpen, setIsMediaGradesDialogOpen] = useState(false);
  const [isSellValuesDialogOpen, setIsSellValuesDialogOpen] = useState(false);

  const initializeCatering = () => {
    if (!isPublic || !currentPublicCollection) return;
    let newLeft = left;
    if (currentPublicCollection.bannedFields?.includes("label")) {
      newLeft = newLeft.filter(element => element.filterType != FilterType.LABEL);
    }
    if (currentPublicCollection.bannedFields?.includes("isGatefold")) {
      newLeft = newLeft.filter(element => element.filterType != FilterType.IS_GATEFOLD);
    }
    if (currentPublicCollection.bannedFields?.includes("isClean")) {
      newLeft = newLeft.filter(element => element.filterType != FilterType.IS_CLEAN);
    }
    if (currentPublicCollection.bannedFields?.includes("store")) {
      newLeft = newLeft.filter(element => element.filterType != FilterType.STORE);
    }
    if (currentPublicCollection.bannedFields?.includes("impact")) {
      newLeft = newLeft.filter(element => element.filterType != FilterType.IMPACT);
    }
    if (currentPublicCollection.bannedFields?.includes("sellValue")) {
      newLeft = newLeft.filter(element => element.filterType != FilterType.SELL_VALUE);
    }
    if (currentPublicCollection.bannedFields?.includes("tags")) {
      newLeft = newLeft.filter(element => element.filterType != FilterType.TAG);
    }
    setLeft(newLeft);
  };

  useEffect(() => {
    onFilterChanged(right, isNegated);
  }, [right, isNegated, selectedFilterElement]);

  useEffect(() => {
    initializeCatering();
    setRight(filterElements);
  }, []);

  const handleToggle = (index: number, isLeft: boolean) => () => {
    const currentIndex = isLeft ? leftChecked.indexOf(index) : rightChecked.indexOf(index);
    const newChecked = isLeft ? [...leftChecked] : [...rightChecked];
    if (currentIndex === -1) {
      newChecked.push(index);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    isLeft ? setLeftChecked(newChecked) : setRightChecked(newChecked);
  };

  const handleCheckedRight = () => {
    leftChecked.forEach(index => {
      copyFilterElementFromCatering(left[index], left[index].elementId);
    });
    setLeftChecked([]);
  };

  const handleCheckedLeft = () => {
    setRight(right => right.filter((filterElement, i) => !rightChecked.includes(i)));
    setRightChecked([]);
  };

  const copyFilterElementFromCatering = (
    filterElement: FilterElement,
    elementId: string | undefined,
    textValue?: string
  ) => {
    const newFilterElement = FilterElement.copy(filterElement);
    newFilterElement.elementId = elementId;
    newFilterElement.textValue = textValue;
    setRight(right => [...right, newFilterElement]);
  };

  const genericFilterComponent = (
    filterElement: FilterElement,
    isNegated: boolean,
    onClick: (event: React.MouseEvent) => void
  ) => (
    <GenericFilterComponent
      filterElement={filterElement}
      isNegated={isNegated}
      onClick={event => {
        setSelectedFilterElement(filterElement);
        onClick(event);
      }}
      onNegatedChange={value => {
        switch (filterElement.filterType) {
          case FilterType.IS_GATEFOLD:
          case FilterType.HAS_INSERT:
          case FilterType.HAS_LYRICS:
          case FilterType.IS_CLEAN:
          case FilterType.BOX_SET:
            break;
          case FilterType.STORE:
            setIsNegated(isNegated => ({ ...isNegated, storeId: value }));
            break;
          case FilterType.LABEL:
            setIsNegated(isNegated => ({ ...isNegated, labelId: value }));
            break;
          case FilterType.IMPACT:
            setIsNegated(isNegated => ({ ...isNegated, impactId: value }));
            break;
          case FilterType.SLEEVE_GRADE:
            setIsNegated(isNegated => ({ ...isNegated, sleeveGradeId: value }));
            break;
          case FilterType.MEDIA_GRADE:
            setIsNegated(isNegated => ({ ...isNegated, mediaGradeId: value }));
            break;
          case FilterType.SELL_VALUE:
            setIsNegated(isNegated => ({ ...isNegated, sellValueId: value }));
            break;
          case FilterType.ARTIST:
            setIsNegated(isNegated => ({ ...isNegated, artistId: value }));
            break;
          case FilterType.ALBUM_TYPE:
            setIsNegated(isNegated => ({ ...isNegated, albumTypeId: value }));
            break;
          case FilterType.ALBUM_FORMAT:
            setIsNegated(isNegated => ({ ...isNegated, albumFormatId: value }));
            break;
          case FilterType.COUNTRY:
            setIsNegated(isNegated => ({ ...isNegated, country: value }));
            break;
          case FilterType.RELEASE_YEAR:
            setIsNegated(isNegated => ({ ...isNegated, releaseYear: value }));
            break;
          case FilterType.ORIGINAL_YEAR:
            setIsNegated(isNegated => ({ ...isNegated, originalYear: value }));
            break;
          case FilterType.SELL_STATUS:
            setIsNegated(isNegated => ({ ...isNegated, sellStatus: value }));
            break;
          case FilterType.TAG:
            setIsNegated(isNegated => ({ ...isNegated, tagId: value }));
            break;
        }
      }}
    />
  );

  const filterElementComponent = (filterElement: FilterElement) => {
    switch (filterElement.filterType) {
      case FilterType.IS_GATEFOLD:
      case FilterType.HAS_INSERT:
      case FilterType.HAS_LYRICS:
      case FilterType.IS_CLEAN:
      case FilterType.BOX_SET:
        return (
          <BooleanFilterComponent
            value={filterElement.isFromCatering ? undefined : filterElement.elementId == "1"}
            onChange={newValue => {
              if (filterElement.isFromCatering) {
                copyFilterElementFromCatering(filterElement, newValue ? "1" : "0");
              } else {
                setRight(right =>
                  right.map(rightFilterElement => {
                    if (rightFilterElement.filterType == filterElement.filterType) {
                      return {
                        ...rightFilterElement,
                        elementId: newValue ? "1" : "0"
                      };
                    } else {
                      return rightFilterElement;
                    }
                  })
                );
              }
            }}
          />
        );
      case FilterType.ARTIST:
        return genericFilterComponent(filterElement, isNegated.artistId, () =>
          setIsArtistsDialogOpen(true)
        );
      case FilterType.ALBUM_FORMAT:
        return genericFilterComponent(filterElement, isNegated.albumFormatId, () =>
          setIsAlbumFormatsDialogOpen(true)
        );
      case FilterType.ALBUM_TYPE:
        return genericFilterComponent(filterElement, isNegated.albumTypeId, () =>
          setIsAlbumTypesDialogOpen(true)
        );
      case FilterType.TAG:
        return genericFilterComponent(filterElement, isNegated.tagId, () =>
          setIsTagsDialogOpen(true)
        );
      case FilterType.STORE:
        return genericFilterComponent(filterElement, isNegated.storeId, () =>
          setIsStoresDialogOpen(true)
        );
      case FilterType.LABEL:
        return genericFilterComponent(filterElement, isNegated.labelId, () =>
          setIsLabelsDialogOpen(true)
        );
      case FilterType.IMPACT:
        return genericFilterComponent(filterElement, isNegated.impactId, () =>
          setIsImpactsDialogOpen(true)
        );
      case FilterType.SLEEVE_GRADE:
        return genericFilterComponent(filterElement, isNegated.sleeveGradeId, () =>
          setIsSleeveGradesDialogOpen(true)
        );
      case FilterType.MEDIA_GRADE:
        return genericFilterComponent(filterElement, isNegated.mediaGradeId, () =>
          setIsMediaGradesDialogOpen(true)
        );
      case FilterType.SELL_VALUE:
        return genericFilterComponent(filterElement, isNegated.sellValueId, () =>
          setIsSellValuesDialogOpen(true)
        );
      case FilterType.COUNTRY:
        return genericFilterComponent(filterElement, isNegated.country, () =>
          setIsGenericAttributeDialogOpen(true)
        );
      case FilterType.ORIGINAL_YEAR:
        return genericFilterComponent(filterElement, isNegated.originalYear, () =>
          setIsGenericAttributeDialogOpen(true)
        );
      case FilterType.RELEASE_YEAR:
        return genericFilterComponent(filterElement, isNegated.releaseYear, () =>
          setIsGenericAttributeDialogOpen(true)
        );
      case FilterType.SELL_STATUS:
        return genericFilterComponent(filterElement, isNegated.sellStatus, event =>
          sellStatusHandleContextMenu(event)
        );
      case FilterType.SEARCH_TEXT:
        return genericFilterComponent(filterElement, false, () =>
          setIsGenericAttributeDialogOpen(true)
        );
    }
  };

  const customList = (items: readonly FilterElement[], isLeft: boolean) => (
    <Paper
      sx={{
        minWidth: isMobile ? 110 : undefined,
        flexGrow: 1,
        height: "100%",
        overflow: "auto",
        borderRadius: 4
      }}
    >
      <div
        className={styles.boxHeader}
        style={{
          backgroundColor: theme.palette.colors.filterHeaderBackground
        }}
      >
        <p>{isLeft ? strings.filter_left_title : strings.filter_right_title}</p>
      </div>
      <List dense component="div" role="list">
        {items.map((filterElement, index) => {
          const labelId = `transfer-list-item-${index}-label`;

          return (
            <div key={labelId}>
              <ListItem className={styles.listItem} role="listitem">
                <Checkbox
                  onClick={handleToggle(index, isLeft)}
                  checked={
                    isLeft ? leftChecked.indexOf(index) !== -1 : rightChecked.indexOf(index) !== -1
                  }
                  tabIndex={-1}
                  size={"small"}
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
                <div className={styles.filterComponent}>
                  <p
                    id={labelId}
                    className={styles.filterElementTitle}
                    style={{ color: theme.palette.primary.main }}
                  >
                    {filterElement.title}
                  </p>
                  {filterElementComponent(filterElement)}
                </div>
              </ListItem>
              <Divider variant={"inset"} />
            </div>
          );
        })}
      </List>
    </Paper>
  );

  const updateSelectedFilterElement = (elementId?: string, textValue?: string) => {
    if (selectedFilterElement) {
      if (selectedFilterElement.isFromCatering) {
        copyFilterElementFromCatering(selectedFilterElement, elementId, textValue);
      } else {
        setRight(right =>
          right.map(filterElement => {
            if (filterElement == selectedFilterElement) {
              return {
                ...filterElement,
                elementId: elementId,
                textValue: textValue
              };
            } else {
              return filterElement;
            }
          })
        );
      }
    }
  };

  const {
    menuProps: sellStatusMenuProps,
    handleContextMenu: sellStatusHandleContextMenu,
    handleMenuClose: sellStatusHandleMenuClose
  } = useContextMenu();

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", display: "flex" }}
    >
      <Grid item md sx={{ height: "100%", flexGrow: 1 }}>
        {customList(left, true)}
      </Grid>
      <Grid item xs sx={{ maxWidth: 64, width: 64, flexGrow: "0 !important", margin: "10px" }}>
        <Grid container direction="column" alignItems="center">
          <Tooltip title={strings.add_to_filter} arrow disableInteractive>
            <IconButton
              sx={{
                color:
                  leftChecked.length === 0
                    ? theme.palette.colors?.disabled
                    : theme.palette.primary.main,
                backgroundColor:
                  leftChecked.length === 0
                    ? theme.palette.colors?.appBar
                    : theme.palette.colors?.buttonBackground,
                marginBottom: 2
              }}
              size="small"
              onClick={handleCheckedRight}
              aria-label="move selected right"
            >
              <ResponsiveIcon icon={ArrowForwardRounded} />
            </IconButton>
          </Tooltip>
          <Tooltip title={strings.remove_from_filter} arrow disableInteractive>
            <IconButton
              sx={{
                color:
                  rightChecked.length === 0
                    ? theme.palette.colors?.disabled
                    : theme.palette.primary.main,
                backgroundColor:
                  rightChecked.length === 0
                    ? theme.palette.colors?.appBar
                    : theme.palette.colors?.buttonBackground,
                marginTop: 2
              }}
              size="small"
              onClick={handleCheckedLeft}
              aria-label="move selected left"
            >
              <ResponsiveIcon icon={ArrowBackRounded} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item md sx={{ height: "100%", flexGrow: 1 }}>
        {customList(right, false)}
      </Grid>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isArtistsDialogOpen}
        onClose={() => setIsArtistsDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <ArtistsDesktop
            handleClose={() => {
              setIsArtistsDialogOpen(false);
            }}
            isPublic={isPublic}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={selectedFilterElement?.elementId}
            onArtistSelected={async artist => {
              setIsArtistsDialogOpen(false);
              updateSelectedFilterElement(artist.uuid, artist.name);
            }}
            onClearValue={() => {
              setIsArtistsDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isAlbumFormatsDialogOpen}
        onClose={() => setIsAlbumFormatsDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <AlbumFormatsDesktop
            handleClose={() => {
              setIsAlbumFormatsDialogOpen(false);
            }}
            isPublic={isPublic}
            columnWidth={isMobile ? 6 : 3}
            preselectedId={selectedFilterElement?.elementId}
            onAlbumFormatSelected={albumFormat => {
              setIsAlbumFormatsDialogOpen(false);
              updateSelectedFilterElement(albumFormat.uuid, albumFormat.name);
            }}
            onClearValue={() => {
              setIsAlbumFormatsDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isAlbumTypesDialogOpen}
        onClose={() => setIsAlbumTypesDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <AlbumTypesDesktop
            handleClose={() => {
              setIsAlbumTypesDialogOpen(false);
            }}
            isPublic={isPublic}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={selectedFilterElement?.elementId}
            onAlbumTypeSelected={albumType => {
              setIsAlbumTypesDialogOpen(false);
              updateSelectedFilterElement(albumType.uuid, albumType.name);
            }}
            onClearValue={() => {
              setIsAlbumTypesDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isTagsDialogOpen}
        onClose={() => setIsTagsDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <TagsDesktop
            handleClose={() => {
              setIsTagsDialogOpen(false);
            }}
            isPublic={isPublic}
            columnWidth={isMobile ? 6 : 4}
            preselectedIds={
              selectedFilterElement?.elementId ? [selectedFilterElement.elementId] : undefined
            }
            onTagSelected={tag => {
              setIsTagsDialogOpen(false);
              updateSelectedFilterElement(tag.uuid, tag.name);
            }}
            onClearValue={() => {
              setIsTagsDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isStoresDialogOpen}
        onClose={() => setIsStoresDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <StoresDesktop
            handleClose={() => {
              setIsStoresDialogOpen(false);
            }}
            isPublic={isPublic}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={selectedFilterElement?.elementId}
            onStoreSelected={async store => {
              setIsStoresDialogOpen(false);
              updateSelectedFilterElement(store.uuid, store.name);
            }}
            onClearValue={async () => {
              setIsStoresDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isLabelsDialogOpen}
        onClose={() => setIsLabelsDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <LabelsDesktop
            handleClose={() => {
              setIsLabelsDialogOpen(false);
            }}
            isPublic={isPublic}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={selectedFilterElement?.elementId}
            onLabelSelected={async label => {
              setIsLabelsDialogOpen(false);
              updateSelectedFilterElement(label.uuid, label.name);
            }}
            onClearValue={async () => {
              setIsLabelsDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isImpactsDialogOpen}
        onClose={() => setIsImpactsDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <ImpactsDesktop
            handleClose={() => {
              setIsImpactsDialogOpen(false);
            }}
            columnWidth={isMobile ? 6 : 3}
            preselectedId={
              selectedFilterElement?.elementId ? +selectedFilterElement.elementId : undefined
            }
            onImpactSelected={async impact => {
              setIsImpactsDialogOpen(false);
              updateSelectedFilterElement(
                `${impact.id}`,
                (currentUserProfile?.shouldShowImpactDescriptions ? `${impact.description} ` : "") +
                  ImpactHelper.starsString(impact)
              );
            }}
            onClearValue={() => {
              setIsImpactsDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isSleeveGradesDialogOpen}
        onClose={() => setIsSleeveGradesDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <SleeveGradesDesktop
            handleClose={() => {
              setIsSleeveGradesDialogOpen(false);
            }}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={
              selectedFilterElement?.elementId ? +selectedFilterElement.elementId : undefined
            }
            onSleeveGradeSelected={async sleeveGrade => {
              setIsSleeveGradesDialogOpen(false);
              updateSelectedFilterElement(
                `${sleeveGrade.id}`,
                AlbumGradeHelper.getSleeveGradeDescription(sleeveGrade)
              );
            }}
            onClearValue={async () => {
              setIsSleeveGradesDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isMediaGradesDialogOpen}
        onClose={() => setIsMediaGradesDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <MediaGradesDesktop
            handleClose={() => {
              setIsMediaGradesDialogOpen(false);
            }}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={
              selectedFilterElement?.elementId ? +selectedFilterElement.elementId : undefined
            }
            onMediaGradeSelected={async mediaGrade => {
              setIsMediaGradesDialogOpen(false);
              updateSelectedFilterElement(
                `${mediaGrade.id}`,
                AlbumGradeHelper.getMediaGradeDescription(mediaGrade)
              );
            }}
            onClearValue={async () => {
              setIsMediaGradesDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isSellValuesDialogOpen}
        onClose={() => setIsSellValuesDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <SellValuesDesktop
            handleClose={() => {
              setIsSellValuesDialogOpen(false);
            }}
            isPublic={isPublic}
            columnWidth={isMobile ? 12 : 4}
            preselectedId={selectedFilterElement?.elementId}
            onSellValueSelected={async sellValue => {
              setIsSellValuesDialogOpen(false);
              updateSelectedFilterElement(
                sellValue.uuid,
                `(${sellValue.group}) ${currencyFormatter.format(
                  sellValue.dollars,
                  currentUserProfile,
                  false,
                  2,
                  "USD"
                )}`
              );
            }}
            onClearValue={async () => {
              setIsSellValuesDialogOpen(false);
              updateSelectedFilterElement(undefined, undefined);
            }}
          />
        </div>
      </Dialog>
      <ModifyGenericAttributeDialog
        title={capitalizeFirstLetter(selectedFilterElement?.title.toLowerCase() ?? "")}
        description={strings.generic_attribute_filter_description}
        placeholder={selectedFilterElement?.placeholder ?? ""}
        value={selectedFilterElement?.textValue}
        missingText={strings.field_missing}
        canClear={false}
        open={isGenericAttributeDialogOpen}
        setOpen={setIsGenericAttributeDialogOpen}
        isNumber={false}
        onChange={newValue => {
          updateSelectedFilterElement(newValue, newValue);
        }}
      />
      <Menu {...sellStatusMenuProps}>
        {AlbumUtilities.getAllSellStatuses().map(sellStatus => (
          <MenuItem
            key={sellStatus}
            onClick={() => {
              sellStatusHandleMenuClose();
              updateSelectedFilterElement(
                sellStatus,
                AlbumUtilities.getSellStatusDescription(sellStatus)
              );
            }}
            dense
          >
            {AlbumUtilities.getSellStatusDescription(sellStatus)}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export interface IAlbumsFilterProps {
  isPublic: boolean;
  initialFilterElements: FilterElement[];
  initialIsNegated: IIsNegatedAlbumsParameters;
  onFilterChanged: (
    newFilterElements: FilterElement[],
    isNegated: IIsNegatedAlbumsParameters
  ) => void;
}
