import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Card,
  Collapse,
  Divider,
  Fade,
  LinearProgress,
  List,
  Stack,
  SvgIcon,
  useTheme
} from "@mui/material";
import { IStats } from "../../../hooks/stats/useCreateStats";
import { RightDetailListItem } from "../../RightDetailListItem";
import { strings } from "../../../localization/LocalizedStrings";
import { TimeInterval } from "../../../models/TimeInterval";
import { ArtistDialog } from "../../attributes/artists/ArtistDialog";
import { StatsGraphs } from "../StatsGraphs";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { DragIndicator, ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { LabelDialog } from "../../attributes/labels/LabelDialog";
import { useIsMobile } from "../../../hooks/isMobile";
import { useCurrencyFormatter } from "../../../hooks/currencyFormatter";
import { useUserProfile } from "../../../hooks/session";

export const StatsDetail: FunctionComponent<IStatsProps> = ({ stats, isLoading }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const currencyFormatter = useCurrencyFormatter();
  const [currentUserProfile] = useUserProfile.useState();
  const [isOpenArtistDialog, setIsOpenArtistDialog] = useState(false);
  const [isOpenLabelDialog, setIsOpenLabelDialog] = useState(false);
  const [isAlbumTypesExpanded, setIsAlbumTypesExpanded] = useState(false);
  const [isAlbumFormatsExpanded, setIsAlbumFormatsExpanded] = useState(false);
  const [isAlbumTagsExpanded, setIsAlbumTagsExpanded] = useState(false);
  useEffect(() => {
    setIsAlbumTypesExpanded(false);
    setIsAlbumFormatsExpanded(false);
    setIsAlbumTagsExpanded(false);
  }, [stats]);
  const statsList = (stats: IStats) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Card
        elevation={5}
        className={isMobile ? styles.itemCardMobile : styles.itemCard}
        key={"general_card"}
      >
        <RightDetailListItem title={strings.stats_general_title} key={"general_title"} boldTitle />
        <Divider />
        <RightDetailListItem
          title={strings.stats_album_total}
          detail={String(stats.totalAlbumCount)}
          key={"general_item_1"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_box_set_total}
          detail={String(stats.totalBoxSetCount)}
          key={"general_item_0"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_clean_total}
          detail={String(stats.cleanCount)}
          key={"general_item_2"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_gatefold_total}
          detail={String(stats.gatefoldCount)}
          key={"general_item_3"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_insert_total}
          detail={String(stats.insertCount)}
          key={"general_item_4"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_lyrics_total}
          detail={String(stats.lyricsCount)}
          key={"general_item_5"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_colored_total}
          detail={String(stats.coloredAlbumsCount)}
          key={"general_item_6"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_average_original_year}
          detail={String(Math.round(stats.averageOriginalYear))}
          key={"general_item_7"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_average_release_year}
          detail={String(Math.round(stats.averageReleaseYear))}
          key={"general_item_8"}
        />
      </Card>
      <Card
        elevation={5}
        className={isMobile ? styles.itemCardMobile : styles.itemCard}
        key={"length_card"}
      >
        <RightDetailListItem title={strings.album_length_title} key={"length_title"} boldTitle />
        <Divider />
        <RightDetailListItem
          title={strings.stats_discs_total}
          detail={String(stats.totalDiscs)}
          key={"length_item_1"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_length_total}
          detail={TimeInterval.toHHMMSS(stats.totalLength)}
          key={"length_item_2"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_average_album_length}
          detail={TimeInterval.toMMSS(Math.round(stats.albumAverageLength))}
          key={"length_item_3"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_average_disc_length}
          detail={
            stats.discAverageLength
              ? TimeInterval.toMMSS(Math.round(stats.discAverageLength))
              : strings.unknown
          }
          key={"length_item_4"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_average_track_length}
          detail={
            stats.trackAverageLength
              ? TimeInterval.toMMSS(Math.round(stats.trackAverageLength))
              : strings.unknown
          }
          key={"length_item_5"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_tracks_by_album}
          detail={String(Math.round(stats.tracksByAlbum))}
          key={"length_item_6"}
        />
      </Card>
      <Card
        elevation={5}
        className={isMobile ? styles.itemCardMobile : styles.itemCard}
        key={"artists_card"}
      >
        <RightDetailListItem
          title={strings.attribute_artists_title}
          key={"artists_title"}
          boldTitle
        />
        <Divider />
        <RightDetailListItem
          title={strings.stats_artist_total}
          detail={String(stats.artistCount)}
          key={"artists_item_1"}
        />
        <Divider variant={"inset"} />
        {stats.artist && (
          <RightDetailListItem
            title={strings.stats_artist_most_albums}
            detail={stats.artist.name}
            onClick={() => {
              setIsOpenArtistDialog(true);
            }}
            key={"artists_item_2"}
          />
        )}
      </Card>
      {stats.store && (
        <div key={"store_section"}>
          <Card
            elevation={5}
            className={isMobile ? styles.itemCardMobile : styles.itemCard}
            key={"store_card"}
          >
            <RightDetailListItem
              title={strings.attribute_stores_title}
              key={"store_title"}
              boldTitle
            />
            <Divider />
            <RightDetailListItem
              title={strings.stats_store_most_albums}
              detail={stats.store.name}
              key={"store_item_1"}
            />
          </Card>
        </div>
      )}
      {stats.label && (
        <div key={"label_section"}>
          <Card
            elevation={5}
            className={isMobile ? styles.itemCardMobile : styles.itemCard}
            key={"label_card"}
          >
            <RightDetailListItem
              title={strings.attribute_labels_title}
              key={"label_title"}
              boldTitle
            />
            <Divider />
            <RightDetailListItem
              title={strings.stats_label_most_albums}
              detail={stats.label.name}
              onClick={() => {
                setIsOpenLabelDialog(true);
              }}
              key={"label_item_1"}
            />
          </Card>
        </div>
      )}
      {(stats.albumTypeCounts?.length ?? 0) > 0 && (
        <div key={"album_type_section"}>
          <Card
            elevation={5}
            className={isMobile ? styles.itemCardMobile : styles.itemCard}
            key={"album_type_card"}
          >
            <RightDetailListItem
              title={strings.attribute_album_types_title}
              key={"album_type_title"}
              theNewIcon={isAlbumTypesExpanded ? ExpandLessRounded : ExpandMoreRounded}
              onClick={() => setIsAlbumTypesExpanded(state => !state)}
              boldTitle
            />
            <Divider />
            <Collapse in={isAlbumTypesExpanded} timeout={"auto"}>
              {stats.albumTypeCounts
                ?.sort((a, b) => b.count - a.count)
                .map((albumTypeCount, index) => (
                  <div key={`album_type_div_${index}`}>
                    <RightDetailListItem
                      title={albumTypeCount.grade.name}
                      detail={String(albumTypeCount.count)}
                      key={`album_type_item_${index}`}
                    />
                    {index < (stats.albumTypeCounts?.length ?? 0) - 1 && (
                      <Divider variant={"inset"} />
                    )}
                  </div>
                ))}
            </Collapse>
          </Card>
        </div>
      )}
      {(stats.albumFormatCounts?.length ?? 0) > 0 && (
        <div key={"album_format_section"}>
          <Card
            elevation={5}
            className={isMobile ? styles.itemCardMobile : styles.itemCard}
            key={"album_format_card"}
          >
            <RightDetailListItem
              title={strings.attribute_album_formats_title}
              key={"album_format_title"}
              theNewIcon={isAlbumFormatsExpanded ? ExpandLessRounded : ExpandMoreRounded}
              onClick={() => setIsAlbumFormatsExpanded(state => !state)}
              boldTitle
            />
            <Divider />
            <Collapse in={isAlbumFormatsExpanded} timeout={"auto"}>
              {stats.albumFormatCounts
                ?.sort((a, b) => b.count - a.count)
                .map((albumFormatCount, index) => (
                  <div key={`album_format_div_${index}`}>
                    <RightDetailListItem
                      title={albumFormatCount.grade.name}
                      detail={String(albumFormatCount.count)}
                      key={`album_format_item_${index}`}
                    />
                    {index < (stats.albumFormatCounts?.length ?? 0) - 1 && (
                      <Divider variant={"inset"} />
                    )}
                  </div>
                ))}
            </Collapse>
          </Card>
        </div>
      )}
      {(stats.tagCounts?.length ?? 0) > 0 && (
        <div key={"album_tags_section"}>
          <Card
            elevation={5}
            className={isMobile ? styles.itemCardMobile : styles.itemCard}
            key={"album_tag_card"}
          >
            <RightDetailListItem
              title={strings.tags_title}
              key={"album_tag_title"}
              theNewIcon={isAlbumTagsExpanded ? ExpandLessRounded : ExpandMoreRounded}
              onClick={() => setIsAlbumTagsExpanded(state => !state)}
              boldTitle
            />
            <Divider />
            <Collapse in={isAlbumTagsExpanded} timeout={"auto"}>
              {stats.tagCounts
                ?.sort((a, b) => b.count - a.count)
                .map((tagCount, index) => (
                  <div key={`album_tag_div_${index}`}>
                    <RightDetailListItem
                      title={tagCount.grade.name}
                      detail={String(tagCount.count)}
                      key={`album_tag_item_${index}`}
                    />
                    {index < (stats.tagCounts?.length ?? 0) - 1 && <Divider variant={"inset"} />}
                  </div>
                ))}
            </Collapse>
          </Card>
        </div>
      )}
      <Card
        elevation={5}
        className={isMobile ? styles.itemCardMobile : styles.itemCard}
        key={"financials_card"}
      >
        <RightDetailListItem
          title={strings.stats_financials_title}
          key={"financials_title"}
          boldTitle
        />
        <Divider />
        <RightDetailListItem
          title={strings.stats_purchase_price_total}
          detail={currencyFormatter.format(stats.totalPurchasePrice, currentUserProfile, false)}
          key={"financials_item_1"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_sell_value_total}
          detail={currencyFormatter.format(stats.totalSellValue, currentUserProfile, false)}
          key={"financials_item_2"}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          title={strings.stats_revenue_total}
          detail={currencyFormatter.format(
            stats.totalSellValue - stats.totalPurchasePrice,
            currentUserProfile,
            false
          )}
          key={"financials_item_3"}
        />
      </Card>
    </div>
  );
  return (
    <Card className={styles.mainContainer}>
      {stats ? (
        <PanelGroup className={styles.mainDiv} direction={"horizontal"}>
          {!isMobile && (
            <Panel
              minSize={30}
              defaultSize={34}
              maxSize={48}
              style={{ display: "flex", overflow: "auto", paddingBottom: 16 }}
            >
              <List disablePadding className={styles.list}>
                {statsList(stats)}
              </List>
            </Panel>
          )}
          {!isMobile && (
            <PanelResizeHandle className={styles.resizeHandle}>
              <SvgIcon
                component={DragIndicator}
                sx={{ alignSelf: "center", color: theme.palette.secondary.main, fontSize: 14 }}
              />
            </PanelResizeHandle>
          )}
          <Panel className={isMobile ? styles.graphsDivMobile : styles.graphsDiv}>
            {isMobile && statsList(stats)}
            <StatsGraphs stats={stats} />
          </Panel>
          {stats.artist && (
            <ArtistDialog
              artist={stats.artist}
              isOpen={isOpenArtistDialog}
              setIsOpen={setIsOpenArtistDialog}
              canEdit={true}
            />
          )}
          {stats.label && (
            <LabelDialog
              label={stats.label}
              isOpen={isOpenLabelDialog}
              setIsOpen={setIsOpenLabelDialog}
              canEdit={true}
            />
          )}
        </PanelGroup>
      ) : (
        <Fade in unmountOnExit style={{ transitionDelay: "2000ms" }}>
          <Stack className={styles.loadingStack} spacing={3}>
            <p>{isLoading ? strings.stats_loading : strings.stats_error}</p>
            {isLoading && (
              <LinearProgress className={styles.progressBar} variant={"indeterminate"} />
            )}
          </Stack>
        </Fade>
      )}
    </Card>
  );
};

export interface IStatsProps {
  stats?: IStats;
  isLoading: boolean;
}
