import React, { FunctionComponent, useEffect, useState } from "react";
import { MainContent } from "../../components/MainContent";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import piperTextImage from "../../images/piper-text.png";
import { useGetSystemStatus } from "../../hooks/systemStatus";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";

export const MaintenancePage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { getSystemStatus } = useGetSystemStatus();
  const [alreadyChecked, setAlreadyChecked] = useState(false);
  useEffect(() => {
    document.title = strings.app_name;
    if (!alreadyChecked) {
      checkSystemStatus().then();
    }
  }, []);
  const checkSystemStatus = async () => {
    const { status, body } = await getSystemStatus();
    if (!StatusCodesHelper.isOnMaintenance(status) || body.isAvailableWeb) {
      navigate(RoutesBuilder.home());
    }
    setAlreadyChecked(true);
  };
  return (
    <div className={styles.mainDiv}>
      <MainContent className={styles.mainContent} showingPersistentDrawer={false}>
        <img className={styles.logoImage} src={piperTextImage} alt={"Piper"} />
        <h2 className={styles.title}>{strings.greetings}</h2>
        <h4 className={styles.subtitle}>{strings.maintenance_message}</h4>
      </MainContent>
    </div>
  );
};
