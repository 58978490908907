import React, { FunctionComponent } from "react";
import { Card, ListItemButton, Stack } from "@mui/material";
import styles from "./styles.module.scss";
import { IAlbum } from "../../../hooks/albums";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { useUserProfile } from "../../../hooks/session";

export const BoxSetListItem: FunctionComponent<IBoxSetListItemProps> = ({ boxSet, onClick }) => {
  const [userProfile] = useUserProfile.useState();
  return (
    <Card className={styles.container}>
      <ListItemButton disableGutters onClick={onClick}>
        <Stack direction={"row"} flexGrow={1} spacing={1} alignItems={"center"} paddingRight={1}>
          <p className={styles.name}>{boxSet.name}</p>
          <p className={styles.label}>{AlbumUtilities.getSubtitle(boxSet, userProfile)}</p>
        </Stack>
      </ListItemButton>
    </Card>
  );
};

export interface IBoxSetListItemProps {
  boxSet: IAlbum;
  onClick: () => void;
}
