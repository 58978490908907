export const doc_album_attributes_es = {
  title: "Atributos de los álbumes",
  home_title: "Atributos generales",
  description: `En esta sección vas a encontrar todos los atributos que podés manejar para cada álbum, qué significan, y cómo podés usarlos.

  Empecemos con los más simples debajo y vas a encontrar el resto en una lista en el fondo de la sección.
  
`,
  name_title: "Nombre",
  name_description: `El nombre del álbum. No puede tener más de 512 caracteres. Este es el único campo mandatorio.
  
`,
  image_title: "Imagen",
  image_description: `Podés cambiar la imagen de la tapa del álbum. Si tenés una suscripción premium entonces también podés agregar imágenes de la contratapa, lomo, y hasta 50 imágenes adicionales del álbum.
  
`,
  country_title: "País",
  country_description: `El país de origen para esta edición en específico, donde fue distribuída originalmente.
  
`,
  original_year_title: "Año original",
  original_year_description: `Ingresá el año en el que el álbum salió originalmente.
  
`,
  release_year_title: "Año de edición",
  release_year_description: `Ingresá el año en el que esta edición fue lanzada. Probablemente este año va a coincidir con el año original para primeras ediciones y va a ser diferente para reediciones.
  
`,
  catalogue_number_title: "Número de catálogo",
  catalogue_number_description: `El número de catálogo del sello para esta edición.
  
`,
  discogs_id_title: "ID de Discogs",
  discogs_id_description: `El Release ID de Discogs para esta edición en particular. Podés encontrar este número en la página de Discogs como 'Release ID' en la esquina superior derecha o dentro de la URL.

  Este ID puede usarse luego para cargar automáticamente la lista de canciones del álbum. También habilita un link directo con la página de Discogs.  
  
`,
  purchase_date_title: "Fecha de compra",
  purchase_date_description: `La fecha en la que compraste el álbum. Esta fecha va a ser usada para calcular automáticamente el precio de compra usando el cambio de moneda más próximo.
  
`,
  purchase_price_title: "Precio de compra",
  purchase_price_description: `El precio que pagaste cuando compraste el álbum.

  El monto tiene que estar expresado en tu propia moneda (La que seleccionaste en tu perfil de usuario). Una vez que actualizás este valor, el monto se va a convertir usando el último cambio de moneda y el cambio de moneda más próximo a la fecha de compra, y va a ser mostrado de esa manera. También se va a mostrar en USD.  
  
`,
  track_count_title: "Número de canciones",
  track_count_description: `El número de canciones para este álbum.
  
`,
  length_title: "Duración",
  length_description: `La duración del álbum en el siguiente formato: HH:mm:ss.
  
  Por ejemplo, si un álbum tiene una duración de 33 minutos y 2 segundos tenés que ingresar: '00:33:02'.
    
`,
  collections_title: "Colecciones",
  collections_description: `Usá este atributo para asignar un álbum a una o más colecciones. El hecho de asignar un álbum a una colección no lo duplica. Podés asignar o desasignar álbumes las veces que quieras sin temer a perder información.

  Referite a la sección de colecciones en esta documentación para más información.  
  
`,
  played_title: "Escuchado",
  played_description: `Indica si el álbum fue escuchado o no. Esto puede ayudarte a dar un seguimiento de los álbumes que reprodujiste y los que no.
  
`,
  has_insert_title: "Con insert",
  has_insert_description: `Indica si el álbum incluye el insert/sobre interno original o no.
  
`,
  has_lyrics_title: "Con letras",
  has_lyrics_description: `Indica si el álbum incluye las letras de las canciones o no. A veces las letras están impresas en el insert, en el sobre interno, o en la contratapa.
  
`,
  gatefold_title: "Gatefold",
  gatefold_description: `Indica si el sobre externo es de tipo Gatefold o no. Los sobres de tipo Gatefold se despliegan como un libro o una billetera.
  
`,
  sleeve_grade_title: "Graduación del sobre",
  sleeve_grade_description: `El estado de preservación del sobre externo del álbum. Se usa el sistema de graduación Goldmine.
  
`,
  media_grade_title: "Graduación del disco",
  media_grade_description: `El estado de preservación de el/los discos del álbum. Se usa el sistema de graduación Goldmine.
  
`,
  impact_title: "Calificación",
  impact_description: `Una calificación personal que podés darle al álbum, de 0 a 5 estrellas.
  
`,
  notes_title: "Notas",
  notes_description: `Un campo de texto libre para que puedas poner lo que quieras sobre el álbum.
  
`,
  more_attributes: "Más atributos"
};
