import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Skeleton,
  Grow,
  Toolbar,
  useTheme,
  Card,
  Tooltip,
  IconButton,
  Stack,
  MenuItem,
  ListItemIcon,
  Menu
} from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import {
  AddCircleOutlineRounded,
  CloseRounded,
  DeleteForever,
  DriveFileRenameOutlineRounded,
  HelpOutlineRounded,
  LayersRounded,
  MoreVertRounded,
  MoveDownRounded,
  ShareRounded
} from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { arrayMoveMutable } from "array-move";
import { ICollection, useDeleteCollection, useGetCollections } from "../../../hooks/collections";
import { CollectionItem } from "../CollectionItem";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { AlertDialog } from "../../AlertDialog";
import { useIsMounted } from "../../../hooks/isMounted";
import { StringFormat } from "../../../models/StringFormat";
import { ModifyCollectionDialog } from "../ModifyCollectionDialog";
import styles from "./styles.module.scss";
import { FilterElement } from "../../../models/FilterElement";
import { useAssignToCollectionByFilter } from "../../../hooks/albums/useAssignToCollectionByFilter";
import { toast } from "react-toastify";
import { useGetStandardCollections } from "../../../hooks/collections/useGetStandardCollections";
import { useReorderCollections } from "../../../hooks/collections/useReorderCollections";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";
import { useCurrentPrivateCollection } from "../../../hooks/collections/useCollectionManager";
import { useShareLink } from "../../../hooks/share/useShareLink";
import { SearchBar } from "../../SearchBar";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { ContentUnavailableNotice } from "../../attributes/ContentUnavailableNotice";
import { useContextMenu } from "../../../hooks/contextMenu";

export const CollectionsDialog: FunctionComponent<ICollectionsDialogProps> = ({
  open,
  setOpen,
  canModifyCollections,
  displayStandardCollections,
  filterElements,
  onCollectionSelected
}) => {
  const theme = useTheme();
  const isMounted = useIsMounted();
  const isMobile = useIsMobile();
  const [currentPrivateCollection] = useCurrentPrivateCollection.useState();
  const { reorderCollections } = useReorderCollections();
  const { assignToCollectionByFilter } = useAssignToCollectionByFilter();
  const [loading, setLoading] = useState(false);
  const [loadingStandardCollections, setLoadingStandardCollections] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isAssignAlertOpen, setIsAssignAlertOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [resetStandardCollections, setResetStandardCollections] = useState(false);
  const standardCollections = useGetStandardCollections(
    setLoadingStandardCollections,
    isMounted.current,
    [resetStandardCollections]
  );
  const [collections, resetCollections] = useGetCollections(setLoading, isMounted.current);
  const [filteredCollections, setFilteredCollections] = useState<ICollection[]>();
  const [searchText, setSearchText] = useState("");
  const { deleteCollection } = useDeleteCollection();
  const [isAddCollectionDialogOpen, setIsAddCollectionDialogOpen] = useState(false);
  const [isModifyCollectionDialogOpen, setIsModifyCollectionDialogOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<ICollection>();
  const [shareLinkComponents, fetchShareLink, , isShareLoading] = useShareLink(
    selectedCollection?.name
  );
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const onCancel = () => {
    setOpen(false);
    setSearchText("");
  };
  const onError = (body: any) => {
    if (isMounted.current) {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
  };
  useEffect(() => {
    if (!open || !isMounted.current) return;
    if (displayStandardCollections) {
      setResetStandardCollections(state => !state);
    }
    resetCollections();
  }, [open]);
  const performDeletion = async () => {
    if (!selectedCollection) return;
    setLoading(true);
    const { status, body } = await deleteCollection(selectedCollection);
    setLoading(false);
    if (StatusCodesHelper.isSuccessful(status)) {
      resetCollections();
      toast.success(strings.generic_update_success);
    } else {
      onError(body);
    }
  };
  const assignByFilter = async (collection: ICollection) => {
    if (!filterElements) return;
    const request = assignToCollectionByFilter(
      { assignToCollectionId: collection.uuid },
      filterElements
    );
    if (!request) return;
    setLoading(true);
    const { status, body } = await request;
    setLoading(false);
    if (StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(strings.collection_assign_filtered_albums_success);
    } else {
      setAlertMessage(body.message);
    }
    setIsAlertOpen(true);
  };
  useEffect(() => {
    refreshFilteredCollections();
  }, [collections]);
  const refreshFilteredCollections = () => {
    if (searchText == "") {
      setFilteredCollections(collections);
      return;
    }
    setFilteredCollections(
      collections?.filter(collection => {
        return (
          collection.name.toLowerCase().includes(searchText.toLowerCase()) ||
          collection.description?.toLowerCase().includes(searchText.toLowerCase())
        );
      })
    );
  };
  useEffect(() => {
    refreshFilteredCollections();
  }, [searchText]);
  const searchBar = () => (
    <SearchBar
      className={styles.searchBar}
      onSearchRequested={setSearchText}
      onSearchDismissed={() => setSearchText("")}
      timed={false}
      minimumSearchLength={0}
      autoFocus={!isMobile}
    />
  );
  const skeletons = () =>
    Array.from({ length: 5 }, (value, index) => (
      <div key={`div_${index}`} className={styles.cardContainer}>
        <Skeleton
          key={index}
          variant={"rectangular"}
          height={124}
          className={styles.collectionCardSkeleton}
        />
      </div>
    ));
  return (
    <Dialog fullWidth maxWidth={"xs"} open={open} onClose={onCancel} TransitionComponent={Grow}>
      <Toolbar>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <h3 style={{ marginRight: 8 }}>{strings.collections_title}</h3>
            <ToolbarButton
              onClick={() => setIsAddCollectionDialogOpen(true)}
              tooltip={strings.add_collection}
              icon={AddCircleOutlineRounded}
            />
            <Box flexGrow={1} />
            <ToolbarButton
              href={`${window.location.protocol}//${
                window.location.host
              }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.collections.home()}`}
              target={"_blank"}
              tooltip={strings.help}
              icon={HelpOutlineRounded}
            />
            <ToolbarButton
              loading={loading || isShareLoading || loadingStandardCollections}
              onClick={() => {
                setOpen(false);
              }}
              tooltip={strings.close}
              icon={CloseRounded}
            />
          </div>
          {searchBar()}
        </div>
      </Toolbar>
      <Card className={styles.contentCard} elevation={15}>
        <DragDropContext
          onDragEnd={async result => {
            if (!collections || searchText != "") return;
            if (!result.destination || result.source.index == result.destination.index) {
              return;
            }
            arrayMoveMutable(collections, result.source.index, result.destination.index);
            setLoading(true);
            const { status } = await reorderCollections(collections);
            if (StatusCodesHelper.isSuccessful(status)) {
              toast.success(strings.generic_update_success);
            } else {
              toast.error(strings.generic_update_error);
            }
            setLoading(false);
          }}
        >
          <DialogContent
            sx={{ padding: isMobile ? 0 : 1, display: "flex", flexDirection: "column" }}
          >
            {displayStandardCollections &&
              standardCollections?.map(collection => (
                <CollectionItem
                  key={`ci_${collection.uuid}`}
                  cardClassName={styles.collectionCard}
                  collection={collection}
                  canMove={false}
                  selected={currentPrivateCollection?.uuid == collection.uuid}
                  onSelection={() => {
                    setSelectedCollection(collection);
                    if (onCollectionSelected) {
                      onCollectionSelected(collection);
                    }
                  }}
                />
              ))}
            <Droppable key={"droppable"} droppableId={"collection"} isDropDisabled={loading}>
              {provided => (
                <div
                  key={"div_droppable"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    flexGrow: filteredCollections?.length == 0 ? 1 : 0
                  }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {filteredCollections && !loading
                    ? filteredCollections.map((collection, index) => (
                        <Draggable
                          key={collection.uuid}
                          draggableId={collection.uuid}
                          index={index}
                          isDragDisabled={searchText != ""}
                        >
                          {provided => (
                            <div
                              key={`div_${collection.uuid}`}
                              className={styles.cardContainer}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CollectionItem
                                key={`ci_${collection.uuid}`}
                                cardClassName={styles.collectionCard}
                                collection={collection}
                                selected={currentPrivateCollection?.uuid == collection.uuid}
                                canMove={true}
                                onContextMenu={event => {
                                  setSelectedCollection(collection);
                                  handleContextMenu(event);
                                }}
                                onSelection={() => {
                                  setSelectedCollection(collection);
                                  if (canModifyCollections) {
                                    setIsModifyCollectionDialogOpen(true);
                                  }
                                  if (onCollectionSelected) {
                                    onCollectionSelected(collection);
                                  }
                                }}
                              >
                                <Stack
                                  className={styles.hoverButtonsStack}
                                  direction={"row"}
                                  spacing={1}
                                >
                                  <Tooltip title={strings.options} arrow disableInteractive>
                                    <IconButton
                                      className={styles.hoverButton}
                                      style={{
                                        backgroundColor: theme.palette.colors?.counterBackground
                                      }}
                                      onClick={event => {
                                        event.stopPropagation();
                                        setSelectedCollection(collection);
                                        handleContextMenu(event);
                                      }}
                                    >
                                      <ResponsiveIcon
                                        className={styles.hoverButtonIcon}
                                        icon={MoreVertRounded}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </CollectionItem>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : skeletons()}
                  {loading && skeletons()}
                  <ContentUnavailableNotice
                    isLoading={loading}
                    items={filteredCollections}
                    emptyIcon={LayersRounded}
                    emptyTitle={strings.empty_collections}
                    emptyDescription={strings.empty_attribute_description}
                    addButtonTitle={strings.add_collection}
                    onAddItem={() => setIsAddCollectionDialogOpen(true)}
                    searchText={searchText}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DialogContent>
        </DragDropContext>
      </Card>
      <Menu {...menuProps}>
        {selectedCollection?.visibility == "public" && (
          <MenuItem
            onClick={async () => {
              handleMenuClose();
              await fetchShareLink(selectedCollection?.uuid, undefined);
            }}
            dense
          >
            <ListItemIcon>
              <ShareRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.share_collection}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifyCollectionDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.collection_modify}
        </MenuItem>
        {filterElements !== undefined && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsAssignAlertOpen(true);
            }}
            dense
          >
            <ListItemIcon>
              <MoveDownRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.collection_assign_filtered_albums}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsDeleteAlertOpen(true);
          }}
          style={{ color: theme.palette.colors?.remove }}
          dense
        >
          <ListItemIcon>
            <DeleteForever style={{ color: theme.palette.colors?.remove }} fontSize={"small"} />
          </ListItemIcon>
          {strings.delete}
        </MenuItem>
      </Menu>
      <ModifyCollectionDialog
        open={isAddCollectionDialogOpen}
        setOpen={setIsAddCollectionDialogOpen}
        callback={resetCollections}
      />
      <ModifyCollectionDialog
        open={isModifyCollectionDialogOpen}
        setOpen={setIsModifyCollectionDialogOpen}
        collection={selectedCollection}
        callback={resetCollections}
        onDelete={() => setIsDeleteAlertOpen(true)}
      />
      {shareLinkComponents()}
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
      <AlertDialog
        isConfirm={true}
        message={StringFormat(
          strings.collection_delete_confirmation,
          selectedCollection?.name ?? ""
        )}
        open={isDeleteAlertOpen}
        setOpen={setIsDeleteAlertOpen}
        onConfirm={async () => {
          setIsModifyCollectionDialogOpen(false);
          await performDeletion();
        }}
      />
      <AlertDialog
        isConfirm={true}
        message={strings.collection_assign_filtered_albums_confirm}
        open={isAssignAlertOpen}
        setOpen={setIsAssignAlertOpen}
        onConfirm={async () => {
          if (!selectedCollection) return;
          await assignByFilter(selectedCollection);
        }}
      />
    </Dialog>
  );
};

export interface ICollectionsDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  canModifyCollections: boolean;
  displayStandardCollections: boolean;
  filterElements?: FilterElement[];
  onCollectionSelected?: (collection: ICollection) => void;
}
