import { useBackend } from "../../services";
import { ICollection } from "../collections";

export const useAddAlbum = () => {
  const { post } = useBackend();
  const addAlbum = (parameters: IAddAlbumParameters) => post("albums", parameters);
  return { addAlbum };
};

export interface IAddAlbumParameters {
  name: string;
  artistId: string;
  order: number;
  isClean: boolean;
  originalYear?: string;
  releaseYear?: string;
  country?: string;
  length?: number;
  tracks?: number;
  catalogueNumber?: string;
  isGatefold: boolean;
  hasInsert: boolean;
  hasLyrics: boolean;
  purchasePrice?: number;
  purchaseDate?: number;
  discogsReleaseId?: string;
  impactId?: number;
  storeId?: string;
  labelId?: string;
  sleeveGradeId?: number;
  mediaGradeId?: number;
  sellValueId?: string;
  albumTypeId?: string;
  collections?: [ICollection];
  isBoxSet: boolean;
}
