import React, { FunctionComponent, useEffect, useState } from "react";
import { MainContent } from "../../components/MainContent";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import { AppBar, Box, Card, List, Stack, Toolbar, useTheme } from "@mui/material";
import { MainDrawer } from "../../components/MainDrawer";
import { PublicCollectionsGrid } from "../../components/marketplace/PublicCollectionsGrid";
import { PublicAlbumsGrid } from "../../components/marketplace/PublicAlbumsGrid";
import { UserProfileCard } from "../../components/userProfiles/UserProfileCard";
import { PiperLogoButton } from "../../components/PiperLogoButton";
import { AppCredits } from "../../components/AppCredits";
import { usePublicUserProfile, useUser, useUserProfile } from "../../hooks/session";
import { IPublicCollection, useGetHighlightedCollections } from "../../hooks/collections";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useCurrentPublicCollection } from "../../hooks/collections/useCollectionManager";
import { useGetShareLink } from "../../hooks/share";
import { useNavigate } from "react-router-dom";
import { PublicUserProfilesGrid } from "../../components/marketplace/PublicUserProfilesGrid";
import { useShareSearchByText } from "../../hooks/share/useShareSearchByText";
import { SearchBar } from "../../components/SearchBar";
import { useGetHighlightedAlbums } from "../../hooks/albums/useGetHighlightedAlbums";
import { IPublicAlbum } from "../../hooks/albums";
import { IUserProfile } from "../../hooks/userProfiles";
import { FilterAltOffRounded } from "@mui/icons-material";
import { ToolbarButton } from "../../components/toolbar/ToolbarButton";
import { AlertDialog } from "../../components/AlertDialog";
import { PublicUserProfileDialog } from "../../components/userProfiles/PublicUserProfileDialog";
import { useIsMobile } from "../../hooks/isMobile";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useIsScreenWide } from "../../hooks/isScreenWide";
import { ContentUnavailableNotice } from "../../components/attributes/ContentUnavailableNotice";

export const MarketplacePage: FunctionComponent = () => {
  useEffect(() => {
    document.title = `${strings.app_name} - ${strings.option_marketplace}`;
  }, []);
  const theme = useTheme();
  const isScreenWide = useIsScreenWide();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const [, setCurrentPublicUserProfile] = usePublicUserProfile.useState();
  const [, setCurrentPublicCollection] = useCurrentPublicCollection.useState();
  const { getShareLink } = useGetShareLink();
  const { shareSearchByText } = useShareSearchByText();
  const [searchText, setSearchText] = useState("");
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingNavigation, setIsLoadingNavigation] = useState(false);
  const [isLoadingCollectionHighlights, setIsLoadingCollectionHighlights] = useState(false);
  const [isLoadingAlbumHighlights, setIsLoadingAlbumHighlights] = useState(false);
  const highlightedCollections = useGetHighlightedCollections(setIsLoadingCollectionHighlights, []);
  const [publicCollections, setPublicCollections] = useState<IPublicCollection[]>();
  const highlightedAlbums = useGetHighlightedAlbums(setIsLoadingAlbumHighlights, []);
  const [publicAlbums, setPublicAlbums] = useState<IPublicAlbum[]>();
  const [userProfiles, setUserProfiles] = useState<IUserProfile[]>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenWide);
  const [selectedUserProfile, setSelectedUserProfile] = useState<IUserProfile>();
  const [isPublicUserProfileDialogOpen, setIsPublicUserProfileDialogOpen] = useState(false);
  const [isOpenDiscogsImport, setIsOpenDiscogsImport] = useState(false);
  const [isOpenExcelImport, setIsOpenExcelImport] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const searchContentByText = async () => {
    setIsLoadingSearch(true);
    const { status, body } = await shareSearchByText({ query: searchText });
    if (StatusCodesHelper.isSuccessful(status)) {
      setPublicCollections(body.collections);
      setPublicAlbums(body.albums);
      setUserProfiles(body.userProfiles);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingSearch(false);
  };
  useEffect(() => {
    if (searchText == "") return;
    searchContentByText().then();
  }, [searchText]);
  const showCollection = async (publicCollection: IPublicCollection) => {
    setIsLoadingNavigation(true);
    const { status, body } = await getShareLink({
      collectionId: publicCollection.collection.uuid
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setCurrentPublicUserProfile(undefined);
      setCurrentPublicCollection(undefined);
      navigate(body.relativeShareLink);
    }
    setIsLoadingNavigation(false);
  };
  const isLoading = () => {
    return isLoadingContent() || isLoadingNavigation;
  };
  const isLoadingContent = () => {
    return isLoadingCollectionHighlights || isLoadingAlbumHighlights || isLoadingSearch;
  };
  const collectionsToShow = () => {
    return searchText == "" ? highlightedCollections : publicCollections;
  };
  const albumsToShow = () => {
    return searchText == "" ? highlightedAlbums : publicAlbums;
  };
  const haveCollections = () => {
    return collectionsToShow() && (collectionsToShow()?.length ?? 0) > 0;
  };
  const haveAlbums = () => {
    return albumsToShow() && (albumsToShow()?.length ?? 0) > 0;
  };
  const haveUserProfiles = () => {
    return userProfiles && userProfiles.length > 0;
  };
  const goToUserProfile = () => navigate(RoutesBuilder.userProfile());
  return (
    <MainContent showingPersistentDrawer={isScreenWide && isDrawerOpen}>
      <Card
        sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}
        elevation={0}
      >
        <AppBar
          position="sticky"
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.colors?.appBar
          }}
        >
          <Toolbar className={styles.toolbar} disableGutters>
            {(!isScreenWide || !isDrawerOpen) && (
              <PiperLogoButton
                style={{ marginRight: isMobile ? 0 : 8 }}
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
              />
            )}
            <MainDrawer
              currentPage={"marketplace"}
              isOpen={isDrawerOpen}
              setIsOpen={setIsDrawerOpen}
              isOpenDiscogsImport={isOpenDiscogsImport}
              setIsOpenDiscogsImport={setIsOpenDiscogsImport}
              isOpenExcelImport={isOpenExcelImport}
              setIsOpenExcelImport={setIsOpenExcelImport}
            />
            {!isMobile && <h3 className={styles.title}>{strings.option_marketplace}</h3>}
            <SearchBar
              className={styles.searchBar}
              style={{ minWidth: isMobile ? undefined : 340 }}
              placeholder={strings.marketplace_search_placeholder}
              onSearchRequested={searchText => {
                setSearchText(searchText);
                setPublicCollections(undefined);
                setPublicAlbums(undefined);
                setUserProfiles(undefined);
              }}
              onSearchDismissed={() => {
                setSearchText("");
                setPublicCollections(undefined);
                setPublicAlbums(undefined);
                setUserProfiles(undefined);
              }}
              forceThisText={searchText}
              timed
            />
            <ToolbarButton
              onClick={() => setSearchText("")}
              disabled={searchText == ""}
              loading={isLoading()}
              tooltip={isLoading() ? "" : strings.clear_search}
              icon={FilterAltOffRounded}
              color={theme.palette.colors?.remove}
            />
            <Box flexGrow={1} />
            <UserProfileCard
              isPublic={false}
              userProfile={currentUserProfile}
              onClick={() => {
                if (!currentUserProfile || currentUser?.role == "guest") return;
                goToUserProfile();
              }}
            />
          </Toolbar>
        </AppBar>
        <Card className={styles.mainCard}>
          <List className={styles.mainList}>
            {(isLoadingContent() || haveCollections()) && (
              <>
                <Stack direction={"row"} spacing={2}>
                  <p className={styles.sectionTitle} key={"highlighted_collections_title"}>
                    {strings.highlighted_collections_title}
                  </p>
                </Stack>
                <Card
                  className={styles.publicCollectionsCard}
                  sx={{ marginBottom: 4 }}
                  elevation={4}
                >
                  <PublicCollectionsGrid
                    isLoading={isLoading()}
                    publicCollections={collectionsToShow()}
                    columnWidth={isMobile ? 12 : 12 / 5}
                    skeletonCount={5}
                    onCollectionSelected={showCollection}
                    showUser
                  />
                </Card>
              </>
            )}
            {(isLoadingContent() || haveAlbums()) && (
              <>
                <Stack direction={"row"} spacing={2}>
                  <p className={styles.sectionTitle} key={"highlighted_albums_title"}>
                    {strings.highlighted_albums_title}
                  </p>
                </Stack>
                <Card
                  className={styles.publicCollectionsCard}
                  sx={{ marginBottom: 4 }}
                  elevation={4}
                >
                  <PublicAlbumsGrid
                    publicAlbums={albumsToShow()}
                    setIsLoading={setIsLoadingNavigation}
                  />
                </Card>
              </>
            )}
            {haveUserProfiles() && (
              <>
                <Stack direction={"row"} spacing={2}>
                  <p className={styles.sectionTitle} key={"highlighted_users_title"}>
                    {strings.highlighted_users_title}
                  </p>
                </Stack>
                <Card className={styles.publicCollectionsCard} elevation={4}>
                  <PublicUserProfilesGrid
                    userProfiles={userProfiles}
                    onUserProfileSelected={userProfile => {
                      setSelectedUserProfile(userProfile);
                      setIsPublicUserProfileDialogOpen(true);
                    }}
                    skeletonCount={5}
                  />
                </Card>
              </>
            )}
          </List>
          <ContentUnavailableNotice
            isLoading={isLoading()}
            items={haveCollections() || haveAlbums() || haveUserProfiles() ? [""] : []}
            emptyIcon={undefined}
            emptyTitle={""}
            emptyDescription={""}
            searchText={searchText}
          />
        </Card>
        <AppCredits className={styles.credits} />
      </Card>
      {selectedUserProfile && (
        <PublicUserProfileDialog
          userProfile={selectedUserProfile}
          isOpen={isPublicUserProfileDialogOpen}
          setIsOpen={setIsPublicUserProfileDialogOpen}
          showCollectionInfo={false}
          showSendMessageButton
        />
      )}
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </MainContent>
  );
};
