import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Stack,
  Toolbar,
  useTheme
} from "@mui/material";
import { IArtist } from "../../../../hooks/artists";
import { strings } from "../../../../localization/LocalizedStrings";
import { TextField } from "../../../TextField";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useAddArtist } from "../../../../hooks/artists/useAddArtist";
import { useModifyArtist } from "../../../../hooks/artists/useModifyArtist";
import { useDeleteArtist } from "../../../../hooks/artists/useDeleteArtist";
import genericImage from "../../../../images/generic_artist.png";
import { useUploadImage } from "../../../../hooks/images";
import { DatePickerField } from "../../../DatePickerField";
import { ImageDropzone } from "../../../ImageDropzone";
import { AlertDialog } from "../../../AlertDialog";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { ImageCache } from "../../../../models/ImageCache";
import { FieldTitle } from "../../../Text/FieldTitle";
import { ContainedButton } from "../../../ContainedButton";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { CloseRounded, Delete } from "@mui/icons-material";
import { useIsMobile } from "../../../../hooks/isMobile";
import { FreeTextListItem } from "../../../FreeTextListItem";
import { ModifyFreeTextAttributeDialog } from "../../ModifyFreeTextAttributeDialog";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../../../services/Firebase";

export const ModifyArtistDialog: FunctionComponent<IModifyArtistDialogProps> = ({
  open,
  setOpen,
  artist,
  originalImageData,
  onNewItem,
  onModifiedItem,
  onDeletedItem
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const analytics = Firebase.shared.getAnalytics();
  const [dialogTitle, setDialogTitle] = useState(strings.ma_new_title);
  const { addArtist } = useAddArtist();
  const { modifyArtist } = useModifyArtist();
  const { deleteArtist } = useDeleteArtist();
  const { uploadImage } = useUploadImage();
  const [isModifyProfileDialogOpen, setIsModifyProfileDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [didChangeImage, setDidChangeImage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const tryToClose = () => {
    if (isLoading) return;
    if (formik.dirty) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [imageFile, setImageFile] = useState<File>();
  const validationSchema = Yup.object({
    name: Yup.string().required(strings.ma_missing_error),
    birthDate: Yup.date(),
    deathDate: Yup.date()
  });
  const formik = useFormik({
    initialValues: {
      name: artist?.name ?? "",
      realName: artist?.realName ?? "",
      website: artist?.website ?? "",
      discogsId: artist?.discogsArtistId ?? "",
      birthDate: artist?.birthDate ? new Date(artist.birthDate * 1000) : undefined,
      deathDate: artist?.deathDate ? new Date(artist.deathDate * 1000) : undefined,
      profile: artist?.profile ?? ""
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ name, realName, website, discogsId, birthDate, deathDate, profile }) => {
      setIsLoading(true);
      const birthDateNumber = birthDate ? birthDate.getTime() / 1000 : undefined;
      const deathDateNumber = deathDate ? deathDate.getTime() / 1000 : undefined;
      if (artist) {
        const { status, body } = await modifyArtist({
          uuid: artist.uuid,
          name,
          realName,
          website,
          discogsArtistId: discogsId,
          birthDate: birthDateNumber,
          deathDate: deathDateNumber,
          hasImage: artist.hasImage,
          profile
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_artist_action", { isNew: false });
          if (didChangeImage && imageFile && artist.uuid) {
            const newThumbnail = await uploadImage(
              { name: artist.uuid, file: imageFile },
              ImageCache.bigArtistImageSize,
              ImageCache.bigArtistImageSize,
              ImageCache.smallArtistImageSize,
              ImageCache.smallArtistImageSize
            );
            if (newThumbnail) {
              body.hasImage = true;
              body.thumbnail = newThumbnail;
            }
          }
          if (onModifiedItem != undefined) {
            onModifiedItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      } else {
        const { status, body } = await addArtist({
          name: name,
          realName: realName,
          website: website,
          discogsArtistId: discogsId,
          birthDate: birthDateNumber,
          deathDate: deathDateNumber,
          profile: profile
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_artist_action", { isNew: true });
          if (didChangeImage && imageFile && body.uuid) {
            const newThumbnail = await uploadImage(
              { name: body.uuid, file: imageFile },
              ImageCache.bigArtistImageSize,
              ImageCache.bigArtistImageSize,
              ImageCache.smallArtistImageSize,
              ImageCache.smallArtistImageSize
            );
            if (newThumbnail) {
              body.hasImage = true;
              body.thumbnail = newThumbnail;
            }
          }
          if (onNewItem != undefined) {
            onNewItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      }
      setIsLoading(false);
    },
    validationSchema: validationSchema
  });
  const onDelete = async () => {
    if (!artist) return;
    setIsLoading(true);
    const { status, body } = await deleteArtist({ uuid: artist.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (onDeletedItem != undefined) {
        onDeletedItem(artist);
      }
      handleClose();
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!open) return;
    setDialogTitle(artist ? strings.artist_title : strings.ma_new_title);
    formik.resetForm();
    setImageFile(undefined);
    setDidChangeImage(false);
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClose={tryToClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{dialogTitle}</h3>
          <Box sx={{ flexGrow: 1 }} />
          {artist && (
            <ToolbarButton
              disabled={isLoading}
              onClick={onDelete}
              tooltip={strings.delete}
              icon={Delete}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton
            loading={isLoading}
            onClick={tryToClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Stack direction={"row"} spacing={2}>
            <ImageDropzone
              className={styles.dropzone}
              onImageChanged={file => {
                setImageFile(file);
                setDidChangeImage(true);
              }}
              placeholderImage={genericImage}
              initialImage={
                originalImageData
                  ? `data:image/png;base64,${originalImageData}`
                  : artist?.thumbnail
                    ? `data:image/png;base64,${artist.thumbnail}`
                    : undefined
              }
              isLoading={false}
            />
            <Stack sx={{ width: "100%" }}>
              <FieldTitle>{strings.ma_name_title}</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="name"
                  name="name"
                  size="small"
                  autoFocus={!isMobile}
                  placeholder={strings.ma_name_placeholder}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
              <FieldTitle>{strings.ma_real_name_title}</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="realName"
                  name="realName"
                  size="small"
                  placeholder={strings.ma_real_name_placeholder}
                  value={formik.values.realName}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
            </Stack>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <div>
              <FieldTitle>{strings.ma_website_title}</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="website"
                  name="website"
                  size="small"
                  placeholder={strings.ma_website_placeholder}
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
            </div>
            <div>
              <FieldTitle>{strings.ma_discogs_id_title}</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="discogsId"
                  name="discogsId"
                  size="small"
                  placeholder={strings.ma_discogs_id_placeholder}
                  value={formik.values.discogsId}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
            </div>
          </Stack>
          <Stack direction={"row"} spacing={2} sx={{ marginBottom: 2 }}>
            <div>
              <FieldTitle>{strings.ma_birth_date_title}</FieldTitle>
              <DatePickerField
                title={strings.ma_birth_date_title}
                value={formik.values.birthDate ?? null}
                onChange={newValue => {
                  formik.setFieldValue("birthDate", newValue);
                }}
              />
            </div>
            <div>
              <FieldTitle>{strings.ma_death_date_title}</FieldTitle>
              <DatePickerField
                title={strings.ma_death_date_title}
                value={formik.values.deathDate ?? null}
                onChange={newValue => {
                  formik.setFieldValue("deathDate", newValue);
                }}
              />
            </div>
          </Stack>
          <FieldTitle>{strings.ma_profile_title}</FieldTitle>
          <Card className={styles.profileCard}>
            <FreeTextListItem
              hideTitle
              detail={formik.values.profile ?? "--"}
              maxLines={4}
              onClick={() => setIsModifyProfileDialogOpen(true)}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={handleClose}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
      <ModifyFreeTextAttributeDialog
        title={strings.ma_profile_placeholder}
        description={strings.edit_profile_description}
        placeholder={strings.ma_profile_placeholder}
        value={formik.values.profile}
        open={isModifyProfileDialogOpen}
        setOpen={setIsModifyProfileDialogOpen}
        onChange={newValue => formik.setFieldValue("profile", newValue)}
        onClearValue={() => formik.setFieldValue("profile", null)}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifyArtistDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  artist?: IArtist;
  originalImageData?: string;
  onNewItem?: (item: IArtist) => void;
  onModifiedItem?: (item: IArtist) => void;
  onDeletedItem?: (item: IArtist) => void;
}
