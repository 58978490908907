import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Grow,
  Stack,
  Toolbar,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { StatusCodes } from "http-status-codes";
import {
  CameraswitchRounded,
  CloseRounded,
  DocumentScannerOutlined,
  HelpOutlineRounded,
  PhotoCameraRounded,
  TravelExploreRounded
} from "@mui/icons-material";
import { useGetDiscogsIdentity } from "../../../hooks/discogs/useGetDiscogsIdentity";
import { useGetDiscogsRequestToken } from "../../../hooks/discogs/useGetDiscogsRequestToken";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { AlertDialog } from "../../AlertDialog";
import { strings } from "../../../localization/LocalizedStrings";
import { SearchBar } from "../../SearchBar";
import { useSearchDiscogsReleases } from "../../../hooks/discogs/useSearchDiscogsReleases";
import { IDiscogsRelease } from "../../../hooks/discogs";
import { DiscogsReleaseGridItem } from "../DiscogsReleaseGridItem";
import { useAddAlbumFromDiscogsRelease } from "../../../hooks/albums/useAddAlbumFromDiscogsRelease";
import { IAlbum } from "../../../hooks/albums";
import { toast } from "react-toastify";
import { ToolbarBox } from "../../toolbar/ToolbarBox";
import { ToolbarRawButton } from "../../toolbar/ToolbarRawButton";
import { UserRole } from "../../../hooks/users";
import { useUser } from "../../../hooks/session";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { ContentUnavailableNotice } from "../../attributes/ContentUnavailableNotice";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../../services/Firebase";

export const BarcodeScannerDialog: FunctionComponent<IBarcodeScannerDialogProps> = ({
  isOpen,
  setIsOpen,
  onNewAlbum
}) => {
  const [currentUser] = useUser.useState();
  const analytics = Firebase.shared.getAnalytics();
  const getDiscogsIdentity = useGetDiscogsIdentity();
  const getDiscogsRequestToken = useGetDiscogsRequestToken();
  const searchDiscogsReleases = useSearchDiscogsReleases();
  const { addAlbumFromDiscogsRelease } = useAddAlbumFromDiscogsRelease();
  const [releases, setReleases] = useState<IDiscogsRelease[]>([]);
  const [shouldUploadImage, setShouldUploadImage] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isBackCamera, setIsBackCamera] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [noResultsText, setNoResultsText] = useState("");
  const theme = useTheme();
  const performSearch = async (query: string) => {
    if (query == "") return;
    // Remove leading zeroes
    query = query.replace(/^0+/, "");
    setNoResultsText("");
    setIsLoading(true);
    const { status, body } = await searchDiscogsReleases(query);
    if (StatusCodesHelper.isSuccessful(status)) {
      setReleases(body);
      if (!body || body.length == 0) {
        setNoResultsText(searchText);
      }
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const addAlbum = async (release: IDiscogsRelease) => {
    setIsLoading(true);
    const { status, body } = await addAlbumFromDiscogsRelease({
      releaseId: release.id,
      shouldUploadImage
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_created);
      logEvent(analytics, "new_album_manual", {
        user_id: currentUser.uuid,
        album_id: body.uuid
      });
      onNewAlbum(body);
      setIsOpen(false);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const getDiscogsToken = async () => {
    const { body, status } = await getDiscogsRequestToken();
    if (StatusCodesHelper.isSuccessful(status)) {
      localStorage.setItem("discogs_temp", body.secret);
      localStorage.setItem("discogs_level", body.authLevel);
      window.open(body.authorizeUrl, "_self");
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const checkDiscogsAccess = async () => {
    const { body, status } = await getDiscogsIdentity();
    if (StatusCodesHelper.isSuccessful(status)) {
      setIsLoading(false);
    } else if (status == StatusCodes.NOT_FOUND) {
      await getDiscogsToken();
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!isOpen || currentUser?.role == "guest") return;
    checkDiscogsAccess().then();
  }, [isOpen]);
  const handleClose = async () => {
    if (isLoading) return;
    setIsOpen(false);
  };
  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose} TransitionComponent={Grow}>
      <div className={styles.dialog} style={{ backgroundColor: theme.palette.background.default }}>
        <Toolbar>
          <h3>{strings.option_scan_barcode}</h3>
          <Box flexGrow={1} />
          <ToolbarButton
            href={`${window.location.protocol}//${
              window.location.host
            }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.scanBarcode()}`}
            target={"_blank"}
            tooltip={strings.help}
            icon={HelpOutlineRounded}
          />
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent>
          <Stack spacing={2} sx={{ height: "100%" }}>
            <Stack direction={"row"} spacing={1}>
              <ToolbarButton
                tooltip={strings.open_camera}
                icon={PhotoCameraRounded}
                highlighted={isCameraOpen}
                onClick={() => setIsCameraOpen(state => !state)}
              />
              <SearchBar
                className={styles.searchBar}
                onSearchRequested={text => setSearchText(text)}
                onSearchDismissed={() => setSearchText("")}
                forceThisText={searchText}
              />
              <ToolbarButton
                tooltip={strings.option_search_albums}
                icon={TravelExploreRounded}
                disabled={searchText == ""}
                onClick={() => performSearch(searchText)}
              />
            </Stack>
            <FormControlLabel
              sx={{ width: "100%" }}
              disabled={currentUser?.role != UserRole.premium}
              control={
                <Checkbox
                  checked={shouldUploadImage}
                  onChange={() => setShouldUploadImage(state => !state)}
                />
              }
              label={
                currentUser?.role == UserRole.premium
                  ? strings.discogs_should_upload_image
                  : strings.discogs_should_upload_image_non_premium
              }
            />
            <Card className={styles.gridCard}>
              {releases && releases.length > 0 && (
                <Grid container spacing={2}>
                  {releases.map(release => (
                    <DiscogsReleaseGridItem
                      key={release.id}
                      release={release}
                      disabled={isLoading}
                      onClick={() => addAlbum(release)}
                    />
                  ))}
                </Grid>
              )}
              <ContentUnavailableNotice
                isLoading={isLoading}
                items={releases}
                emptyIcon={DocumentScannerOutlined}
                emptyTitle={strings.scan_barcode_title}
                emptyDescription={strings.scan_barcode_description}
                searchText={noResultsText}
              />
            </Card>
          </Stack>
        </DialogContent>
      </div>
      <Dialog
        maxWidth={"md"}
        open={isCameraOpen}
        onClose={() => setIsCameraOpen(false)}
        TransitionComponent={Grow}
      >
        <Toolbar>
          <h3>{strings.camera_title}</h3>
          <Box flexGrow={1} />
          <ToolbarBox>
            <ToolbarRawButton
              tooltip={strings.switch_camera}
              icon={CameraswitchRounded}
              highlighted={!isBackCamera}
              onClick={() => setIsBackCamera(state => !state)}
            />
          </ToolbarBox>
          <ToolbarButton
            tooltip={strings.close}
            icon={CloseRounded}
            onClick={() => setIsCameraOpen(false)}
          />
        </Toolbar>
        <QrScanner
          onDecode={result => {
            setSearchText(result);
            performSearch(result).then();
            setIsCameraOpen(false);
          }}
          onError={undefined}
          constraints={{ facingMode: isBackCamera ? "environment" : "user" }}
        />
      </Dialog>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </Dialog>
  );
};

export interface IBarcodeScannerDialogProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  onNewAlbum: (newAlbum: IAlbum) => void;
}
