import React, { FunctionComponent, useEffect, useState } from "react";
import { IToken, useLogin } from "../../hooks/users";
import { strings } from "../../localization/LocalizedStrings";
import { MainContent } from "../../components/MainContent";
import styles from "./styles.module.scss";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "../../components/TextField";
import * as Yup from "yup";
import { Box, Button, Card, IconButton, Toolbar } from "@mui/material";
import { LoginDrawer } from "../../components/LoginDrawer";
import { ArrowBackRounded, MenuRounded } from "@mui/icons-material";
import { CustomLoadingIndicator } from "../../components/CustomLoadingIndicator";
import { AlertDialog } from "../../components/AlertDialog";
import { UserDataFormDialog } from "../../components/UserDataFormDialog";
import { StringFormat } from "../../models/StringFormat";
import { StatusCodes } from "http-status-codes";
import { VerifyEmailCodeDialog } from "../../components/VerifyEmailCodeDialog";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import queryString from "query-string";
import { ResponsiveIcon } from "../../components/ResponsiveIcon";
import { useGoogleLogin } from "@react-oauth/google";
import {
  useSignInWithGoogleCode,
  useSignUpWithGoogle
} from "../../hooks/users/useSignInWithGoogle";
import AppleSignin from "react-apple-signin-auth";
import { useSignInWithApple, useSignUpWithApple } from "../../hooks/users/useSignInWithApple";
import piperTextImage from "../../images/piper-text.png";
import { AppCredits } from "../../components/AppCredits";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { AcceptanceText } from "../../components/AcceptanceText";
import { useIsMobile } from "../../hooks/isMobile";
import { useSessionManager, useUser } from "../../hooks/session";
import { OAuthLoginButton } from "../../components/OAuthLoginButton";
import { useIsScreenWide } from "../../hooks/isScreenWide";
import classNames from "classnames";
import { logEvent } from "firebase/analytics";
import { Firebase } from "../../services/Firebase";

export const Login: FunctionComponent<ILoginPageProps> = ({ isMinimalist, loginCallback }) => {
  useEffect(() => {
    document.title = `${strings.app_name} - ${strings.login_submit}`;
  }, []);
  const navigate = useNavigate();
  const isScreenWide = useIsScreenWide();
  const isMobile = useIsMobile();
  const analytics = Firebase.shared.getAnalytics();
  const { login } = useLogin();
  const { setToken } = useSessionManager();
  const { signInWithGoogleCode } = useSignInWithGoogleCode();
  const { signInWithApple } = useSignInWithApple();
  const { signUpWithGoogle } = useSignUpWithGoogle();
  const { signUpWithApple } = useSignUpWithApple();
  const [currentUser] = useUser.useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenWide);
  const [isShowingGuestCard, setIsShowingGuestCard] = useState(false);
  const [isSignupDialogOpen, setIsSignupDialogOpen] = useState(false);
  const [isRecoverPasswordDialogOpen, setIsRecoverPasswordDialogOpen] = useState(false);
  const [isGoogleAcceptanceAlertOpen, setIsGoogleAcceptanceAlertOpen] = useState(false);
  const [isAppleAcceptanceAlertOpen, setIsAppleAcceptanceAlertOpen] = useState(false);
  const [oAuthToken, setOAuthToken] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState<string | undefined>(undefined);
  const [isVerifyEmailCodeDialogOpen, setIsVerifyEmailCodeDialogOpen] = useState(false);
  const [isVerifyRecoverPasswordCodeDialogOpen, setIsVerifyRecoverPasswordCodeDialogOpen] =
    useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async codeResponse => {
      const { code } = codeResponse;
      if (!code || code.length == 0) {
        showError();
        return;
      }
      setLoading(true);
      const { status, body } = await signInWithGoogleCode({
        code: code,
        validateEmail: currentUser?.email
      });
      if (StatusCodesHelper.isSuccessful(status)) {
        await processLogin(body);
      } else if (status == 515) {
        // Should register new user
        setOAuthToken(body.token);
        setIsGoogleAcceptanceAlertOpen(true);
      } else {
        showError(body.message);
        setLoading(false);
      }
    }
  });
  useEffect(() => {
    new Promise(r => setTimeout(r, 2000)).then(() => setIsShowingGuestCard(true));
    new Promise(r => setTimeout(r, 12000)).then(() => setIsShowingGuestCard(false));

    // If the URL is from a share link, login as guest automatically
    if (queryString.parse(location.search).link) {
      performLogin("guest", "guest").then();
    }
  }, []);
  const validationSchema = Yup.object({
    email: Yup.string().required(strings.field_missing),
    password: Yup.string().required(strings.password_missing)
  });
  const processLogin = async (token: IToken) => {
    setToken(token);
    if (loginCallback) {
      loginCallback();
    } else {
      if (window.location.pathname == RoutesBuilder.signIn()) {
        navigate(RoutesBuilder.home());
      } else {
        window.location.reload();
      }
    }
  };
  const performLogin = async (email: string, password: string) => {
    if (email == "" || password == "") return;
    setLoading(true);
    const { body, status } = await login({ email, password });
    if (StatusCodesHelper.isSuccessful(status)) {
      await processLogin(body);
    } else if ([StatusCodes.UNAUTHORIZED, StatusCodes.NOT_FOUND].includes(status)) {
      showError(strings.login_wrong_credentials);
    } else if (status == 510) {
      setEnteredEmail(email);
      setIsVerifyEmailCodeDialogOpen(true);
    } else {
      showError(body.message);
    }
    setLoading(false);
  };

  const showSignupDialog = () => {
    setIsSignupDialogOpen(true);
  };

  const showRecoverPasswordDialog = () => {
    setIsRecoverPasswordDialogOpen(true);
  };

  const showError = (message?: string) => {
    setAlertTitle(undefined);
    setAlertMessage(message ?? strings.login_unknown_error);
    setIsAlertOpen(true);
  };

  const registerUserWithGoogle = async (token: string) => {
    const { status, body } = await signUpWithGoogle({
      token: token
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      await processLogin(body);
      logEvent(analytics, "new_user_google");
    } else {
      showError();
      setLoading(false);
    }
  };
  const registerUserWithApple = async (token: string) => {
    const { status, body } = await signUpWithApple({
      token: token
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      await processLogin(body);
      logEvent(analytics, "new_user_apple");
    } else {
      showError();
      setLoading(false);
    }
  };
  const appleResponse = async (response: any) => {
    setLoading(true);
    const { status, body } = await signInWithApple({
      token: response.authorization.id_token,
      validateEmail: currentUser?.email
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      await processLogin(body);
    } else if (status == 515) {
      // Should register new user
      setOAuthToken(response.authorization.id_token);
      setIsAppleAcceptanceAlertOpen(true);
    } else {
      showError(body.message);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: isMinimalist ? currentUser?.email ?? "" : "",
      password: ""
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async () => {
      return;
    },
    validationSchema: validationSchema
  });

  return (
    <FormikProvider value={formik}>
      <MainContent
        className={
          isMinimalist
            ? classNames(styles.loginContainer, styles.loginContainerMinimalist)
            : styles.loginContainer
        }
        showingPersistentDrawer={isScreenWide && isDrawerOpen && !isMinimalist}
      >
        {isMinimalist ? (
          <Toolbar sx={{ width: "100%" }} />
        ) : (
          <Toolbar>
            {(!isDrawerOpen || !isScreenWide) && (
              <Button
                className={styles.drawerButton}
                sx={{ color: "white" }}
                style={{ textTransform: "none" }}
                size={"large"}
                startIcon={<MenuRounded />}
                onClick={() => setIsDrawerOpen(true)}
              >
                {strings.options}
              </Button>
            )}
            <div className={isShowingGuestCard ? styles.guestCardShown : styles.guestCardHidden}>
              <Card className={styles.guestCard}>
                <ResponsiveIcon className={styles.guestArrow} icon={ArrowBackRounded} />
                <h5 style={{ marginRight: 16 }}>{strings.login_guest_info}</h5>
              </Card>
            </div>
            <LoginDrawer
              isOpen={isDrawerOpen}
              setIsOpen={setIsDrawerOpen}
              onClickLoginAsGuest={async () => {
                await performLogin("guest", "guest");
              }}
              onClickCreateNewUser={showSignupDialog}
              onClickRecoverPassword={showRecoverPasswordDialog}
              onClickExplore={async () => {
                loginCallback = () => {
                  navigate(RoutesBuilder.marketplace());
                };
                await performLogin("guest", "guest");
              }}
            />
          </Toolbar>
        )}
        <IconButton
          style={{
            height: 80,
            width: 180,
            alignSelf: "center"
          }}
          onClick={() => {
            setIsDrawerOpen(true);
          }}
        >
          <img
            className={styles.logoImage}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={piperTextImage}
            alt={"Piper"}
          />
        </IconButton>
        <form className={styles.form}>
          <h1 style={{ color: "white" }}>
            {isMinimalist ? strings.login_again : strings.login_title}
          </h1>
          <p className={styles.subtitle}>
            {isMinimalist ? strings.login_again_subtitle : strings.login_subtitle}
          </p>
          {!isMinimalist && (
            <button
              className={styles.newUserButton}
              type={"button"}
              onClick={showSignupDialog}
              disabled={loading}
            >
              {strings.login_new_user_button}
            </button>
          )}
          <label className={styles.emailField} style={{ width: 300 }}>
            <TextField
              id="email"
              name="email"
              type="email"
              size="small"
              placeholder={strings.login_email}
              onChange={formik.handleChange}
              value={formik.values.email}
              disabled={isMinimalist || loading}
              forceWhite
            />
          </label>
          <label style={{ width: 300 }}>
            <TextField
              className={styles.textField}
              id="password"
              name="password"
              type="password"
              size="small"
              placeholder={strings.login_password}
              onChange={formik.handleChange}
              value={formik.values.password}
              disabled={loading}
              forceWhite
            />
          </label>
          <div className={styles.submitButtonContainer}>
            {loading ? (
              <CustomLoadingIndicator />
            ) : (
              <button
                onClick={async event => {
                  event.preventDefault();
                  await formik.validateForm();
                  const email = formik.values.email;
                  const password = formik.values.password;
                  await performLogin(email, password);
                }}
              >
                {strings.login_submit}
              </button>
            )}
          </div>
          {!isMinimalist && (
            <button
              className={styles.newUserButton}
              style={{ marginBottom: 12 }}
              type={"button"}
              onClick={showRecoverPasswordDialog}
              disabled={loading}
            >
              {strings.login_forgot_password}
            </button>
          )}
          <Box flexGrow={1} />
          <OAuthLoginButton
            imageSrc={"https://piper-public-images.s3.us-east-2.amazonaws.com/GoogleGLogo.svg"}
            buttonText={strings.signin_with_google}
            onClick={loginWithGoogle}
          />
          <Box sx={{ height: 10 }} />
          <AppleSignin
            authOptions={{
              clientId: process.env.REACT_APP_APPLE_CLIENT_ID ?? "",
              scope: "email",
              redirectURI: window.location.href,
              usePopup: true
            }}
            uiType="light"
            noDefaultStyle={false}
            buttonExtraChildren={strings.signin_with_apple}
            onSuccess={appleResponse}
            /** Skips loading the apple script if true */
            skipScript={false}
            render={(props: any) => (
              <OAuthLoginButton
                props={props}
                iconClassName={styles.iconApple}
                imageSrc={"https://piper-public-images.s3.us-east-2.amazonaws.com/AppleLogo.svg"}
                buttonText={strings.signin_with_apple}
              />
            )}
            onError={() => {
              // Do nothing
            }}
          />
          <UserDataFormDialog
            open={isSignupDialogOpen}
            setOpen={setIsSignupDialogOpen}
            callback={email => {
              setAlertTitle(strings.signup_success_title);
              setAlertMessage(StringFormat(strings.signup_success_message, email));
              setIsAlertOpen(true);
            }}
            title={strings.signup_title}
            subtitle={strings.signup_subtitle}
            description={strings.signup_description}
            behavior={"signup"}
          />
          <UserDataFormDialog
            open={isRecoverPasswordDialogOpen}
            setOpen={setIsRecoverPasswordDialogOpen}
            callback={(email, password) => {
              setEnteredEmail(email);
              setNewPassword(password);
              setIsVerifyRecoverPasswordCodeDialogOpen(true);
            }}
            title={strings.login_recover_password}
            subtitle={strings.recover_password_form_title}
            description={strings.recover_password_form_subtitle}
            behavior={"recoverPassword"}
          />
          <VerifyEmailCodeDialog
            email={enteredEmail}
            open={isVerifyEmailCodeDialogOpen}
            setOpen={setIsVerifyEmailCodeDialogOpen}
            callback={async () => {
              await performLogin(formik.values.email, formik.values.password);
            }}
          />
          <VerifyEmailCodeDialog
            email={enteredEmail}
            password={newPassword}
            open={isVerifyRecoverPasswordCodeDialogOpen}
            setOpen={setIsVerifyRecoverPasswordCodeDialogOpen}
            callback={() => {
              setAlertTitle(strings.signup_success_title);
              setAlertMessage(strings.recover_password_success);
              setIsAlertOpen(true);
            }}
          />
          <AlertDialog
            title={alertTitle}
            message={alertMessage}
            open={isAlertOpen}
            setOpen={setIsAlertOpen}
          />
          <AlertDialog
            message={<AcceptanceText withIntro />}
            open={isGoogleAcceptanceAlertOpen}
            setOpen={setIsGoogleAcceptanceAlertOpen}
            isConfirm
            onConfirm={() => registerUserWithGoogle(oAuthToken)}
            onCancel={() => setLoading(false)}
          />
          <AlertDialog
            message={<AcceptanceText withIntro />}
            open={isAppleAcceptanceAlertOpen}
            setOpen={setIsAppleAcceptanceAlertOpen}
            isConfirm
            onConfirm={() => registerUserWithApple(oAuthToken)}
            onCancel={() => setLoading(false)}
          />
        </form>
        {!isMinimalist && (
          <AppCredits
            className={isMobile ? styles.creditsMobile : styles.credits}
            textClassName={styles.creditsText}
          />
        )}
      </MainContent>
    </FormikProvider>
  );
};

export interface ILoginPageProps {
  isMinimalist: boolean;
  loginCallback?: () => void;
}
