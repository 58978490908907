import { es } from "date-fns/locale";
import { doc_albums_es } from "./documentation/albums/Home/strings.es";
import { doc_import_from_discogs_es } from "./documentation/albums/ImportFromDiscogs/strings.es";
import { terms_es } from "./documentation/TermsOfService/strings.es";
import { privacy_policy_es } from "./documentation/PrivacyPolicy/strings.es";
import { doc_filter_albums_es } from "./documentation/albums/FilterAlbums/strings.es";
import { doc_order_albums_es } from "./documentation/albums/OrderAlbums/strings.es";
import { doc_home_es } from "./documentation/Home/strings.es";
import { doc_subscriptions_es } from "./documentation/subscriptions/Home/strings.es";
import { doc_mercado_pago_subscriptions_es } from "./documentation/subscriptions/MercadoPago/strings.es";
import { about_piper_es } from "./documentation/AboutPiper/strings.es";
import { doc_users_sign_up_es } from "./documentation/users/SignUp/strings.es";
import { doc_users_guest_es } from "./documentation/users/SignInAsGuest/strings.es";
import { doc_users_recover_password_es } from "./documentation/users/RecoverPassword/strings.es";
import { doc_albums_add_barcode_es } from "./documentation/albums/AddFromBarcode/strings.es";
import { doc_image_gallery_es } from "./documentation/albums/ImageGallery/strings.es";
import { doc_user_profile_es } from "./documentation/userProfile/Home/strings.es";
import { doc_import_from_excel_es } from "./documentation/albums/ImportFromExcel/strings.es";
import { doc_remove_filtered_albums_es } from "./documentation/albums/RemoveFilteredAlbums/strings.es";
import { doc_collections_public_collections_es } from "./documentation/collections/PublicCollections/strings.es";
import { doc_collections_es } from "./documentation/collections/Home/strings.es";
import { doc_collections_attributes_es } from "./documentation/collections/Attributes/strings.es";
import { doc_artists_es } from "./documentation/albumAttributes/Artists/strings.es";
import { doc_album_attributes_es } from "./documentation/albumAttributes/Home/strings.es";
import { doc_marking_albums_es } from "./documentation/albums/MarkingAlbums/strings.es";
import { esES as esEsDatePicker } from "@mui/x-date-pickers/locales/esES";

export const strings_es = {
  locale: "es-ES",
  currencyLocale: "es-AR",
  fnsLocale: es,
  datePickerLocale: esEsDatePicker,
  minDate: "1800-01-01",
  app_name: "Piper",

  /* General */
  yes: "Sí",
  no: "No",
  none: "Ninguno",
  menu: "Menú",
  save: "Guardar",
  close: "Cerrar",
  delete: "Eliminar",
  expand: "Expandir",
  shrink: "Contraer",
  discount: "OFF",
  new: "Nuevo",
  horizontal: "Horizontal",
  vertical: "Vertical",
  help: "Ayuda",
  refresh_list: "Recargar lista",
  alert_accept: "Aceptar",
  alert_cancel: "Cancelar",
  alert_confirm: "Confirmar",
  alert_modify: "Modificar",
  alert_delete: "Eliminar",
  alert_exit: "Salir",
  alert_cancel_confirmation: "¿Querés cancelar la operación?",
  session_expired: "Tu sesión expiró. Por favor ingresá nuevamente.",
  album_singular: "álbum",
  albums_plural: "álbumes",
  disc_singular: "disco",
  plural_indicator: "s",
  search: "Buscar",
  unknown: "Desconocido",
  mint: "Mint",
  missing_purchase_date: "Falta la fecha de compra",
  date_missing: "Seleccionar fecha",
  field_missing: "Tenés que completar el campo",
  field_missing_short: "Completá esto",
  field_invalid: "El campo es inválido",
  not_number_error: "Este campo tiene que ser un número",
  open_on_discogs: "Ver en Discogs",
  open_on_private_collection: "Ver en mi colección privada",
  mark_as_title: "Marcar como...",
  remove_mark_title: "Quitar marca",
  refresh_image: "Recargar imagen",
  clear_value: "Limpiar valor",
  clear_values: "Limpiar valores",
  start_date: "Fecha inicio",
  end_date: "Fecha fin",
  generic_attribute_filter_description: "Ingresá el valor que estás buscando:",
  generic_track_name: "Nueva canción",
  no_value: "Sin valor",
  currency_title: "Moneda",
  share_link_notification: "¡Link copiado al portapapeles!",
  image_upload_success: "¡Imagen actualizada exitosamente!",
  image_upload_error: "Ocurrió un error actualizando la imagen. Por favor intentá nuevamente.",
  upload_multiple_files_error: "Por favor seleccioná solamente un archivo.",
  upload_multiple_images_error: "Por favor seleccioná solamente una imagen.",
  album_update_success: "¡Álbum actualizado exitosamente!",
  album_update_error: "Ocurrió un error actualizando el álbum. Por favor intentá nuevamente.",
  album_created: "¡El álbum se agregó correctamente!",
  album_deleted: "El álbum se eliminó correctamente",
  generic_update_success: "La operación fue exitosa",
  generic_update_error: "No se pudo completar la operación, por favor intentá de nuevo más tarde.",
  clear_filter: "Limpiar filtro",
  clear_search: "Limpiar búsqueda",
  order_warning: "⚠️ Sin órden",
  back_to_app: "Volver a la aplicación",
  general_queries: "Si tenés alguna duda por favor contactanos:",
  network_online_message: "¡Volvió la conexión a internet!",
  network_offline_message: "Parece que no estás conectado a internet. Chequeá tu conexión.",
  qr_code: "Código QR",
  qr_code_public_title: "Código QR público",
  qr_code_public_message: "Podés compartir este código QR con otras personas",
  qr_code_private_title: "Código QR privado",
  qr_code_private_message: "Este código QR sólo te va a funcionar a vos",
  download: "Descargar",
  toggle_style: "Cambiar estilo",
  no_results: "La búsqueda no trajo resultados",
  edit_profile_description: "Ingresá el perfil debajo:",
  edit_description_description: "Ingresá la descripción debajo:",
  about_app_title: "Acerca de",
  dropzone_placeholder: "Arrastrá un archivo, o tocá para seleccionar uno.",
  more_info: "Más información",
  optional_attributes: "Atributos opcionales",
  push_notifications_permission_notice: "¿Te gustaría recibir notificaciones de Piper?",

  /* Policies */
  privacy_policy_link: "Política de Privacidad",
  terms_link: "Términos de Servicio",

  /* MobileAlert */
  greetings: "¡Hola!",
  not_found_subtitle: "No pudimos encontrar lo que estás buscando",
  not_found_message: "Hacé clic en este botón para volver a la aplicación:",
  not_found_queries: "Si creés que esto es un error o tenés alguna pregunta, por favor comunicate:",

  /* MaintenancePage */
  maintenance_message:
    "La aplicación se encuentra en mantenimiento, pronto va a estar nuevamente disponible.",

  /* SignupViewController */
  signup_title: "Nuevo usuario",
  signup_subtitle: "Ingresá estos datos",
  signup_description: "Vas a necesitar confirmar la dirección de correo electrónico",
  signup_repeat_password_placeholder: "Repetí la contraseña",
  signup_success_title: "¡Tu usuario está listo!",
  signup_success_message:
    "Revisá tu bandeja de entrada e ingresá el código alfanumérico que te enviamos al momento de ingresar a la aplicación.\n\nEnviamos el código de verificación a {0}",
  recover_password_form_title: "Ingresá tu correo electrónico y una nueva contraseña",
  recover_password_form_subtitle:
    "Te vamos a enviar un código que vas a ingresar en el próximo paso",
  recover_password_email_not_found:
    "Ese correo electrónico no está registrado, podés usarlo para crear un nuevo usuario.",

  /* CreateAlbumViewController */
  ca_title: "Nuevo álbum",
  ca_all_fields: "todos los campos requeridos",
  ca_name: "el nombre",
  ca_artist: "el artista",
  ca_country: "el país",
  ca_catalogue_number: "el número de catálogo",
  ca_color: "el color",
  ca_store: "la tienda",
  ca_label: "el sello",
  ca_missing_message: "Tenés que completar {0} del nuevo álbum.",
  new_box_set_title: "Nuevo box set",

  /* AlbumsTableViewController */
  add_album: "Agregar álbum",
  add_box_set: "Agregar box set",
  album_title: "Álbum",
  albums_title: "Mis álbumes",
  option_attributes: "Atributos",
  option_stats: "Estadísticas",
  option_marketplace: "Exhibición",
  option_close_session: "Cerrar sesión",
  option_preferences: "Preferencias",
  option_refresh_albums: "Actualizar álbumes",
  option_fetch_all_albums: "Traer todos los álbumes",
  option_reorder_albums: "Reordenar álbumes",
  option_filter_albums: "Filtrar álbumes",
  option_show_index: "Mostrar índice",
  option_random_albums: "Álbumes aleatorios",
  option_reel: "Vista de carrete",
  option_search_albums: "Buscar álbumes",
  option_view_detail: "Ver detalle",
  option_discogs_import: "Importar desde Discogs",
  option_excel_import: "Importar desde planilla de cálculo",
  option_scan_barcode: "Agregar desde código de barras o búsqueda",
  random_albums_message: "Ingresá la cantidad de álbumes:",
  play_from_youtube: "Reproducir desde YouTube",
  album_delete_all: "Eliminar todos los álbumes filtrados",
  album_delete_all_confirmation_message:
    "Estás por eliminar TODOS tus álbumes en la colección actual. ¡ESTO NO PUEDE DESHACERSE! Si estás seguro de querer hacer esto, por favor escribí 'Eliminar todos los álbumes' abajo y confirmá.",
  album_delete_all_filter_confirmation_message:
    "Estás por eliminar TODOS los álbumes filtrados en la colección actual. ¡ESTO NO PUEDE DESHACERSE! Los álbumes que caen fuera del filtro no van a modificarse. Si estás seguro de querer hacer esto, por favor escribí 'Eliminar todos los álbumes' abajo y confirmá.",
  album_delete_all_placeholder: "Eliminar todos los álbumes",
  album_delete_confirmation:
    "¿Estás seguro de querer eliminar el álbum {0}? Esto va a eliminar el álbum de TODAS las colecciones y no es reversible. Si solamente querés eliminarlo de una colección podés hacerlo desde el detalle del álbum en la lista de colecciones.",
  box_set_delete_confirmation:
    "¿Estás seguro de querer eliminar el box set {0}? Esto va a eliminar el box set de TODAS las colecciones y no es reversible. Si solamente querés eliminarlo de una colección podés hacerlo desde el detalle del box set en la lista de colecciones.\n\nEsto no va a eliminar ningún álbum que pertenezca al box set.",
  album_remove_from_box_set_confirmation:
    "¿Estás seguro de querer eliminar el álbum {0} de este box set? Esto no va a eliminar el álbum propiamente dicho o sus atributos.",
  album_remove_from_box_set: "Eliminar del box set",
  no_albums_error: "Agregá algún álbum para poder usar esta funcionalidad.",
  options: "Opciones",
  album_options: "Opciones de álbumes",
  album_sleeve_grade: "Sobre:",
  box_set_sleeve_grade: "Caja:",
  album_media_grade: "Disco:",
  open_drawer: "Abrir menú",
  open_attributes_list: "Abrir lista de atributos",
  open_albums_list: "Abrir lista de álbumes",
  add_attribute: "Agregar nuevo",
  album_highlighted: "Destacado",
  album_sold: "Vendido",
  album_upcoming: "Próximamente",
  album_new: "Novedad",
  album_reserved: "Reservado",

  /* AlbumDetailViewController */
  exchange_rate_title: "Cambio de moneda",
  sell_value_title: "Valor de venta",
  sell_value_detail: "Grupo {0} ({1} USD)",
  purchase_price_title: "Precio de compra",
  purchase_price_detail: "Precio al momento de la compra: {0}",
  purchase_price_detail_dollars: "Precio al momento de la compra: {0} ({1})",
  purchase_price_placeholder: "Precio al momento de la compra",
  purchase_price_message: "Ingresá el precio al momento de la compra del álbum:",
  artist_title: "Artista",
  label_title: "Sello",
  original_year_title: "Año original",
  original_year_message: "Ingresá el año original del álbum:",
  release_year_title: "Año de edición",
  release_year_message: "Ingresá el año de esta edición:",
  country_title: "País de origen",
  country_short_title: "País",
  country_message: "Ingresá el país de origen de esta edición:",
  sleeve_grade_title: "Graduación del sobre",
  sleeve_grade_box_set_title: "Graduación de la caja",
  media_grade_plural_title: "Graduación de los discos",
  media_grade_singular_title: "Graduación del disco",
  revenue_title: "Ganancia",
  purchase_date_title: "Fecha de compra",
  store_title: "Tienda",
  catalogue_number_title: "Número de catálogo",
  catalogue_number_message: "Ingresá el número de catálogo de esta edición:",
  disc_count_title: "Cantidad de discos",
  disc_count_message: "Ingresá la cantidad de discos:",
  disc_color_plural_title: "Color de los discos",
  disc_color_singular_title: "Color del disco",
  disc_color_plural_message: "Ingresá el color de los discos:",
  disc_color_singular_message: "Ingresá el color del disco:",
  album_format_title: "Formato",
  album_type_title: "Tipo de álbum",
  add_track: "Agregar canción",
  track_count_title: "Cantidad de canciones",
  track_count_title_big: "CANCIONES",
  track_count_message: "Ingresá la cantidad de canciones:",
  album_length_title: "Duración",
  album_length_title_big: "DURACIÓN",
  album_length_message: "Ingresá la duración del álbum:",
  discogs_id_title: "ID de Discogs",
  discogs_id_title_big: "ID DE DISCOGS",
  discogs_id_message: "Ingresá el ID de release de Discogs:",
  impact_title: "Calificación",
  random_title: "Aleatorio",
  notes_title: "Notas",
  notes_detail: "Ingresá notas para el álbum:",
  notes_placeholder: "Notas del álbum",
  edit_album_name: "Editar nombre",
  change_artist: "Cambiar artista",
  change_label: "Cambiar sello",
  name_title: "Nombre",
  name_detail: "Ingresá el nombre del álbum:",
  name_placeholder: "Ejemplo: Slow Turning",
  box_set_name_placeholder: "Ejemplo: The Definitive Collection",
  artist_placeholder: "Ejemplo: John Hiatt",
  country_placeholder: "Ejemplo: USA",
  original_year_placeholder: "Ejemplo: 1988",
  release_year_placeholder: "Ejemplo: 2018",
  catalogue_number_placeholder: "Ejemplo: B0027601-01",
  disc_color_placeholder: "Ejemplo: Negro",
  impact_placeholder: "\u2606\u2606\u2606\u2606\u2606",
  sleeve_grade_placeholder: "Ejemplo: VG+",
  media_grade_placeholder: "Ejemplo: NM",
  no_album_selected: "Seleccioná un álbum para ver su detalle",
  album_no_collections: "Este álbum todavía no tiene ninguna colección en particular asociada",
  album_no_location: "Este álbum todavía no tiene ubicación",
  share_album: "Compartir álbum",
  get_private_link: "Obtener link privado",
  copy_album: "Copiar álbum",
  copy_box_set: "Copiar box set",
  album_shared_loading: "Cargando contenido...",

  /* Image Gallery */
  image_gallery: "Galería de imágenes",
  add_new_image: "Agregar imagen",
  front_cover: "Tapa",
  back_cover: "Contratapa",
  spine: "Lomo",
  image_name: "Nombre de la imagen",
  image_name_message: "Ingresá un nombre para esta imagen. Sólo puede tener 30 caracteres o menos.",
  image_name_placeholder: "Ejemplo: Frente del insert",
  option_view_image: "Ver detalle de la imagen",
  modify_image: "Editar imagen",
  move_left: "Mover a la izquierda",
  move_right: "Mover a la derecha",
  move_up: "Mover arriba",
  move_down: "Mover abajo",
  edit_image_name: "Editar nombre",
  image_delete_confirmation: "¿Estás seguro de querer eliminar esta imagen?",

  /* TrackListDialog */
  track_list_title: "Lista de canciones",
  fetch_from_discogs: "Traer desde Discogs",
  fetch_from_discogs_disabled:
    "Tenés que completar el Discogs ID de este álbum para usar esta funcionalidad",
  track_extra_artists_placeholder: "Créditos de la canción",
  track_name_title: "Nombre",
  track_name_description: "Ingresá el nombre de la canción:",
  track_name_placeholder: "Ejemplo: Telegraph Road",
  track_position_title: "Posición",
  track_position_description: 'Ingresá la posición de la canción "{0}":',
  track_position_placeholder: "Ejemplo: A1",
  track_length_title: "Duración",
  track_length_description: 'Ingresá la duración de la canción "{0}":',
  track_extra_artists_title: "Créditos",
  track_extra_artists_description: 'Ingresá los créditos de la canción "{0}":',
  fetch_tracks_from_discogs_confirmation:
    "¿Estás seguro de querer traer la lista de canciones de este álbum desde Discogs? Esto va a sobreescribir cualquier cambio que hayas hecho a la lista de canciones.",

  /* AlbumsFilterViewController */
  order_by_title: "Ordenado por",
  order_direction_title: "Dirección",
  order_desc_title: "Descendente",
  order_asc_title: "Ascendente",
  filter_is: "Es",
  filter_is_not: "No es",
  filter_album_search_text_title: "TEXTO DE BÚSQUEDA",
  filter_album_format_title: "FORMATO",
  filter_album_type_title: "TIPO DE ÁLBUM",
  filter_tag_title: "ETIQUETA",
  filter_gatefold_title: "GATEFOLD",
  filter_insert_title: "CON INSERT",
  filter_lyrics_title: "CON LETRAS",
  filter_clean_title: "ESCUCHADO",
  filter_box_set_title: "BOX SET",
  filter_artist_title: "ARTISTA",
  filter_store_title: "TIENDA",
  filter_label_title: "SELLO",
  filter_impact_title: "CALIFICACIÓN",
  filter_sleeve_grade_title: "GRADUACIÓN DEL SOBRE",
  filter_media_grade_title: "GRADUACIÓN DEL DISCO",
  filter_sell_value_title: "VALOR DE VENTA",
  filter_country_title: "PAÍS DE ORIGEN",
  filter_color_title: "COLOR",
  filter_original_year_title: "AÑO ORIGINAL",
  filter_release_year_title: "AÑO DE EDICIÓN",
  filter_sell_status_title: "MARCADO COMO...",
  filter_confirm: "Aplicar filtro",
  sort_by_sell_value: "Valor de venta (Nombre)",
  sort_by_sell_value_dollars: "Valor de venta (Valor)",
  add_to_filter: "Agregar al filtro",
  remove_from_filter: "Remover del filtro",

  /* AttributeListCollectionViewController */
  filter_left_title: "Filtros disponibles",
  filter_right_title: "Filtro a aplicar",
  attribute_artists_title: "Artistas",
  attribute_stores_title: "Tiendas",
  attribute_labels_title: "Sellos",
  attribute_exchange_rates_title: "Cambios de moneda",
  attribute_sell_values_title: "Valores de venta",
  attribute_impacts_title: "Calificaciones",
  attribute_sleeve_grades_title: "Graduaciones de sobres",
  attribute_media_grades_title: "Graduaciones de discos",
  attribute_album_types_title: "Tipos de álbum",
  attribute_album_formats_title: "Formatos",
  artist_edit: "Editar artista",
  artist_view: "Ver artista",
  label_edit: "Editar sello",
  label_view: "Ver sello",
  empty_artists: "Todavía no tenés artistas",
  empty_stores: "Todavía no tenés tiendas",
  empty_tags: "Todavía no tenés etiquetas",
  empty_labels: "Todavía no tenés sellos",
  empty_exchange_rates: "Todavía no tenés cambios de moneda",
  empty_sell_values: "Todavía no tenés valores de venta",
  empty_impacts: "No se pudieron cargar las calificaciones",
  empty_sleeve_grades: "No se pudieron cargar las graduaciones de sobres",
  empty_media_grades: "No se pudieron cargar las graduaciones de discos",
  empty_album_types: "Todavía no tenés tipos de álbum",
  empty_album_formats: "Todavía no tenés formatos",
  empty_artists_public: "No hay artistas para filtrar",
  empty_stores_public: "No hay tiendas para filtrar",
  empty_tags_public: "No hay etiquetas para filtrar",
  empty_labels_public: "No hay sellos para filtrar",
  empty_exchange_rates_public: "No hay cambios de moneda para filtrar",
  empty_sell_values_public: "No hay valores de venta para filtrar",
  empty_album_types_public: "No hay tipos de álbumes para filtrar",
  empty_album_formats_public: "No hay formatos para filtrar",
  empty_collections: "Todavía no tenés colecciones",
  empty_attribute_description: "¡Empezá a agregar para que aparezcan en esta lista!",
  empty_search_title: `No hay resultados sobre "{0}"`,
  empty_search_description: "Revisá la ortografía o intentá hacer otra búsqueda",
  empty_user_notifications: "No tenés notificaciones nuevas",
  add_exchange_rate_by_time_lapse: "Agregar por lapso de tiempo",
  add_exchange_rate_by_time_lapse_disabled:
    "Para agregar cambios de moneda por lapso de tiempo por favor mejorá la suscripción de tu cuenta.",

  /* Email General */
  email_used: "Este correo ya está en uso ❌",
  email_valid: "Este correo está disponible ✔️",
  email_invalid: "La dirección ingresada no parece válida ❌",
  email_error_invalid:
    "La dirección de correo electrónico no es válida. Por favor revisala e intentá nuevamente.",
  email_error_duplicated: "El correo electrónico ingresado ya está en uso por otro usuario.",

  /* Password General */
  password_very_weak: "La contraseña es muy débil ❌",
  password_weak: "La contraseña es débil ❌",
  password_valid: "La contraseña es aceptable ✔️",
  password_strong: "La contraseña es fuerte ✔️",
  password_match: "Las contraseñas coinciden ✔️",
  password_mismatch: "Las contraseñas no coinciden ❌",
  password_missing: "Ingresá tu contraseña.",
  password_repeat_missing: "Repetí la contraseña que ingresaste en el último campo.",
  password_mismatch_error:
    "Las constraseñas no coinciden. Por favor revisalo e intentá nuevamente.",
  password_weak_error:
    "La contraseña ingresada no cumple con los requisitos mínimos. Debe tener un mínimo de 8 caracteres y contener minúsculas, mayúsculas, números y/o caracteres especiales. Por favor revisala e intentá nuevamente.",

  /* LoginViewController */
  login_unknown_error: "No pudimos ingresarte. Por favor intentá de nuevo más tarde.",
  login_create_user: "Crear nuevo usuario",
  login_create_user_or_login: "Crear nuevo usuario o ingresar",
  login_guest: "Ingresar como invitado",
  login_preferences: "Preferencias",
  login_again: "Volver a ingresar",
  login_again_subtitle: "Necesitamos que vuelvas a ingresar tu contraseña",
  login_again_message:
    "Necesitamos que vuelvas a ingresar tus credenciales para realizar esta acción",
  login_wrong_credentials:
    "Las credenciales son incorrectas. Por favor verificá los datos ingresados e intentá nuevamente.",
  login_title: "¡Bienvenido!",
  login_email: "Usuario o correo",
  login_password: "Contraseña",
  login_submit: "Ingresar",
  login_subtitle: "Ingresá tus credenciales o bien",
  login_new_user_button: "creá un nuevo usuario",
  login_guest_info: "También podés ingresar como invitado",
  login_recover_password: "Recuperar contraseña",
  login_forgot_password: "¿Olvidaste tu contraseña?",
  guest_title: "Invitado",
  signin_with_google: "Continuar con Google",
  signin_with_apple: "Continuar con Apple",
  signup_email: "Correo electrónico",
  signup_oauth_intro: `¡Estás a un paso de crear tu nueva cuenta! Por favor confirmá que estás de acuerdo con lo siguiente:
    `,
  signup_accept_first: "Acepto la ",
  signup_accept_second: " y estoy de acuerdo con los ",
  signup_accept_third: ", y soy mayor de 13 años.",

  /* SignupSuccessViewController */
  verification_code_sent: "Enviamos el código a\n{0}",

  /* ArtistDetailViewController */
  artist_death_date: "FECHA DE MUERTE",

  /* AVAudioPlayerViewController */
  player_unknown_error: "Ocurrió un error inesperado",
  player_autoplay_on: "Reproducción automática activada",
  player_autoplay_off: "Reproducción automática desactivada",

  /* SettingsTableViewController */
  settings_user_title: "Usuario",
  settings_preferences_title: "Preferencias",
  settings_advanced_title: "Aplicación",
  settings_cache_confirmation:
    "Estás por eliminar todas las imágenes y datos guardados en la memoria caché.",
  settings_should_show_impact_descriptions: "Mostrar descripción de calificaciones",
  settings_should_show_album_id: "Mostrar ID del álbum",
  settings_order_new_albums: "Ordenar automáticamente álbumes nuevos",
  settings_show_decimals: "Mostrar decimales en ciertos importes financieros",
  settings_receive_email_notifications: "Recibir notificaciones por correo",
  my_external_systems_title: "Apps ya asociadas",
  catering_external_systems_title: "Apps disponibles",
  confirm_external_system_deletion: '¿Estás seguro de querer desasociar la app "{0}"?',

  /* EmailTableViewController */
  email_current_title: "Correo electrónico actual",
  email_pending_title: "Pendiente de confirmación",
  email_confirm_new: "Confirmar correo nuevo",
  email_discard_new: "Descartar correo nuevo",
  email_new_title: "Nuevo correo electrónico",
  email_confirmed: "Confirmado ✔️",
  email_pending: "Pendiente ⚠️",

  /* ChangePasswordViewController */
  password_new_mismatch: "La contraseña actual no coincide, por favor revisala.",
  password_update_success: "La contraseña fue actualizada con éxito.",
  password_update_error_current: "Ingresá tu contraseña actual.",
  password_update_error_new: "Ingresá una nueva contraseña.",
  password_update_error_repeat: "Repetí la nueva contraseña.",

  /* VerifyEmailCodeViewController */
  vec_title: "Verificar correo",
  vec_subtitle: "Revisá tu bandeja de entrada e ingresá el código que te enviamos",
  vec_code_placeholder: "Código",
  vec_resend_code: "Reenviar código de verificación",
  vec_confirmation:
    "Se envió un nuevo código de verificación y debería llegar en los próximos minutos, revisá tu bandeja de entrada.",
  vec_invalid_code: "El código ingresado es incorrecto. Revisalo e intentá nuevamente.",
  vec_success: "Tu nueva dirección de correo electrónico fue confirmada con éxito.",
  recover_password_success: "¡Tu contraseña fue modificada con éxito!",

  /* StatsViewController */
  stats_clean_title: "ESCUCHADOS",
  stats_gatefold_title: "GATEFOLD",
  stats_insert_title: "CON INSERT",
  stats_lyrics_title: "CON LETRAS",
  stats_impacts_title: "CALIFICACIONES",
  stats_sleeve_grades_title: "GRADUACIÓN DE SOBRES",
  stats_media_grades_title: "GRADUACIÓN DE DISCOS",
  stats_pie_yes: "SÍ",
  stats_pie_no: "NO",
  stats_last_updated: "Última actualización",
  stats_loading: "Esto puede demorar un poco para colecciones grandes, tené paciencia por favor...",
  stats_error:
    "Hubo un error trayendo las estadísticas de esta colección. Por favor intentá de nuevo más tarde.",

  /* Attributes General */
  attributes_title: "Atributos",

  /* ModifyExchangeRateViewController */
  mer_new_title: "Nuevo cambio de moneda",
  mer_delete_confirmation: "¿Querés eliminar este cambio de moneda?",
  mer_format_error: "Revisá que el factor de conversión sea un número real.",
  mer_rate_title: "FACTOR DE CONVERSIÓN (USD -> {0})",
  mer_rate_placeholder: "1 USD equivale a # {0}",
  mer_date_title: "FECHA",
  date_range_title: "Rango de fechas",
  mer_tl_title: "Obtener cambios de moneda automáticamente",
  mer_tl_description:
    "Ingresá un rango de fechas a continuación y vamos a cargar automáticamente un cambio de moneda cada 7 días. Asegurate de no exceder un rango de 1 año. Si querés cargar más de 1 año de cambios de moneda, por favor repetí esta operación las veces que sean necesarias.",

  /* ModifyLabelViewController */
  ml_new_title: "Nuevo sello",
  ml_delete_confirmation: "¿Querés eliminar este sello?",
  ml_missing_error: "Completá el nombre del nuevo sello.",
  ml_placeholder: "Ejemplo: Asylum Records",
  ml_field_title: "NOMBRE DEL SELLO",

  /* ModifyStoreViewController */
  ms_new_title: "Nueva tienda",
  ms_delete_confirmation: "¿Querés eliminar esta tienda?",
  ms_missing_error: "Completá el nombre de la nueva tienda.",
  ms_placeholder: "Ejemplo: Ebay",
  ms_field_title: "NOMBRE DE LA TIENDA",
  ms_address_title: "DIRECCIÓN DE LA TIENDA",
  ms_address_placeholder: "Dirección de la tienda",

  /* ModifySellValueViewController */
  msv_new_title: "Nuevo valor de venta",
  msv_delete_confirmation: "¿Querés eliminar este valor de venta?",
  msv_missing_error: "Completá todos los campos.",
  msv_group_title: "NOMBRE DEL GRUPO",
  msv_group_placeholder: "Ejemplos: A, 4, 3.2",
  msv_dollars_title: "VALOR [USD]",
  msv_dollars_placeholder: "Valor de venta en USD",
  msv_description_title: "DESCRIPCIÓN DEL GRUPO",
  msv_description_placeholder: "Ejemplo: Álbumes raros en buen estado",
  msv_group: "Grupo",

  /* ModifyArtistViewController */
  ma_new_title: "Nuevo artista",
  ma_delete_confirmation: "¿Querés eliminar este artista?",
  ma_missing_error: "Completá el nombre del artista.",
  ma_name_title: "NOMBRE ARTÍSTICO",
  ma_name_placeholder: "Ejemplo: Little Richard",
  ma_real_name_title: "NOMBRE REAL",
  ma_real_name_placeholder: "Ejemplo: Richard Wayne Penniman",
  ma_website_title: "PÁGINA WEB",
  ma_website_button_title: "Visitar página web",
  ma_website_placeholder: "Ejemplo: https://piper.ldelosheros.com",
  ma_discogs_id_title: "ID DE DISCOGS",
  ma_discogs_id_placeholder: "Ejemplo: 12345",
  ma_profile_title: "PERFIL",
  ma_profile_placeholder: "Perfil",
  ma_birth_date_title: "FECHA DE NACIMIENTO",
  ma_death_date_title: "FECHA DE MUERTE",

  /* ModifyTimeLengthAlertController */
  mtl_title: "Duración",
  mtl_message: "Ingresá la duración del álbum:",
  mtl_missing_error: "Completá la duración del álbum.",
  mtl_calculate: "Calcular desde las canciones",

  /* ModifyAlbumTypeViewController */
  mat_new_title: "Nuevo tipo de álbum",
  mat_delete_confirmation: "¿Querés eliminar este tipo de álbum?",
  mat_missing_error: "Completá el nombre del tipo de álbum.",
  mat_field_title: "NOMBRE DEL TIPO DE ÁLBUM",
  mat_placeholder: "Ejemplos: Estudio, Compilado",
  mat_show_on_album_list_title: "MOSTRAR EN LA LISTA DE ÁLBUMES",

  /* ModifyAlbumFormatViewController */
  maf_new_title: "Nuevo formato",
  maf_delete_confirmation: "¿Querés eliminar este formato?",
  maf_missing_error: "Completá el nombre y nombre corto del formato.",
  maf_name_title: "NOMBRE",
  maf_name_placeholder: "Ejemplo: Long Play",
  maf_short_name_title: "NOMBRE CORTO",
  maf_short_name_placeholder: "Ejemplo: LP",

  /* Album contents */
  album_contents_title: "Contenido",
  add_content: "Agregar contenido",
  album_content_disc: "Disco",
  album_content_other: "Otro",
  playback_speed: "Velocidad de reproducción",
  album_content_quantity: "Cantidad",
  album_content_type: "Tipo de contenido",
  album_content_delete_confirmation: "¿Estás seguro de querer eliminar este contenido del álbum?",

  /* Tags */
  tag_title: "Etiqueta",
  new_tag_title: "Nueva etiqueta",
  tags_title: "Etiquetas",

  /* UpdateEmailDialog */
  update_email_title: "Correo",
  new_email_title: "NUEVO CORREO ELECTRÓNICO",
  new_email_placeholder: "Nuevo correo electrónico",
  email_pending_message: "Revisá tu bandeja de entrada, enviamos un código de verificación a {0}",

  /* UpdatePasswordDialog */
  update_password_title: "Contraseña",
  old_password_title: "CONTRASEÑA ACTUAL",
  old_password_placeholder: "Contraseña actual",
  new_password_title: "NUEVA CONTRASEÑA",
  new_password_placeholder: "Nueva contraseña",
  repeat_password_placeholder: "Repetí la nueva contraseña",

  /* Stats */
  stats_general_title: "General",
  stats_financials_title: "Finanzas",
  stats_album_total: "Álbumes",
  stats_box_set_total: "Box sets",
  stats_clean_total: "Álbumes escuchados",
  stats_gatefold_total: "Álbumes gatefold",
  stats_insert_total: "Álbumes con insert",
  stats_lyrics_total: "Álbumes con letra",
  stats_colored_total: "Álbumes de color",
  stats_average_original_year: "Año original promedio",
  stats_average_release_year: "Año de edición promedio",

  stats_discs_total: "Cantidad de discos",
  stats_length_total: "Duración total",
  stats_average_album_length: "Duración álbum promedio",
  stats_average_disc_length: "Duración disco promedio",
  stats_average_track_length: "Duración canción promedio",
  stats_tracks_by_album: "Canciones por álbum",

  stats_artist_total: "Cantidad de artistas",
  stats_artist_most_albums: "Artista con más álbumes",
  stats_store_most_albums: "Tienda con más álbumes",
  stats_label_most_albums: "Sello con más álbumes",

  stats_purchase_price_total: "Precio de compra total",
  stats_sell_value_total: "Valor de venta total",
  stats_revenue_total: "Ganancia total",

  /* DiscogsImport */
  import_from_discogs: "Importar desde Discogs",
  discogs_import_collection_folders_title: "Carpetas de Discogs",
  discogs_import_collection_import: "Importación desde Discogs",
  discogs_import_cancel_confirmation:
    "¿Estás seguro de querer cancelar el proceso de importación? Solamente vas a perder los álbumes que todavía no fueron importados.",
  discogs_import_subtitle: "¡Estamos importando tu colección!",
  discogs_import_description:
    "Podés seguir el progreso desde esta pantalla. El proceso puede tomarse descansos de hasta un minuto de vez en cuando. Esto se debe a las limitaciones impuestas por el servicio de Discogs y es completamente normal.",
  discogs_import_info:
    'Estamos importando todos los álbumes de la carpeta "{0}", con un total de {1} álbumes.',
  cancel_import: "Cancelar proceso",
  discogs_folders_subtitle: "Seleccioná la carpeta desde la cual importar los álbumes",
  discogs_folders_description:
    "Después vas a poder elegir a qué colección de Piper importarlos\n\nSi querés podés cancelar el proceso ahora y dirigirte a Discogs para modificar las carpetas de tu colección",
  discogs_folders_confirmation:
    'Estás a punto de importar {0} álbumes desde la carpeta "{1}" a la colección "{2}". Vamos a tratar de completar la mayor cantidad de información posible pero algunos campos van a tener que ser completados o corregidos manualmente.\n\nEste proceso va a crear nuevos álbumes, artistas, sellos y formatos como sea necesario, tratando de coincidir por nombre con cualquiera que hayas cargado previamente. No va a modificarse ninguno de los datos existentes.\n\nLa duración promedio de este proceso puede estimarse en 2 minutos por cada 50 álbumes. Podés cancelarlo en cualquier momento y vas a poder mantener todos los álbumes que se hubiesen importado hasta el momento.',
  discogs_import_cancelled: "Cancelaste el proceso de importación.",
  discogs_import_errored:
    "Ocurrió un error mientras se importaban tus álbumes. Intentá de nuevo más tarde.",
  discogs_import_finished: "¡Todos los álbumes fueron importados!",
  discogs_auth_message:
    "Para importar tu colección de Discogs necesitamos que autorices a Piper en la página de Discogs. Hacé click en el botón de abajo para abrir Discogs en una nueva pestaña y volvé acá cuando hayas permitido el acceso.",
  discogs_auth_button: "Ir a Discogs",
  discogs_auth_retry: "Reintentar",
  discogs_should_skip_duplicates: "Saltear álbumes con el mismo ID de release",
  discogs_should_upload_image:
    "Usar imágenes de Discogs (Esto va a incrementar la duración del proceso)",
  discogs_should_upload_image_non_premium: "Usar imágenes de Discogs (Sólo para usuarios premium)",

  /* ExcelImport */
  excel_import_title: "Importar desde planilla de cálculo",
  excel_import_message: `Seleccioná un archivo con la extensión 'xlsx'. Los álbumes dentro de la planilla de cálculo tienen que seguir un formato específico, seleccioná el botón de ayuda en la esquina superior derecha de esta ventana para conocer más.
  
Después de confirmar vas a poder elegir a qué colección de Piper importarlos.

Podés usar la siguiente plantilla y llenarla con tus álbumes:
  `,
  excel_import_confirmation: `Estás a punto de importar todos los álbumes en el archivo "{0}" a la colección "{1}". Si alguna fila no cumple con el formato requerido, el proceso de importación va a saltear ese álbum.\n\nEste proceso va a crear nuevos álbumes, artistas, sellos y otros atributos como sea necesario, tratando de coincidir por nombre con cualquiera que hayas cargado previamente. No va a modificarse ninguno de los datos existentes.`,
  excel_import_download_template: "Descargar plantilla",
  excel_import_template_url:
    "https://piper-public-images.s3.us-east-2.amazonaws.com/Piper-Plantilla.xlsx",
  excel_import_problems: `Encontramos algunos álbumes con problemas, pero el resto fue agregado exitosamente a tu colección. Abajo vas a encontrar la lista de álbumes que no pudieron ser importados:
  
{0}
  `,

  /* Collections */
  collection_title: "Colección",
  collection_new_title: "Nueva colección",
  add_collection: "Agregar colección",
  share_collection: "Compartir colección",
  star_collection: "Dar estrella",
  unstar_collection: "Quitar estrella",
  collections_title: "Colecciones",
  collections_tease: "¡Próximamente vas a poder manejar múltiples colecciones!",
  collection_delete_confirmation:
    '¿Estás seguro de querer eliminar la colección "{0}"? Todos los álbumes asociados a esta colección van a seguir estando disponibles en la colección de todos los álbumes.',
  private_collection: "Privada",
  public_collection: "Pública",
  collection_missing_field: "Tenés que completar {0} de la colección.",
  collection_assign_filtered_albums: "Asignar álbumes filtrados a esta colección",
  collection_assign_filtered_albums_confirm:
    "¿Estás seguro de querer asignar los álbumes actualmente filtrados a esta colección? Esto solamente va a agregar los álbumes que no estaban previamente presentes en la colección seleccionada.",
  collection_assign_filtered_albums_success: "¡Asignamos los álbumes exitosamente!",
  collection_all_albums: "Todos los álbumes",
  collection_associate: "Asociar colección",
  collection_disassociate: "Desasociar",
  collection_disassociate_confirmation:
    '¿Estás seguro de querer desasociar este álbum de la colección "{0}"?',
  album_view_on_exhibition: "Ver en la exhibición",
  collection_create_visibility:
    "Vas a poder convertirla en una colección pública cuando asocies algunos álbumes a la colección.",
  collection_visibility: "Visibilidad",
  collection_description: "Descripción",
  collection_discount: "Descuento",
  collection_banned_fields: "Campos ocultos",
  collection_banned_fields_description:
    "Seleccioná un campo para ocultar cuando otras personas revisen esta colección (Sólo para colecciones públicas)",
  collection_banned_field_is_clean: "Escuchado",
  collection_banned_field_is_gatefold: "Gatefold",
  collections_manage_title: "Colecciones",
  collection_modify: "Editar colección",
  collection_name_placeholder: "Ejemplo: Álbumes a la venta",
  collection_image_info:
    "Para mejores resultados, tratá de usar imágenes con una relación de aspecto similar a 5:1.",

  /* Box Sets */
  box_set_title: "Box set",
  box_set_no_albums_title: "Este box set no tiene álbumes",
  box_set_no_albums_description:
    "Arrastrá los álbumes que pertenecen a este box set y soltalos en esta sección",
  box_set_unique_error: "Este álbum ya es parte de un box set.",

  /* PreferencesViewController */
  email_title: "Correo",
  theme_option: "Apariencia",
  dark_mode: "Oscuro",
  light_mode: "Claro",
  black_mode: "Negro",
  blue_mode: "Azul",
  orange_mode: "Naranja",
  language_title: "Idioma (Language)",
  app_version_title: "Versión de la aplicación",
  clear_cache_title: "Limpiar memoria caché",
  about_title: "Acerca de esta aplicación",
  contact_us: "Contacto",
  contact_support_title: "Contactar a soporte",
  support_mail: "piper.soporte@ldelosheros.com",
  reorder_tutorial: `Para reordenar tus álbumes arrastralos hasta la posición deseada y soltalos. Sólo se pueden reordenar álbumes dentro del mismo artista.
  
Asegurate de tener seleccionada la colección de 'Todos los álbumes' para poder reordenar tu lista de álbumes. También tenés que tener la lista ordenada por artista y sin filtros, o solamente filtrado por artista.
  
Como esta funcionalidad trae problemas de rendimiento en algunos dispositivos, está deshabilitada en teléfonos. Por favor usá la aplicación de escritorio o la app nativa de iOS para reordenar tus álbumes.
  `,
  credits: "© 2025 Lautaro de los Heros, Piper™",
  credits_short: "© 2025 Piper™",

  /* User Profile */
  user_profile_title: "Perfil de usuario",
  username_title: "Nombre de usuario",
  modify_username_message:
    "Ingresá tu nombre de usuario. Tiene que empezar con una letra y tener 8-20 caracteres de largo. No se permiten caracteres especiales excepto guiones bajos.",
  no_color_name_title: "Nuevo color",
  no_color_names_title: "Colores de discos sin color",
  no_color_name_message:
    "Ingresá un color que no quieras que cuente como disco coloreado en tus estadísticas:",
  no_color_name_removal_confirmation: '¿Estás seguro de querer eliminar el color "{0}"?',
  countries_to_hide_title: "Países para ocultar de la lista de álbumes",
  countries_to_hide_message: "Ingresá un país que quieras ocultar de la lista de álbumes:",
  countries_to_hide_removal_confirmation: '¿Estás seguro de querer eliminar el país "{0}"?',
  bio_title: "Biografía",
  bio_message: "Ingresá tu biografía. Va a ser visible para los demás usuarios.",
  public_collections_title: "Colecciones públicas",
  remove_account_title: "Eliminar mi cuenta",
  remove_account_confirmation_message:
    "Estás por eliminar tu cuenta de usuario de forma permanente. ¡ESTO NO PUEDE DESHACERSE! Todos tus álbumes, atributos, imágenes, y perfil van a desaparecer para siempre. Si estás seguro de querer hacer esto, por favor escribí 'Eliminar mi cuenta' abajo y confirmá.",

  /* User Role */
  update_role_title: "Plan de suscripción",
  role_admin: "Administrador",
  role_guest: "Sin plan",
  role_common: "Plan gratuito",
  role_collector: "Coleccionista",
  role_premium: "Premium",
  subscription_free: "GRATIS",
  subscription_pay_info:
    "El cobro va a realizarse el primer día de cada mes excepto por el primer pago que va a hacerse en el momento que te suscribas. El precio de la primera cuota va a ser ajustado dependiendo de cuántos días quedan pendientes.",
  subscription_pay_info_apple:
    "Pagá usando la aplicación de Piper disponible en App Store. La suscripción es administrada por Apple.",
  subscription_pay_info_patreon:
    "Pagá usando la plataforma Patreon. La suscripción es administrada por Patreon.",
  total: "Total",
  subscription_pay_with_card: "Pagar con tarjeta de crédito",
  subscription_pay_with_mercadopago: "Pagar con Mercado Pago",
  subscription_pay_with_mercadopago_alert: `Te vamos a redirigir a Mercado Pago para que puedas completar el pago.
  
IMPORTANTE: Es importante que uses el botón para volver al sitio que se muestra una vez finalizado el proceso así podemos asociar tu suscripción con nuestra aplicación. Si no hacés este paso no vas a ver la suscripción en Piper, pero no te preocupes, en ese caso contactanos y vamos a solucionar el inconveniente lo antes posible.`,
  subscription_pay_with_apple: "Pagar en App Store",
  subscription_pay_with_patreon: "Pagar con Patreon",
  subscription_payment_method_title: "Método de pago",
  per_month: "por mes",
  pay_option: "Opción",
  pay_option_mercado_pago: "Mercado Pago",
  pay_option_apple: "Apple",
  pay_option_patreon: "Patreon",
  subscription_success:
    "¡Tu nueva suscripción está lista! Por favor ingresá tus credenciales para continuar.",

  /* My Subscription */
  my_subscription_title: "Mi suscripción",
  subscription_manage_in_mercadopago: "Administrar en Mercado Pago",
  subscription_manage_in_apple: "Administrar en App Store",
  subscription_manage_in_patreon: "Administrar en Patreon",
  subscription_status: "Estado de la suscripción",
  subscription_end_date: "Tu suscripción va a terminar el",
  subscription_change: "Cambiar mi suscripción",
  subscription_renew: "Volver a suscribirse",
  payment_last_status: "Estado del último pago",
  payment_next_date: "Próximo pago",
  payment_last_update: "Última actualización",
  payment_approved: "Aprobado",
  payment_authorized: "Autorizado",
  payment_cancelled: "Cancelado",
  payment_charged_back: "Devuelto",
  payment_in_mediation: "En mediación",
  payment_in_process: "Procesando",
  payment_pending: "Pendiente",
  payment_refunded: "Reintegrado",
  payment_rejected: "Rechazado",
  payment_paused: "Pausado",
  payment_is_processing_message:
    "Tu pago se está procesando. Volvé a consultar en unas horas para ver su estado.",

  /* Marketplace */
  highlighted_collections_title: "Colecciones",
  highlighted_albums_title: "Álbumes",
  highlighted_users_title: "Usuarios",
  public_username_prefix: "Colección de",
  marketplace_search_placeholder: "Álbumes, colecciones, usuarios",
  no_public_collections: "No hay colecciones públicas para mostrar",

  /* UserProfileDialog */
  edit_avatar: "Editar avatar",
  user_since_title: "Usuario desde",

  /* Shortcuts */
  shortcuts_title: "Accesos directos",
  shortcuts_add_albums: "Agregar un nuevo álbum",
  shortcuts_scan_barcode: "Agregar un álbum desde un código de barras o una búsqueda",
  shortcuts_discogs_import: "Importar álbumes desde mi cuenta de Discogs",
  shortcuts_excel_import: "Importar álbumes desde una planilla de cálculo",
  shortcuts_stats: "Ver estadísticas de mis álbumes",
  shortcuts_albums: "Ver mis álbumes",
  shortcuts_attributes: "Agregar un nuevo artista, sello, cambio de moneda y más...",
  shortcuts_marketplace: "Explorar colecciones de otros usuarios",
  shortcuts_filter: "Filtrar mis álbumes en esta colección",
  shortcuts_filter_public: "Filtrar los álbumes en esta colección",
  shortcuts_random_albums: "Ver un álbum aleatorio de esta colección",
  shortcuts_change_collection: "Ver álbumes de una colección distinta",
  shortcuts_create_user: "Crear un usuario o ingresar",
  shortcuts_search_title: "No hay álbumes para mostrar",
  shortcuts_documentation: "Ver la documentación por ayuda o consejos",
  shortcuts_edit_profile: "Personalizar mi perfil de usuario",
  shortcuts_layout: "Organizar la ubicación física de mis álbumes",

  /* User Notifications */
  user_notifications_title: "Notificaciones",
  mark_as_read: "Marcar como leído",
  mark_as_unread: "Marcar como no leído",

  /* ScanBarcode */
  scan_barcode_title: "Escaneá el código de barras presente en el álbum",
  scan_barcode_description:
    "Opcionalmente, ingresá el código de barras de forma manual o cualquier otra información sobre el álbum",
  camera_title: "Escanear un código de barras",
  flashlight: "Linterna",
  open_camera: "Abrir cámara",
  switch_camera: "Cambiar cámara",

  /* Messages */
  messages_title: "Comentarios",
  messages_new_title: "Nuevo comentario",
  messages_reply: "Responder",
  messages_more: "Más comentarios",
  messages_replying_to: "Estás respondiendo a un comentario de {0}",
  messages_reply_more: "1  respuesta",
  messages_replies_more: "{0}  respuestas",
  message_removed: "<<Mensaje eliminado>>",
  album_no_messages: "Este álbum todavía no tiene comentarios",
  view_message: "Ver comentario",
  messages_private_notice:
    "Si querés responder a un comentario visitá este álbum en la exhibición.",
  messages_confirm_removal: `¿Estás seguro que querés eliminar el mensaje de {0} y todas sus respuestas? El mensaje dice:
  
  "{1}"`,
  messages_report: "Reportar mensaje",

  /* Reports */
  report_new: "Nuevo reporte",
  report_description: "Contanos más acerca de este reporte:",
  report_success: "¡Reporte enviado! Gracias por tu aporte.",

  /* Chats */
  chats_title: "Conversaciones",
  chat_placeholder: "Escribí acá tu mensaje...",
  chat_send: "Enviar mensaje",
  chat_new: "Enviar un mensaje",
  chat_select: "Seleccioná una conversación",
  chat_new_user: "Empezar una nueva conversación",
  chat_empty_title: "Todavía no tenés conversaciones",
  chat_empty_description:
    "Empezá a chatear con otros usuarios usando el botón en la barra de herramientas",
  public_user_profiles_empty:
    "Ingresá un nombre de usuario en la barra de búsqueda para encontrar usuarios",
  chat_messages_empty_title: "Todavía no hay mensajes para mostrar",
  chat_messages_empty_description:
    "Vas a ver tu historial de mensajes con este usuario cuando empieces la conversación",
  chat_new_message_notification: "Tenés un nuevo mensaje",
  open_chats_list: "Abrir lista de conversaciones",

  /* Layout */
  layout_title: "Mobiliario",
  shelving_title: "Mueble",
  shelving_new_title: "Nuevo mueble",
  shelving_tutorial:
    "Arrastrá el lomo del álbum que ves en la lista hacia cualquiera de los segmentos del mueble para ubicarlo. Arrastrá los lomos dentro del segmento para reordenarlos. Hacé click en cualquier álbum para seleccionarlo y ver su posición en el mueble, o click derecho para ver su detalle.",
  shelf_title: "Estante",
  shelf_new_title: "Nuevo estante",
  shelf_segment_title: "Segmento",
  shelf_segment_new_title: "Nuevo segmento",
  shelf_segment_position_title: "Posición",
  highlight_filtered_albums: "Resaltar álbumes filtrados",
  shelving_edit_title: "Editar mueble",
  shelf_edit_title: "Editar estante",
  shelf_segment_edit_title: "Editar segmento",
  shelving_orientation: "Orientación del mueble",
  shelving_theme: "Material del mueble",
  shelving_cedar: "Cedro",
  shelving_oak: "Roble",
  shelving_ebony: "Ébano",
  shelving_walnut: "Nogal",
  shelving_blue: "Azul",
  shelving_name_placeholder: "Ejemplo: Mueble de vinilos",
  shelving_delete_confirmation: `¿Estás seguro de querer eliminar el mueble "{0}"? No vas a perder ningún álbum asociado a este mueble.`,
  shelf_name_placeholder: "Ejemplo: Estante de arriba",
  shelf_delete_confirmation: `¿Estás seguro de querer eliminar el estante "{0}"? No vas a perder ningún álbum asociado a este estante.`,
  shelf_segment_name_placeholder: "Ejemplo: Primer segmento",
  shelf_segment_delete_confirmation: `¿Estás seguro de querer eliminar el segmento "{0}"? No vas a perder ningún álbum asociado a este segmento.`,
  empty_shelvings: "Seleccioná un mueble para mostrarlo",
  empty_shelvings_subtitle: "O agregá uno nuevo usando el botón de abajo",
  shelf_album_added_message: "Álbum agregado al segmento exitosamente",
  shelf_album_removed_message: "Álbum removido del segmento exitosamente",
  shelf_album_duplicate_error: "¡Ya tenés ese álbum en ese segmento!",
  shelf_remove_album: "Remover del segmento",
  shelf_segment_album_shape_title: "Forma del álbum",
  shelf_segment_album_shape_long_play: "12″",
  shelf_segment_album_shape_ten_inches: "10″",
  shelf_segment_album_shape_single: "7″",
  shelf_segment_album_shape_cd: "CD",
  shelf_segment_album_shape_box_set: "Box set",
  shelf_segment_assign_filtered_albums: "Asignar álbumes filtrados a este segmento",
  shelf_segment_add_album_title: "Agregar álbumes",
  album_location_title: "Ubicación",
  album_position_title: "Posición",
  layout_cannot_move_message: "Para hacer modificaciones a este mueble, dirigite al Mobiliario.",

  /* Texts */
  documentation: "Documentación",
  doc_home: doc_home_es,
  doc_albums: doc_albums_es,
  doc_users_sign_up: doc_users_sign_up_es,
  doc_users_guest: doc_users_guest_es,
  doc_users_recover_password: doc_users_recover_password_es,
  doc_filter_albums: doc_filter_albums_es,
  doc_order_albums: doc_order_albums_es,
  doc_import_from_discogs: doc_import_from_discogs_es,
  doc_import_from_excel: doc_import_from_excel_es,
  doc_image_gallery: doc_image_gallery_es,
  doc_marking_albums: doc_marking_albums_es,
  doc_remove_filtered_albums: doc_remove_filtered_albums_es,
  doc_albums_add_barcode: doc_albums_add_barcode_es,
  doc_album_attributes: doc_album_attributes_es,
  doc_artists: doc_artists_es,
  doc_collections: doc_collections_es,
  doc_collections_attributes: doc_collections_attributes_es,
  doc_collections_public_collections: doc_collections_public_collections_es,
  doc_user_profile: doc_user_profile_es,
  doc_subscriptions: doc_subscriptions_es,
  doc_mercado_pago_subscriptions: doc_mercado_pago_subscriptions_es,
  doc_more_options_title: "Más cosas que podés hacer",
  about_piper: about_piper_es,
  privacy_policy: privacy_policy_es,
  terms: terms_es,
  tutorial_video_id: "hBKdnR8effU"
};
