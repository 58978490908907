import React, { FunctionComponent, useEffect, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { Box, Card, ListItemText, Skeleton, Stack, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { DashboardRounded } from "@mui/icons-material";
import { AlbumLocationTable } from "../location/AlbumLocationTable";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { BoxSetListItem } from "../BoxSetListItem";

export const AlbumLocationListSection: FunctionComponent<IAlbumLocationListSectionProps> = ({
  album,
  onBoxSetSelected
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(album.shelfSegments === undefined);
  }, [album.shelfSegments]);
  return (
    <div>
      <div className={styles.tableDiv}>
        {album.isInsideBoxSet && album.boxSet && (
          <>
            <Stack className={styles.header} direction={"row"} spacing={2} sx={{ width: "100%" }}>
              <ListItemText
                disableTypography
                className={styles.title}
                secondary={strings.box_set_title}
                sx={{ flexGrow: 0 }}
              />
            </Stack>
            <BoxSetListItem boxSet={album.boxSet} onClick={onBoxSetSelected} />
          </>
        )}
        <Stack className={styles.header} direction={"row"} spacing={2} sx={{ width: "100%" }}>
          <ListItemText
            disableTypography
            className={styles.title}
            secondary={strings.album_location_title}
            sx={{ flexGrow: 0 }}
          />
          <Box flexGrow={1} />
          <ToolbarButton
            tooltip={strings.shortcuts_layout}
            icon={DashboardRounded}
            loading={isLoading}
            href={`${window.location.protocol}//${window.location.host}${RoutesBuilder.layout()}`}
            target={"_blank"}
          />
        </Stack>
        {album.shelfSegments && album.shelfSegments.length > 0 ? (
          <Card sx={{ width: "100%" }}>
            <AlbumLocationTable album={album} />
          </Card>
        ) : isLoading ? (
          <Skeleton variant={"rectangular"} className={styles.skeleton} />
        ) : (
          <p className={styles.noLocationText} style={{ color: theme.palette.secondary.main }}>
            {strings.album_no_location}
          </p>
        )}
      </div>
    </div>
  );
};

export interface IAlbumLocationListSectionProps {
  album: IAlbum;
  onBoxSetSelected: () => void;
}
